import nserc_alliance from '../../assets/jpg/press-queens.jpg';
import type { NewsStory } from '../../types/news-story.model';

// note month input on date is 0-indexed (0 = January, 1 = Feburary ...)

export default function NewsStories() {
  const components: NewsStory[] = [
    {
      id: '20230510',
      title: 'Joint NSERC Alliance Project',
      date: new Date(2023, 4, 10),
      headline:
        '$3.1M Joint NSERC Alliance Project continues to put Kingston at the forefront of human knowledge in distributed computing',
      subtitle: 'Kingston, Ontario, Canada',
      image: nserc_alliance,
      caption: `Joseph S. Stauffer Library at Queen's University`,
      content: `Motivated by the exponential growth of data-driven initiatives (e.g., smart cities) and innovation (e.g., compute-hungry research), the need for novel technologies and ubiquitous resources for fast and affordable computing is critical. Traditional methods of obtaining computing resources — data centres and cloud computing — are expensive and are falling behind demand.  Aggregation of personal and enterprise-owned devices at the “edge” of the network can create computing alternatives to meet the growing need.
            <br>
            <br>
            As the world thirsts for computing resources, this collaboration continues to keep KDS and QSC researchers at the forefront of commercializing its advanced scheduling framework for edge computing. This provides abundant, secure and low-cost tools for research, innovation and discovery.
            <br>
            <br>
            “The computing resource is the oil of the future — and this taps into a gusher!” said Douglas Stewart, Director of Special Projects and Co-founder at KDS. KDS is contributing $740,000 in cash to this research project.
            <br>
            <br>
            “We are excited to take on this cutting-edge project and proud to be able to offer our students and researchers newfound knowledge and training in this important field. It’s also gratifying as a researcher to see direct commercial application of our pure-science discoveries.” Said Hossam Hassanein, Director of Queen’s School of Computing and Principal Investigator on the project. As part of this initiative QSC will engage 21 students and researchers over a period of four years in pursuit of the project’s goals.
            <br>
            <br>
            It is hoped that this research will continue to expand the boundaries of human knowledge in this subject area and both parties continue to look forward to a fruitful long-term collaboration.
            <br>
            <br>
            Please attend the project kick-off and cheque hand-over ceremony on Monday October 26, at 2:30 pm at Kings Distributed Systems offices: 4th floor LaSalle Mews, Bagot Street, Kingston Ontario.
            <br>
            <br>
            Or online at <a href="https://whereby.com/kingshq ">https://whereby.com/kingshq</a>           
            `,
      about: `<p className="font-semibold">About Kings Distributed Systems:</p>
            Founded in 2017, Kings Distributed Systems is a fast growing Canadian startup responsible for deploying the Distributed Compute Protocol (DCP), a cross-platform solution that aggregates computing resources from arbitrary devices and digital infrastructure — from smartphones to enterprise servers — and makes it available to researchers and innovators on-demand. This allows individuals, companies, institutions as well as Universities to recapture and allocate under-utilized resources for science and innovation. <a href="https://www.kingsds.network">https://www.kingsds.network</a>
            <br>
            <br>
            <p className="font-semibold">About Queen’s School of Computing:</p>
            Established in 1969, the School of Computing has developed its program through research and scholarship and has become one of the leading establishments in its field.The School of Computing is actively engaged in research on a broad range of topics, with an eminent research record.  The School offers graduate programs at the Master's and PhD levels and an honours Bachelor's degree. It also offers undergraduate degrees in cooperation with other Science departments at Queen's, as well as a degree in Cognitive Science. <a href="https://www.cs.queensu.ca">https://www.cs.queensu.ca</a>
            `,
    },
  ];

  return components;
}
