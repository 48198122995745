import COST from '../assets/png/DIANA-cost.png';
import MODULARITY from '../assets/png/DIANA-modularity.png';
import SECURITY from '../assets/png/DIANA-privacy.png';
import USABILITY from '../assets/png/DIANA-usability.png';
import NEON from '../assets/png/Ngen-stacked-white.png';
import DIANA_LOGO from '../assets/png/full-title-diana.png';
import NORTH from '../assets/png/north-star-air-white.png';
import QUOTES from '../assets/png/quote-marks.png';
export const DIANA = {
  metadata: {
    description:
      'Distributive created the Distributive Compute Protocol (DCP). A standard for matching supply and demand for compute to enable anyone, academic or commercial, with a need for computing power to have access to that power within any web connected device on the platform.',
    keywords: '',
  },
  hero: {
    imgTitle: DIANA_LOGO,
    subheading1:
      'Machine learning inferencing for smart industry without sky-high cloud costs.',
    subheading2: "Don't worry, there are clear skies ahead.",
    bgImage: 'bg-[url(./assets/jpg/hero-diana.jpg)] !bg-left-top xl:!bg-right ',
  },
  whyDiana: {
    title: 'Why is DIANA better?',
    benefits: [
      {
        title: 'COST',
        src: COST,
        description:
          'On-prem distributed computing slashes cloud costs by up to 92%',
      },
      {
        title: 'PRIVACY',
        src: SECURITY,
        description:
          'Data and model are processed on-prem; nothing leaves your building',
      },
      {
        title: 'MODULARITY',
        src: MODULARITY,
        description:
          'Expand capabilities with unlimited custom model deployment',
      },
      {
        title: 'USABILITY',
        src: USABILITY,
        description:
          'Three steps from deployment to live inferencing in under a day',
      },
    ],
  },
  provenTech: {
    title: 'Proven Technology, Proven Results',
    bgImage: 'bg-[url(./assets/jpg/security-cam-forklift.jpg)]',
    subheading:
      'NorthStar Air, with support from NGen, is currently using DIANA to help keep their facilities safer, and continue to innovate their business without the monumental costs associated with commercial cloud computing resources.',
    bgQuotes: QUOTES,
    companies: [
      {
        src: NORTH,
        description:
          'The high cost of running machine learning inferencing in the cloud was an impediment to creating a smart workplace. DIANA exceeded our expectations while keeping costs low and our data on-prem.',
      },
      {
        src: NEON,
        description:
          'This completely changes the game for manufacturers wanting to implement state-of-the art, data-driven solutions without spending an absolute fortune on commercial cloud.',
      },
    ],
  },
  optimize: {
    title:
      'Optimize product, factory, and supply-chain operations with low cost machine learning inferencing through on-prem distributed computing.',
    bgImage: 'bg-[url(./assets/jpg/diana-gradient.jpg)]',
    subheading:
      'These are some of the potential use-cases that DIANA can be used for:',
    features: [
      {
        title: 'SECURITY & SAFETY <br/>MONITORING',
        description:
          'Continuously analyze video feeds using machine vision threat detection models',
      },
      {
        title: 'PREDICTIVE QUALITY <br/>CONTROL',
        description:
          'Monitor manufacturing quality control using predictive machine vision models',
      },
      {
        title: 'PROCESS <br/>OPTIMIZATION',
        description: 'Reduce process divergence with fault detection',
      },
      {
        title: 'EDGE <br/>INFERENCING',
        description:
          'Run models close to the data source using edge inferencing',
      },
    ],
  },
  application: {
    title: 'DIANA Application',
    bgImage: 'bg-[url(./assets/png/overwatch-diagram.png)]',
    processes: [
      {
        title: 'DIANA Server:',
        description: 'Communicates between DCP and the user to initiate jobs',
      },
      {
        title: 'Model Registry:',
        description:
          'Stores machine learning models which can be accessed by the on-prem workers - along with any pre or post processing',
      },
      {
        title: 'Input Server:',
        description:
          'Stores inputs to be inferenced on, accessed by the on-prem workers when a job is initiated',
      },
      {
        title: 'User:',
        description:
          'After post-processing, results are returned from the DIANA Server to the user.',
      },
    ],
    poweredByDCP: {
      title: 'Powered by the DCP Platform',
      description:
        'DCP is a web-based distributed computing platform that provides secure authentication, workload orchestration, and results returned for DIANA. DCP Workers are compute nodes that authenticate with and retrieve encrypted compute messages from the DCP Scheduler. The Workers decrypt the messages and fetch DIANA data and models on-prem for processing. After post-processing, results are returned from the DIANA Server to the user.',
    },
  },
  DCPWorkers: {
    title: 'DCP Workers Supply the Compute Power',
    bgImage: 'bg-[url(./assets/jpg/diana-gradient.jpg)]',
    description:
      'There are several options for DCP Workers to provide compute power from within your secure facility to ensure your data and model never leaves the premises.',
    features: [
      {
        title: 'SCREEN SAVER <br/>WORKER',
        subheading1: 'For desktop fleets',
        subheading2:
          '(all the idle computers in a building represent untapped compute, especially during periods of inactivity)',
      },
      {
        title: 'DOCKER <br/>WORKER',
        subheading1: 'For Kubernetes-style deployments',
        subheading2:
          '(to interoperate with your existing Kubernetes or cloud deployments)',
      },
      {
        title: 'STANDALONE <br/>WORKER',
        subheading1: 'For Unix devices',
        subheading2:
          '(command line, more control, can run as a dedicated process, or a background one)',
      },
      {
        title: 'WEB BROWSER <br/>WORKER',
        subheading1: 'For ephemeral compute clusters',
        subheading2: '(for demonstrations, and rapid temporary deployments)',
      },
    ],
  },
  cta: {
    title: 'Contact us today to learn how DIANA can work for you',
    subheading:
      'If your goal is to unlock innovation that is stalled by overwhelming compute costs, or if you are tired of the monthly cloud bills, DIANA could be your answer.',
    button: 'Learn More Today',
    href: 'mailto:diana@distributive.network',
  },
};
