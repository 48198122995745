import { useEffect, useState } from 'react';
import type * as React from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { NavLink, useLocation } from 'react-router-dom';
import menu from '../../assets/svg/menu.svg';
import './Nav.scss';
import { AnimatePresence, motion } from 'framer-motion';
import distributive_logo from '../../assets/svg/distributive-logo.svg';
import type { NavigationModel } from '../../types/navigation.model';
import { Dropdown } from './Dropdown/Dropdown';
import { MobileMenu } from './MobileMenu/MobileMenu';

const urlMap = new Map();
urlMap.set('/diana', { index: 1 });
urlMap.set('/platform', { index: 0 });
urlMap.set('/', { index: 1 });
urlMap.set('/osler', { index: 1 });
urlMap.set('/lp117', { index: 1 });
urlMap.set('/about', { index: 2 });
urlMap.set('/news', { index: 2 });
urlMap.set('/media', { index: 2 });
urlMap.set('/policy', { index: 2 });
urlMap.set('/careers', { index: 3 });
urlMap.set('/contact', { index: 4 });
urlMap.set('/screensaver', { index: 0 });
urlMap.set('/workers', { index: 0 });
urlMap.set('/blog', { index: 5 });

export const Nav: React.FC<NavigationModel> = ({ items }) => {
  const [dropdownMenuOpen, setDropDownMenuOpen] = useState(false);
  const [mouseHoverIndex, setMouseHoverIndex] = useState(-1);
  const [currentPageIndex, setCurrentPageIndex] = useState(-1);
  const [topPage, setTopPage] = useState(true);
  const [onMenu, setOnMenu] = useState(false);
  const { pathname } = useLocation();

  /**
   * Used for navbar underline effect
   */
  useEffect(() => {
    function checkCurrentUrl(pathName: string) {
      const urlData = urlMap.get(pathName);
      setCurrentPageIndex(urlData?.index ?? -1);
      // Delay an event loop tick for the `onMouseLeave` event handler to
      // trigger first.
      setTimeout(() => setMouseHoverIndex(urlData?.index ?? -1), 0);
    }

    checkCurrentUrl(pathname);
  }, [pathname]);

  /**
   * Used for setting the top nav black background
   */
  useEffect(() => {
    const onScroll = () => {
      setTopPage(window.pageYOffset <= 75);
    }; // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <>
      <div className="bg-black w-[100%] h-[75px]" />
      <nav
        className={`lp_Navbar flex justify-between h-[75px] ${
          dropdownMenuOpen ? '!bg-black' : ''
        } ${topPage ? '!bg-black transition-none' : ''}`}
        onMouseLeave={() => {
          setMouseHoverIndex(currentPageIndex);
          setOnMenu(false);
        }}
      >
        <div className="lp_nav-left">
          {/* Distributive Logo */}
          <NavLink
            to="/"
            onClick={() => {
              setMouseHoverIndex(-1);
              setDropDownMenuOpen(false);
            }}
          >
            <img
              src={distributive_logo}
              alt="distributive nav logo"
              className="lp_nav_brandName"
            />
          </NavLink>
        </div>

        {/* Mobile Navigation */}
        <div className="mobile-nav block md:hidden">
          <button
            type="button"
            className="flex justify-center items-center mr-[.6rem]"
            onClick={() => {
              setDropDownMenuOpen(!dropdownMenuOpen);
            }}
          >
            <img
              src={menu}
              alt="menu button"
              className="lp_menu object-cover h-[20px]"
            />
          </button>
          <AnimatePresence>
            {dropdownMenuOpen && (
              <MobileMenu
                setDropDownMenuOpen={setDropDownMenuOpen}
                currentPageIndex={currentPageIndex}
                items={items}
              />
            )}
          </AnimatePresence>
        </div>

        {/* Desktop Navigation */}
        <motion.ul className="nav-menu hidden md:flex gap-[1.2rem] lg:gap-[2rem] xl:gap-[4rem] text-[1rem] h-[100%]">
          {/* Nav menu Items */}
          {items?.map((item, index) => (
            <li
              key={item.url}
              className="relative nav-item"
              onMouseEnter={() => {
                setMouseHoverIndex(index);
                if (item.subItems) setOnMenu(true);
              }}
            >
              <NavLink
                className="nav-links !h-[100%] flex justify-center items-center"
                to={item.url || '/'}
                target={item.blankTarget ? '_blank' : ''}
              >
                <span className="flex items-center">
                  {item.title}
                  {item.subItems && (
                    <RiArrowDropDownLine className="text-[1.5rem]" />
                  )}
                </span>
              </NavLink>

              {/* Dropdown menu */}
              {item.subItems && mouseHoverIndex === index && onMenu && (
                <Dropdown
                  linkIndex={index}
                  // setCurrentPageIndex={setCurrentPageIndex}
                  subItems={item.subItems}
                />
              )}

              {/* Underline */}
              <AnimatePresence>
                {mouseHoverIndex === index && (
                  <motion.div
                    initial={{
                      opacity: 0,
                    }}
                    animate={{
                      opacity: 1,
                      transition: {
                        duration: 0.2,
                      },
                    }}
                    exit={{
                      opacity: 0,
                      transition: {
                        duration: 0.4, //How long it will take to slide out of frame
                      },
                    }}
                    layoutId="nav-underline"
                    className="nav-underline h-[5px] w-[50px] absolute bottom-[1rem]"
                  />
                )}
              </AnimatePresence>
            </li>
          ))}

          {/* DCP PORTAL */}
          <NavLink
            className="dcp-portal !h-[fit-content] flex justify-center items-center bg-[#00a871] my-[auto] p-[10px] rounded"
            to="https://secure.distributed.computer/users/sign_in"
            target="_blank"
          >
            DCP PORTAL
          </NavLink>
        </motion.ul>
      </nav>
    </>
  );
};
