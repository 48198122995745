import { ABOUT } from '../../../PageContent/About';
import NewsStories from '../../../PageContent/MentionContent/NewsStories';
import type { NewsStory } from '../../../types/news-story.model';
import NewsMention from './NewsMention';

export default function News() {
  const newsComponents = NewsStories();

  return (
    <>
      <div className="news-container">
        <div className="h-[1px] w-full bg-[#D9D9D9] bg-opacity-40" />
        {newsComponents
          .slice(-ABOUT.news.numOfMentions)
          .reverse()
          .map((news: NewsStory, key) => (
            <>
              <NewsMention
                key={key}
                url={`/news/${news.id}`}
                date={news.date}
                headline={news.headline}
              />
              <div className="h-[1px] w-full bg-[#D9D9D9] bg-opacity-40" />
            </>
          ))}
      </div>
    </>
  );
}
