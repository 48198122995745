import { Helmet } from 'react-helmet-async';

import { FAQ } from '../../PageContent/FAQ';
import FaqPanel from '../../components/FaqPanel/FaqPanel';
import HeroPanel from '../../components/HeroPanel/HeroPanel';

export function Faq() {
  return (
    <>
      <Helmet>
        <meta name="description" content={FAQ.metadata?.description} />
        <meta name="keywords" content={FAQ.metadata?.keywords} />
      </Helmet>
      <div>
        <HeroPanel
          fullHeight={false}
          bgImage={FAQ.hero.bgImage}
          title={FAQ.hero.title}
          description={FAQ.hero.subHeading}
        />
        <FaqPanel
          topic={FAQ.distributiveQuestions}
          faqBackground={FAQ.faqBackground}
        />
        <FaqPanel
          topic={FAQ.computeQuestions}
          faqBackground={FAQ.faqBackground}
        />
        <FaqPanel
          topic={FAQ.productQuestions}
          faqBackground={FAQ.faqBackground}
        />
      </div>
    </>
  );
}
