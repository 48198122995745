import { useState } from 'react';
import { MANIFEST_YAML } from '../../PageContent/ManifestYaml';
import CHECKMARK from '../../assets/png/checkbox_checkmark.png';
import CLOSE from '../../assets/png/close_modal_icon.png';
import { cn } from '../../utils/utils';
import FormErrorMsg from '../FormErrorMsg/FormErrorMsg';
import styles from './ManifestYamlModal.module.scss';
function ManifestYamlModal({ setModalOn, modalOn }: any) {
  const [leavePublicGroup, setLeavePublicGroup] = useState(false); //If true => unchecked, false => checked

  interface IOptions {
    PAYMENT_ADDRESS: string;
    CPU_CORES: string;
    COMPUTE_GROUPS: any;
    LEAVE_PUBLIC_GROUP: boolean;
    ALLOW_ORIGINS_ANY: string[];
  }
  const [options, setOptions] = useState<IOptions>({
    PAYMENT_ADDRESS: '',
    CPU_CORES: '',
    COMPUTE_GROUPS: [{ joinKey: '', joinSecret: '' }],
    LEAVE_PUBLIC_GROUP: leavePublicGroup,
    ALLOW_ORIGINS_ANY: [],
  });
  const INITIAL_ERROR_STATE = {
    dcpAccountError: false,
    numCPUError: false,
    computeGroupError: false,
    allowOriginsError: false,
  };
  const [errors, setErrors] = useState(INITIAL_ERROR_STATE);

  const updateErrors = () => {
    const deepClonedErrors = structuredClone(errors);
    setErrors(deepClonedErrors);
  };

  const setComputeGroupError = (value: boolean) => {
    errors.computeGroupError = value;
    updateErrors();
  };

  const setDCPAccountError = (value: boolean) => {
    errors.dcpAccountError = value;
    updateErrors();
  };

  const setAllowOriginsError = (value: boolean) => {
    errors.allowOriginsError = value;
    updateErrors();
  };

  // Used to update the modal form options state
  const updateOptions = () => {
    const deepClonedOptions = structuredClone(options); //We need to deep copy or else React won't rerender UI
    setOptions(deepClonedOptions);
    //Reset Errors
    resetErrors();
  };

  const resetErrors = () => {
    setErrors(INITIAL_ERROR_STATE);
  };

  const updateComputeGroup = (
    id: number,
    keyValue: string,
    secretValue: string,
  ) => {
    options.COMPUTE_GROUPS[id] = {
      joinKey: keyValue,
      joinSecret: secretValue,
    };

    updateOptions();
  };

  const updateLeavePublicComputeGroup = (value: boolean) => {
    setLeavePublicGroup(value);
    options.LEAVE_PUBLIC_GROUP = value;
    updateOptions();
  };

  const deleteComputeGroup = (id: number) => {
    options.COMPUTE_GROUPS.splice(id, 1);
    updateOptions();
  };

  const updateNumCPUs = (value: string) => {
    const positiveIntReg = new RegExp(/^[1-9]\d*$/);
    if (value !== '' && !positiveIntReg.test(value)) return;
    options.CPU_CORES = value;
    updateOptions();
  };

  const addComputeGroup = () => {
    options.COMPUTE_GROUPS.push({ joinKey: '', joinSecret: '' });
    updateOptions();
  };

  const updatePaymentAddress = (value: string) => {
    options.PAYMENT_ADDRESS = value.trim();
    updateOptions();
  };

  const updateAllowOrigins = (value: string) => {
    const delimiterRegex = /[ |\n]/;
    options.ALLOW_ORIGINS_ANY = value
      .split(delimiterRegex) //Splits the input items based on new lines (\n) or spaces (' ')
      .filter((item) => item !== '') //Removes empty newlines
      .map((item) => item.trim()); //Removes leading and trailing spaces

    updateOptions();
  };

  const getDCPAccount = async () => {
    // @ts-ignore
    const account = await window.getPaymentAddress();

    if (account != null) {
      options.PAYMENT_ADDRESS = account;
      updateOptions();
    }
  };

  const generateYaml = async () => {
    let errorsPresent = false;

    // @ts-ignore
    const validAddress = await window.validatePaymentAddress(
      options.PAYMENT_ADDRESS,
    );

    // ------------------------------------------------ ERROR VALIDATION ------------------------------------------------ //

    // Validate DCP bank account
    if (!validAddress && options.PAYMENT_ADDRESS !== '') {
      setDCPAccountError(true);
      errorsPresent = true;
    }

    // Allow Copute Group Unchecked
    if (options.COMPUTE_GROUPS.length === 0 && options.LEAVE_PUBLIC_GROUP) {
      setComputeGroupError(true);
      errorsPresent = true;
    }

    // Check for empty compute group fields
    for (const compute of options.COMPUTE_GROUPS) {
      if (compute.joinKey === '' && options.LEAVE_PUBLIC_GROUP) {
        setComputeGroupError(true);
        errorsPresent = true;
      }
    }

    // Check for semi colons in Allow Origins
    if (options.ALLOW_ORIGINS_ANY.length > 0) {
      for (const origin of options.ALLOW_ORIGINS_ANY) {
        if (origin.slice(-1) !== ';') continue;
        //Remove the last character
        setAllowOriginsError(true);
        errorsPresent = true;
        break;
      }
    }

    if (errorsPresent) return;

    // ------------------------------------------------ SERIALIZING DATA ------------------------------------------------ //
    const serializedOptions: any = {};

    // Checks for default payment address
    serializedOptions.PAYMENT_ADDRESS =
      options.PAYMENT_ADDRESS === ''
        ? '0x079dac0612c710ab4e975dab7171c7e4bef78c5a'
        : options.PAYMENT_ADDRESS;

    // Checks for default number of cpus
    serializedOptions.CPU_CORES =
      options.CPU_CORES === '' ? '4' : options.CPU_CORES;

    // Checks if Allow global Compute, then filters out the empty Join Key Compute Groups
    serializedOptions.COMPUTE_GROUPS = !options.LEAVE_PUBLIC_GROUP
      ? JSON.stringify(
          options.COMPUTE_GROUPS.filter((group: any) => group.joinKey !== ''),
        )
      : JSON.stringify(options.COMPUTE_GROUPS);

    serializedOptions.LEAVE_PUBLIC_GROUP = options.LEAVE_PUBLIC_GROUP;
    serializedOptions.ALLOW_ORIGINS_ANY = JSON.stringify(
      options.ALLOW_ORIGINS_ANY,
    );

    // @ts-ignore
    await window.downloadManifestYaml(serializedOptions);
  };

  return (
    <>
      {/* Back drop */}
      <div
        className={cn(
          `${styles['manifest-background']} flex justify-center items-center`,
        )}
      />

      {/* Manifest Container */}
      <div
        className={cn(
          `${styles['manifest-container']} flex justify-center items-center bg-[#FFFFFF33] `,
        )}
      >
        {/* Manifest Content*/}
        <div
          className={`${styles['manifest-content']} m-[7px] sm:m-[20px] relative w-[850px] max-h-[75vh] sm:max-h-[95vh] overflow-auto sm:min-h-[650px] bg-[rgba(13, 36, 56, 0.60)] top-[30px] sm:top-[0]`}
        >
          {/* Exit Icon */}
          <button
            className="absolute top-0 right-0 bg-[#ffffff1a] border-solid border-[2px] border-[#ffffff33]"
            onClick={() => setModalOn(false)}
          >
            <img src={CLOSE} alt="close modal" />
          </button>

          {/* Modal Title */}
          <p className={'p_large_style'}>{MANIFEST_YAML.title}</p>
          {/* Horizontal Line */}
          <div className="w-[100%] h-[1px] bg-[white] my-[10px]" />

          <div className="flex flex-col gap-[15px]">
            {/* DCP Bank Account */}
            <div className="flex flex-col justify-start">
              <p>{MANIFEST_YAML.enterDCPAccount}</p>
              <div className="flex flex-col sm:flex-row gap-[15px] w-[100%] ">
                <input
                  type="text"
                  placeholder={MANIFEST_YAML.enterDCPAccountPlaceholder}
                  onChange={(e) => updatePaymentAddress(e.target.value)}
                  value={options.PAYMENT_ADDRESS}
                  className="w-[100%] sm:w-[579px] min-h-[40px] bg-[#ffffff17] border-solid border-[1px] border-[#A8A8A8] pl-[20px]"
                />
                <button
                  className="bg-[#ffffff17] min-w-[100%] sm:min-w-[156px] min-h-[40px]"
                  onClick={() => {
                    getDCPAccount();
                  }}
                >
                  <span className="relative top-[1px]">
                    {MANIFEST_YAML.selectAccount}
                  </span>
                </button>
              </div>
            </div>
            {errors.dcpAccountError && (
              <FormErrorMsg>{MANIFEST_YAML.errors.dcpAccount}</FormErrorMsg>
            )}

            {/* # of CPUs */}
            <div className="flex flex-col justify-start">
              <p>{MANIFEST_YAML.numberOfCPUs}</p>
              <div className="flex gap-[15px] w-[100%] h-[40px]">
                <input
                  type=""
                  value={options.CPU_CORES}
                  onChange={(e) => updateNumCPUs(e.target.value)}
                  placeholder={MANIFEST_YAML.numberOfCPUsPlaceholder}
                  className="w-[579px] h-[100%] bg-[#ffffff17] border-solid border-[1px] border-[#A8A8A8] pl-[20px]"
                />
              </div>
            </div>
            {/* # of CPUS Error */}
            {errors.numCPUError && (
              <FormErrorMsg>{MANIFEST_YAML.errors.numCPU}</FormErrorMsg>
            )}

            <div className="flex flex-col">
              {/* Horizontal Line */}
              <div className="w-[100%] h-[1px] bg-[white] mt-[10px] mb-[5px]" />

              {/* Compute Groups & Allow Global Compute Group */}
              <div className="flex justify-between flex-col gap-[10px] sm:gap-0 sm:flex-row">
                {/* Compute Groups */}
                <p>
                  <span className="font-bold">
                    {MANIFEST_YAML.computeGroups}
                  </span>{' '}
                  {MANIFEST_YAML.computeGroupsMsg}
                </p>

                {/* ALlow Global Compute */}
                <div className="flex gap-[10px] items-center">
                  <p>{MANIFEST_YAML.allowGlobalComputeGroup}</p>
                  <button
                    className="flex items-center gap-[5px]"
                    onClick={() =>
                      updateLeavePublicComputeGroup(!leavePublicGroup)
                    }
                  >
                    {/* Checkbox */}
                    <div className="relativebg-[#ffffff17] border-solid border-[1px] border-[#A8A8A8] w-[15px] h-[15px]">
                      {!leavePublicGroup && (
                        <img src={CHECKMARK} alt="checkmark" />
                      )}
                    </div>
                    <p>{MANIFEST_YAML.yes}</p>
                  </button>
                </div>
              </div>
            </div>

            <div className="flex flex-col w-[100%] items-start gap-[10px]">
              {options.COMPUTE_GROUPS.map((group: any, key: any) => (
                <div className="flex w-[100%] gap-[10px]">
                  {/* Join Key */}
                  <div className="w-[100%]">
                    {key === 0 && <p>{MANIFEST_YAML.joinKey}</p>}
                    <input
                      type="text"
                      placeholder={MANIFEST_YAML.joinKeyPlaceholder}
                      onChange={(e) =>
                        updateComputeGroup(
                          key,
                          e.target.value,
                          group.joinSecret,
                        )
                      }
                      value={group.joinKey}
                      className="w-[100%] h-[40px] bg-[#ffffff17] border-solid border-[1px] border-[#A8A8A8] pl-[20px]"
                    />
                  </div>
                  {/* Join Secret */}
                  <div className="w-[100%] flex flex-col">
                    {key === 0 && <p>{MANIFEST_YAML.joinSecret}</p>}
                    <div className="w-[100%] flex gap-[10px]">
                      <input
                        placeholder={MANIFEST_YAML.joinSecretPlaceholder}
                        type="text"
                        onChange={(e) =>
                          updateComputeGroup(key, group.joinKey, e.target.value)
                        }
                        value={group.joinSecret}
                        className="w-[100%] h-[40px] bg-[#ffffff17] border-solid border-[1px] border-[#A8A8A8] pl-[20px]"
                      />
                      <button
                        className="w-[50px] h-[40px] flex justify-center items-center bg-[#ffffff17] border-solid border-[1px] border-[#A8A8A8]"
                        onClick={() => deleteComputeGroup(key)}
                      >
                        <img src={CLOSE} alt="Delete Group" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              {errors.computeGroupError && (
                <FormErrorMsg>{MANIFEST_YAML.errors.computeGroup}</FormErrorMsg>
              )}

              {/* Add a Compute Group */}
              <button
                className="bg-[#ffffff17] h-[100%] min-w-[100%] sm:min-w-[250px] min-h-[40px]"
                onClick={() => {
                  addComputeGroup();
                }}
              >
                {options.COMPUTE_GROUPS.length === 0
                  ? MANIFEST_YAML.addComputeGroup
                  : MANIFEST_YAML.addAnotherRow}
              </button>
            </div>

            {/* Horizontal Line */}
            <div className="w-[100%] h-[1px] bg-[white] my-[10px]" />

            {/* Allow Origins */}
            <div className="flex flex-col">
              <p>
                <span className="font-bold">{MANIFEST_YAML.allowOrigins}</span>{' '}
                {MANIFEST_YAML.allowOriginsMsg}
              </p>
              <textarea
                placeholder={MANIFEST_YAML.allowOriginsPlaceholder}
                className="bg-[#ffffff17] border-solid border-[1px] border-[#A8A8A8] pt-[10px] pl-[20px] h-[90px] resize-none leading-[20px]"
                onChange={(e) => updateAllowOrigins(e.target.value)}
              />
            </div>

            {/* Allow Origins Error */}
            {errors.allowOriginsError && (
              <FormErrorMsg>{MANIFEST_YAML.errors.allowOrigins}</FormErrorMsg>
            )}

            {/* Cancel &  Geerate File*/}
            <div className="flex flex-col xs:flex-row gap-[10px] w-[100%] justify-end">
              <button
                className="bg-[#ffffff17] h-[100%] px-[20px] sm:min-w-[151px] min-h-[40px]"
                onClick={() => setModalOn(false)}
              >
                {MANIFEST_YAML.cancel}
              </button>
              <button
                className="bg-[#ffffff17] h-[100%] px-[20px] sm:min-w-[200px] min-h-[40px] border-[#D9D9D9] border-solid border-[1px]"
                onClick={() => generateYaml()}
              >
                {MANIFEST_YAML.generateFile}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManifestYamlModal;
