import { twMerge } from 'tailwind-merge';
import type { CardPanelModel } from '../../types/cardPanel.model';
import { cn } from '../../utils/utils';
import Margins from '../Margins/Margins';
export default function CardPanel(card: CardPanelModel) {
  return (
    <div
      className={cn(
        'card-panel text-center flex flex-col pt-[1.5rem] pb-[3rem] bg-no-repeat bg-cover h-[100%]',
        { 'text-black': card.textBlack },
        { 'text-white': !card.textBlack },
        card.bgImage,
        card.parentDivClassName,
      )}
    >
      <Margins className={cn('', card.headingClassName)}>
        {card.title && (
          <h2
            className={cn(
              'h2_style text-center px-[0rem] md:px-[2rem] py-[1rem] pb-[1rem] text-xl md:text-4xl font-[700] leading-[1.6rem]',
              card.titleClassName,
            )}
          >
            {card.title}
          </h2>
        )}
        {card.subHeading && (
          <p
            className={cn(
              'p_large_style text-center px-[0rem] md:px-[2rem] text-xs md:text-[1em] pb-[2rem] font-normal leading-[1.5rem]',
              card.subheadingClassName,
            )}
          >
            {card.subHeading}
          </p>
        )}
      </Margins>

      {card.grid ? (
        <div className={cn('grid', card.childrenDivClassName)}>
          {card.children}
        </div>
      ) : (
        <div
          className={cn(
            'flex justify-center gap-0 md:gap-[2rem] flex-wrap ',
            card.childrenGap,
            card.childrenDivClassName,
          )}
        >
          {card.children}
        </div>
      )}
    </div>
  );
}
