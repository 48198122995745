import MediaMention from './MediaMention';
// import Button from "../../Button/Button";
import './Media.scss';
import { ABOUT } from '../../../PageContent/About';
import MediaStories from '../../../PageContent/MentionContent/MediaStories';
import type { MediaMentions } from '../../../types/media-mention.model';

export default function Media() {
  const mediaComponents = MediaStories();

  return (
    // eventually this will be an api call and automized, but for the moment it is hardcoded.
    <div className="flex flex-col justify-evenly gap-[18px]">
      {mediaComponents
        .slice(-ABOUT.media.numOfMentions)
        .reverse()
        .map((media: MediaMentions, index) => (
          <MediaMention
            date={media.date}
            source={media.source}
            headline={media.headline}
            url={media.url}
          />
        ))}
    </div>
  );
}
