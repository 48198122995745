/**
 * @file       PrivacyPolicyPage.jsx
 * @author     Robert Mirandola <robert@distributive.network>
 *
 * @date       October 2022
 *
 *             Portal page for displaying and managing user and identity info
 */

import { Helmet } from 'react-helmet-async';

import HeroPanel from '@/components/HeroPanel/HeroPanel';
import Margins from '@/components/Margins/Margins';
import { PRIVACY_POLICY } from '../../PageContent/PrivacyPolicy';

export function PrivacyPolicyPage(_props) {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={PRIVACY_POLICY.metadata?.description}
        />
        <meta name="keywords" content={PRIVACY_POLICY.metadata?.keywords} />
      </Helmet>

      <HeroPanel
        bgImage="bg-[url(./assets/png/background-with-logo.png)] bg-cover bg-no-repeat !bg-center"
        title={PRIVACY_POLICY.meta?.privacy_policy}
        // fullHeight={true}
        minHeight="min-h-[40vh]"
      />

      <Margins>
        <div className="w-full my-[60px] flex flex-col gap-[20px]">
          <h2>
            <span className="font-bold">
              {PRIVACY_POLICY.meta?.privacy_policy_date}
            </span>
          </h2>
          <p>{PRIVACY_POLICY.paragraph?.privacy_policy_par_1}</p>
          <p>{PRIVACY_POLICY.paragraph?.privacy_policy_par_2}</p>
          <h2>
            <span className="font-bold">
              {PRIVACY_POLICY.commitments?.our_privacy_commitments}
            </span>
          </h2>
          <ul className="ml-[40px]">
            <li>{PRIVACY_POLICY.commitments?.privacy_policy_list_1}</li>
            <li>{PRIVACY_POLICY.commitments?.privacy_policy_list_2}</li>
            <li>{PRIVACY_POLICY.commitments?.privacy_policy_list_3}</li>
            <li>{PRIVACY_POLICY.commitments?.privacy_policy_list_4}</li>
          </ul>
          <p>{PRIVACY_POLICY.paragraph?.privacy_policy_par_3}</p>
          <h2>
            <span className="font-bold">
              {PRIVACY_POLICY.disclaimers?.privacy_policy_disclaimer}
            </span>
          </h2>
          <p>{PRIVACY_POLICY.disclaimers?.privacy_policy_par_4}</p>
          <p>{PRIVACY_POLICY.disclaimers?.privacy_policy_par_5}</p>
          <h2>
            <span className="font-bold">
              {PRIVACY_POLICY.closing?.policy_instructions}
            </span>
          </h2>
          <p>{PRIVACY_POLICY.closing?.privacy_policy_par_6}</p>
          <p>{PRIVACY_POLICY.closing?.privacy_policy_par_7}</p>
          <p>{PRIVACY_POLICY.closing?.privacy_policy_par_8}</p>
        </div>
      </Margins>
    </>
  );
}
