import { useState } from 'react';
import type * as React from 'react';
import './MobileMenu.scss';
import { motion } from 'framer-motion';
import parse from 'html-react-parser';
// import { NavigationSubModel } from "../../../models/navigationItemModel";
import { RiArrowDropDownLine, RiArrowDropRightLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import type { NavigationModel } from '../../../types/navigation.model';

export const MobileMenu: React.FC<NavigationModel> = ({
  currentPageIndex,
  setDropDownMenuOpen,
  items,
}) => {
  const [subnav, setSubnav] = useState(false);
  const showSubnav = () => setSubnav(!subnav);
  const [activeIndex, setActiveIndex] = useState(-1);

  return (
    <motion.div
      initial={{
        translateX: '100%',
        opacity: 0,
      }}
      animate={{
        translateX: '0',
        opacity: 1,
        transition: {
          duration: 0.2,
        },
      }}
      exit={{
        translateX: '100%',
        opacity: 0,
        transition: {
          duration: 0.2,
        },
      }}
      className="dropdown flex justify-start items-center flex-col fixed top-[75px] left-[0] right-[0] bottom-[0] bg-[black] overflow-auto py-[40px]"
    >
      <div className="links-container flex flex-col mt-[3rem] gap-[1.5rem]">
        {items?.map((item, index: number) => (
          <div
            key={index}
            onMouseEnter={() => setActiveIndex(index)}
            onMouseLeave={() => setActiveIndex(-1)}
          >
            <div className="relative">
              <Link
                className="sidebar-link text-[white] text-[1.5rem] text-left"
                to={item.url as string}
                onClick={item.subItems && showSubnav}
                target={item.blankTarget ? '_blank' : ''}
              >
                <div
                  className="w-[100%]"
                  onClick={() => {
                    if (item.subItems) return;
                    setDropDownMenuOpen(false);
                  }}
                >
                  <span
                    className="sidebar-label w-[100%]"
                    style={{
                      display: 'flex',
                      flexFlow: 'row',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}
                  >
                    {parse(item.title as string)}
                    {item.subItems && subnav && activeIndex === index ? (
                      <RiArrowDropRightLine />
                    ) : item.subItems ? (
                      <RiArrowDropDownLine />
                    ) : null}
                  </span>
                </div>
              </Link>
              {currentPageIndex === index && (
                <div className="nav-underline h-[5px] w-[50px] absolute bottom-[-.2rem]" />
              )}
            </div>

            {/* Sub navigation */}
            <div className="">
              {item.subItems && subnav && activeIndex === index && (
                <div className="sub-item-container mt-[1rem] ml-[1.5rem] flex gap-[1.5rem] flex-col">
                  {item.subItems?.map((subItem, subIndex) => (
                    <Link
                      className=""
                      to={subItem.url as string}
                      key={subIndex}
                      onClick={() => {
                        // setCurrentPageIndex(index);
                        setDropDownMenuOpen(false);
                      }}
                      target={item.blankTarget ? '_blank' : ''}
                    >
                      <span className="sidebar-label text-white text-[1.5rem]">
                        {parse(subItem.title as string)}
                      </span>
                    </Link>
                  ))}
                </div>
              )}
            </div>
          </div>
        ))}
        {/* DCP PORTAL */}
        <Link
          className="dcp-portal !h-[fit-content] flex justify-center items-center bg-[#00a871] my-[auto] p-[10px] rounded"
          to="https://secure.distributed.computer/users/sign_in"
          target="_blank"
        >
          DCP PORTAL
        </Link>
      </div>
    </motion.div>
  );
};
