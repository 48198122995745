import type { MediaMentions } from '../../../types/media-mention.model';
import './MediaMention.scss';

export default function MediaMention(content: MediaMentions) {
  let month;
  let day;
  let year;
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  if (content.date !== undefined) {
    year = content.date.getFullYear();
    month = monthNames[content.date.getMonth()];
    day = content.date.getDate();
  }

  return (
    <a href={`${content.url}`} target="_blank" rel="noreferrer">
      <div className="media-mention-container flex flex-col justify-around">
        <div className="p_small_style text-opacity-80 font-light">
          {month}. {day}, {year}: {content.source}
        </div>
        <div className="p_standard_style font-medium">{content.headline}</div>
      </div>
    </a>
  );
}
