import parse from 'html-react-parser';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { BROWSER_WORKER } from '../../PageContent/BrowserWorker';
import CTAPanel from '../../components/CTA/CTAPanel';
import CardPanel from '../../components/CardPanel/CardPanel';
import NumberGlassCard from '../../components/Cards/NumberGlassCard';
import HeroPanel from '../../components/HeroPanel/HeroPanel';
import Margins from '../../components/Margins/Margins';
import Tabs from '../../components/Tabs/Tabs';
import WorkerCard from '../../components/WorkerCard/WorkerCard';

import STYLES from './BrowserWorker.module.scss';

export function BrowserWorker() {
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto', // Optional if you want to skip the scrolling animation
    });
  }, []);
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={BROWSER_WORKER.metadata?.description}
        />
        <meta name="keywords" content={BROWSER_WORKER.metadata?.keywords} />
      </Helmet>
      {/* ------------------------------------------------- Hero Panel ------------------------------------------------- */}
      <HeroPanel
        title={BROWSER_WORKER.hero.title}
        bgImage={BROWSER_WORKER.hero.bgImage}
        minHeight="!min-h-[700px] !2xl:min-h-[700px]"
        contentClassName="relative bottom-[90px]"
      >
        <div className="w-[100%] xl:w-[66%]">
          <h2 className="h2_hero_style">{BROWSER_WORKER.hero.description1}</h2>
          <h2 className="h2_hero_style mt-[40px]">
            {BROWSER_WORKER.hero.description2}
          </h2>
        </div>
      </HeroPanel>

      {/* ------------------------------------------------- Operating System Downloads ------------------------------------------------- */}
      <Margins className="">
        <div className="grid grid-cols-1 lg:grid-cols-3 justify-center items-center gap-[20px] lg:gap-[40px] xl:gap-[120px] relative my-[20px] xl:mt-[-200px]  ">
          {BROWSER_WORKER.howToGetStarted.cards.map((card, key) => (
            <WorkerCard
              key={key}
              content={card}
              borderCardClassName="w-[100%] min-h-[450px] h-[100%] xl:justify-self-center "
              cardClassName="m-[0]"
            >
              {card?.comingSoon && <p>{card?.comingSoon}</p>}
            </WorkerCard>
          ))}
        </div>
      </Margins>

      {/* ------------------------------------------------- How to get started ------------------------------------------------- */}
      <Tabs
        activeTab={activeTab}
        tabs={BROWSER_WORKER.tabs}
        setActiveTab={setActiveTab}
      >
        <Margins>
          <CardPanel
            textBlack={true}
            title={BROWSER_WORKER.installationProcedures[activeTab].pageHeader}
            subHeading={
              BROWSER_WORKER.installationProcedures[activeTab].description
            }
            subheadingClassName="pb-0"
          >
            <iframe
              className={`screensaver-video mt-[25px] w-[100%] lg:w-[900px] xl:w-[1245px] h-[350px] sm:h-[450px] lg:h-[500px] xl:h-[620px] z-[2] ${STYLES.screenSaverVideo}`}
              src={BROWSER_WORKER.installationProcedures[activeTab].video}
              title="How to get started with the screensaver worker"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              width="560"
              height="315"
            ></iframe>
          </CardPanel>
        </Margins>
        {/* ------------------------------------------------- Installation Procedure ------------------------------------------------- */}
        <CardPanel
          title={BROWSER_WORKER.installationProcedures[activeTab].title}
          titleClassName="text-[black]"
          parentDivClassName="bg-[#E6E6E6] w-[100%] !mt-[-200px] pt-[268px]"
          childrenDivClassName="max-w-[3500px] grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-[40px] w-[92%] mx-auto mt-[80px] justify-center items-center "
          grid={true}
        >
          {BROWSER_WORKER.installationProcedures[activeTab].steps.map(
            (step, key) => (
              <NumberGlassCard
                key={key}
                src={step.number}
                divClassName="!bg-[#FFF] xl:max-w-[500px] !w-[100%] !h-[100%] p-[20px] !m-auto box-border"
              >
                <div className="mt-[37px] xl:max-w-[321px] px-[20px] xl:px-[0] pb-[20px] xl:pb-[0px] xl:mx-auto flex flex-col justify-start items-center lg:items-start w-[100%] ">
                  {/* Word Instructions */}
                  {step.instruction && <>{parse(step.instruction || '')}</>}

                  {/* Image Instructions */}
                  <img
                    src={step.img}
                    className="object-fit w-[100%]"
                    alt={`Instruction`}
                  />
                </div>
              </NumberGlassCard>
            ),
          )}
        </CardPanel>
      </Tabs>
      {/* ------------------------------------------------- Footer ------------------------------------------------- */}
      <CTAPanel
        title={BROWSER_WORKER.cta.title}
        subHeading={BROWSER_WORKER.cta.subHeading}
        ctaText={BROWSER_WORKER.cta.ctaText}
        ctaBgColour={'bg-[black]'}
        ctaTextColour={'text-white'}
        bgImage={BROWSER_WORKER.cta.bgImage}
        href={BROWSER_WORKER.cta.href}
      ></CTAPanel>
    </>
  );
}
