import parse from 'html-react-parser';
import type { CardModel } from '../../types/card.model';
import './FaqCard.scss';

export default function FaqCard(card: CardModel) {
  return (
    <div
      className={`flex flex-col items-center justify-center p-[2rem] ${
        card.noGlass ? '' : 'glass'
      } ${card.className} ${
        card.divClassName
      }  box-border gap-[1rem] 3xl:min-w-[480px] max-w-[310px] md:max-w-[460px] xl:max-w-[440px] 3xl:max-w-[480px] 3xl:min-h-[300px] 3xl:max-h-[300px] overflow-y-scroll`}
    >
      {/* Card Title */}
      <h1
        className={`icon-card-title !text-left "!text-[white]" "normal-case"`}
      >
        {parse((card?.title as string) || '')}
      </h1>

      {/* Image */}
      <div className={`${card.imgDivClassName} `}>
        {card.src && (
          <img
            src={card.src}
            alt=""
            className={`${card.imgClassName} icon-card-icon !w-[90px] pb-[1rem] `}
          />
        )}
      </div>
      {/* Description */}
      {/* <p
        className={`p_standard_style text-[white] !text-left  ${
          card.imageFirst ? "order-3" : "order-3"
        } ${card.textBlack ? "!text-[black]" : "!text-[white]"} ${
          card.pClassName
        } `}
      >
        {parse((card?.description as string) || "")}
      </p> */}
      <p className="!m-0 p_standard_style text-[white] !text-left">
        {parse((card?.description as string) || '')}
      </p>

      {/* Children */}
      <div className="order-4">{card.children}</div>
    </div>
  );
}
