export const MANIFEST_YAML = {
  title: 'Manifest File Parameters',
  enterDCPAccount:
    'DCP Bank ACcount that will accumulate Compute Credits for Job Slices completed',
  enterDCPAccountPlaceholder: 'DCP Bank Account',
  selectAccount: 'SELECT',
  numberOfCPUs: '# of CPUs that will execute Job Slices',
  numberOfCPUsPlaceholder: 'Ex. 4',
  computeGroups: 'Compute Groups',
  computeGroupsMsg: 'from which your Worker will fetch Job Slices',
  allowGlobalComputeGroup: 'Join Global Compute Group',
  yes: 'Yes',
  joinKey: 'Join Key',
  joinKeyPlaceholder: 'joinkey',
  joinSecret: 'Join Secret',
  joinSecretPlaceholder: 'joinsecret',
  addAnotherRow: 'ADD A ROW',
  addComputeGroup: 'ADD A ROW',
  allowOrigins: 'Allow Origins: ',
  allowOriginsMsg:
    'URLs from which the Worker will be allowed to fetch Job data',
  allowOriginsPlaceholder:
    'Add URLs separated by a space or line break, Ex:\nhttps://scheduler.distributive.network\nhttps://scheduler.distributive.network',
  cancel: 'CANCEL',
  generateFile: 'GENERATE FILE',
  errors: {
    dcpAccount: 'Please enter valid DCP bank account address.',
    numCPU: 'Please specify the number of CPUs.',
    computeGroup:
      'WARNING: You must either Allow the Global Compute Group or add a minimum of 1 valid Join Key for you workers to receive job slices.',
    allowOrigins:
      'WARNING: you have a semicolon ‘;’ at the end of a URL, if this is in error or used to separate more than one URL please remove the semicolon and separate URLs with a space or put each URL on a separate line. The semicolon will be added to the URL in the Manifest file and may result in an error if it is not part of the URL.',
  },
};
