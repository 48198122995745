import './FrameworkCard.scss';
import parse from 'html-react-parser';
import type { ComponentModel } from '../../types/component.model';
import Button from '../Button/Button';

function FrameworkCard(content: ComponentModel) {
  return (
    <div className="text-center bg-[#081a31] py-[3rem] w-full xl: w-auto xl:px-6 text-white w-full">
      <div className="px-[1rem] flex flex-col justify-between w-[100%] h-[100%] max-w-[700px] m-auto ">
        <div className="pb-[2rem]">
          <h3 className="text-left h4_style pb-[1.3rem]">{content.title}</h3>
          <div className="text-left">
            <p className="pt-2 p_standard_style">
              {parse(content.description || '')}
            </p>
          </div>
        </div>
        <div className="text-center w-[100%] flex">
          <Button
            aClassName="w-[100%]"
            text={content.button?.text}
            url={content.button?.url}
            textColour={content.button?.textColour}
          />
        </div>
      </div>
    </div>
  );
}

export default FrameworkCard;
