function ArticleHeading({ children }: any) {
  return (
    <>
      <h2 className="h2_id h2_style border-t-[1px] border-b-[1px] border-solid border-black py-[10px]">
        {children}
      </h2>
      <br />
    </>
  );
}

export default ArticleHeading;
