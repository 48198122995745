import emailjs from '@emailjs/browser';
import { addDoc, collection } from 'firebase/firestore';
import { useEffect, useRef, useState } from 'react';
import RECAPTCHA from 'react-google-recaptcha';
import { Helmet } from 'react-helmet-async';

import {
  EMAILJS_PUBLIC_KEY,
  EMAILJS_SERVICE_ID,
  EMAILJS_TEMPLATE_ID,
  GOOGLE_RECAPTCHA_SITE_KEY,
} from '@/config';
import { db } from '@/lib/firebase';
import { CONTACT } from '../../PageContent/Contact';
import CTA from '../../components/CTA/CTAPanel';
import CardPanel from '../../components/CardPanel/CardPanel';
import NumberGlassCard from '../../components/Cards/NumberGlassCard';
import HeroPanel from '../../components/HeroPanel/HeroPanel';
import TextImageSplit from '../../components/TextImageSplit/TextImageSplit';
import type { CardModel } from '../../types/card.model';

import './Contact.scss';

export function Contact() {
  const recaptchaRef = useRef<RECAPTCHA>(null);
  const ref = collection(db, 'distributiveemails');
  const [fname, setFName] = useState('');
  const [lname, setLName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [checked, setChecked] = useState(false);
  const [showButton, setShowButton] = useState(true);

  function FormSubmit() {
    if (
      recaptchaRef.current?.getValue() !== null &&
      recaptchaRef.current?.getValue() !== '' &&
      recaptchaRef.current?.getValue() !== undefined
    ) {
      setShowButton(false);
      const data = {
        fname: fname,
        lname: lname,
        email: email,
        message: message,
      };
      const formData = {
        email: email,
        name: `${fname} ${lname}`,
      };
      setFName('');
      setLName('');
      setEmail('');
      setMessage('');
      try {
        addDoc(ref, formData);
      } catch (error) {
        console.error(error);
      }

      emailjs
        .send(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, data, {
          publicKey: EMAILJS_PUBLIC_KEY,
        })
        .then(
          (result) => {},
          (error) => {
            console.log(error.text);
          },
        );

      handleSubmit();
    } else {
      alert('Please verify that you are not a robot.');
    }
  }

  CONTACT.form.button = {
    text: CONTACT.form.button.text,
    onClick: () => {
      FormSubmit();
    },
  };

  const handleSubmit = () => {
    // e.preventDefault();
  };

  const handleClicked = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto', // Optional if you want to skip the scrolling animation
    });
  }, []);

  return (
    <>
      <Helmet>
        <meta name="description" content={CONTACT.metadata?.description} />
        <meta name="keywords" content={CONTACT.metadata?.keywords} />
      </Helmet>
      {/* ------------------------------------------------- HERO ------------------------------------------------- */}

      <HeroPanel
        bgImage={CONTACT.hero.bgImage}
        justifyTop={true}
        title={CONTACT.hero.title}
        description={CONTACT.hero.description}
        minHeight="min-h-[60vh]"
      />

      {/* ------------------------------------------------- HOW TO GET STARTED WITH DCP ------------------------------------------------- */}
      <CardPanel
        title={CONTACT.steps.title}
        subHeading={CONTACT.steps.description}
        bgImage={CONTACT.steps.bgImage}
        childrenDivClassName={'pt-[2rem] xl:pt-[3rem]'}
        childrenGap={'!gap-[3rem]'}
      >
        {CONTACT.steps.cards?.map((card: CardModel, index) => (
          <NumberGlassCard
            className={''}
            key={index}
            src={card.src}
            title={card.title}
            description={card.description}
          />
        ))}
      </CardPanel>

      {/* ------------------------------------------------- DON'T WORRY WE DON'T BYTE ------------------------------------------------- */}
      <TextImageSplit
        title={CONTACT.departments.title}
        subHeading={CONTACT.departments.description}
        wantButton={CONTACT.departments.wantButton}
        textBgImage={CONTACT.departments.textBgImage}
        isTextLeft={CONTACT.departments.isTextLeft}
        bgImage={CONTACT.departments.bgImage}
      >
        <div className="py-[1rem] flex flex-col gap-[0.5rem] md:flex-row md:gap-[1rem] flex-wrap justify-between">
          {CONTACT.departments.contacts.map((position) => (
            <div className="text-white flex flex-col w-[100%] sm:w-[40%]">
              <p className="font-[600] text-[18px] ">{position.department}</p>
              <p className="font-[400] text-[14px]">{position.email}</p>
            </div>
          ))}
        </div>
      </TextImageSplit>

      {/* ------------------------------------------------- GET IN TOUCH ------------------------------------------------- */}
      <TextImageSplit
        title={CONTACT.form.title}
        textBgImage={CONTACT.form.textBgImage}
        wantButton={showButton ? CONTACT.form.wantButton : false}
        button={CONTACT.form.button}
        isTextLeft={CONTACT.form.isTextLeft}
        bgImage={CONTACT.form.bgImage}
        wantAuth={CONTACT.form.wantAuth}
        AuthValue={recaptchaRef.current?.getValue()!}
      >
        <div>
          {showButton ? (
            <form onSubmit={handleSubmit} className="">
              <div className="flex justify-between pb-2 mt-4">
                <input
                  className="buttonclass text-white flex-1 w-2/5 h-10 border border-greyish
                        pl-[1rem] py-[1.2rem] placeholder:text-greyish placeholder:font-[600] placeholder:uppercase placeholder:text-[14px]
                        border-r-[#03a282] border-b-[#03a282] border-t-[#d3d8dc] border-l-[#99a3ab]"
                  type="text"
                  placeholder="First Name"
                  onChange={(e) => setFName(e.target.value)}
                />
                <input
                  className="text-white flex-1 buttonclass border border-greyish placeholder:text-greyish w-2/5 mr-0 ml-5 h-10
              pl-[1rem] py-[1.2rem] placeholder:text-greyish placeholder:font-[600] placeholder:uppercase placeholder:text-[14px]
              border-r-[#03a282] border-b-[#03a282] border-t-[#d3d8dc] border-l-[#99a3ab]"
                  type="text"
                  placeholder="Last Name"
                  onChange={(e) => setLName(e.target.value)}
                />
              </div>

              <div className="pb-2">
                <input
                  className="text-white buttonclass border border-greyish placeholder:text-greyish h-10 w-full
              pl-[1rem] py-[1.2rem] placeholder:text-greyish placeholder:font-[600] placeholder:uppercase placeholder:text-[14px]
              border-r-[#03a282] border-b-[#03a282] border-t-[#d3d8dc] border-l-[#99a3ab]"
                  type="email"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="pb-2">
                <textarea
                  className="text-white buttonclass border border-greyish placeholder:text-greyish w-full h-[100px] resize-none
              pl-[1rem] py-[1.2rem] placeholder:text-greyish placeholder:font-[600] placeholder:uppercase placeholder:text-[14px]
              border-r-[#03a282] border-b-[#03a282] border-t-[#d3d8dc] border-l-[#99a3ab]"
                  placeholder="Message"
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
              <div className="flex flex-row pb-2">
                <input type="checkbox" id="check1" onChange={handleClicked} />
                <label className="text-[#A8A8A8] font-[600] text-[14px] leading-[150%] pl-[0.5rem] w-[100%]">
                  {CONTACT.form.checkbox}
                </label>
              </div>
              <RECAPTCHA
                ref={recaptchaRef}
                sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
              />
            </form>
          ) : (
            <div className="text-white">
              <h2>{CONTACT.form.closingMessage}</h2>
            </div>
          )}
        </div>
      </TextImageSplit>

      {/* ------------------------------------------------- PREFER ANOTHE WAY? NO PROBLEM! ------------------------------------------------- */}
      <TextImageSplit
        title={CONTACT.address.title}
        textBgImage={CONTACT.address.textBgImage}
        subHeading={CONTACT.address.description}
        wantButton={CONTACT.address.wantButton}
        isTextLeft={CONTACT.address.isTextLeft}
        bgImage={CONTACT.address.bgImage}
      >
        {CONTACT.address.info}
      </TextImageSplit>

      {/* ------------------------------------------------- CTA ------------------------------------------------- */}
      <CTA
        title={CONTACT.cta.title}
        subHeading={CONTACT.cta.subHeading}
        ctaText={CONTACT.cta.ctaText}
        ctaBgColour={CONTACT.cta.ctaBgColour}
        ctaTextColour={CONTACT.cta.ctaTextColour}
        bgImage={CONTACT.cta.bgImage}
        href={CONTACT.cta.href}
        noReferrer={true}
      />
    </>
  );
}
