import { useEffect } from 'react';

import { LEGENDRE_PAIRS } from '../../PageContent/LegendrePairs/LegendrePairs';
import { Article } from '../../components/Article/Article';

export function LegendrePairs() {
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto', // Optional if you want to skip the scrolling animation
    });
  }, []);

  return (
    <>
      <Article articleProps={LEGENDRE_PAIRS} />
    </>
  );
}
