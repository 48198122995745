import './FrameworkMainSubcards.scss';
import parse from 'html-react-parser';
import { useMediaQuery } from 'react-responsive';
import type { FrameworkSubCardModel } from '../../types/frameworksubcard.model';

function FrameworkMainSubcards(content: FrameworkSubCardModel) {
  const numColumns = +content.num; // number of columns to display
  const mobile = useMediaQuery({ query: '(max-width: 600px)' });

  const columns = [];
  for (let i = 0; i < numColumns; i++) {
    const subcard = content.data?.[i];
    const title = subcard.title;
    const title2 = subcard.title2;
    const description = subcard.description;
    const subdescription = subcard.subdescription;
    const img = subcard.img;

    if (title || title2) {
      const columnClass = `min-h-[7vh] h-[100%] flex flex-col ${
        title2 ? 'justify-start' : 'justify-center'
      } items-center card`;

      // Pushing the number of columns to display
      columns.push(
        <div className={`${columnClass}`} key={`column-${i}`}>
          {/* ICON */}
          {img && (
            <div className="container flex items-center pt-[1rem]">
              <img
                className={`img mx-auto ${numColumns === 4 ? 'w1' : ''} ${
                  numColumns === 2 ? 'w2' : ''
                } ${numColumns === 3 ? 'w3' : ''}`}
                src={img}
                alt={title}
              />
            </div>
          )}
          {/* TITLE */}
          {title && (
            <h3
              className={`h6_style  !font-bold text px-[.5rem] 3xl:px-[1rem] py-[1rem] ${
                !img ? 'pt-2' : ''
              }`}
            >
              {title}
            </h3>
          )}
          {/* SMALLER TITLE */}
          {title2 && (
            <h3
              className={`h6_style  !font-bold text px-[1rem] 3xl:px-[1rem] py-[1rem] ${
                !img ? 'pt-6' : ''
              }`}
            >
              {title2}
            </h3>
          )}
          {/* DESCRIPTION */}
          {description && (
            <p className="text  pb-4 px-[1rem] 3xl:px-[1rem]">
              {parse(description || '')}{' '}
            </p>
          )}
          {/* SUB_DESCRIPTION */}
          {subdescription && (
            <p className="text  pb-4 px-[1rem] 3xl:px-[1rem]">
              {subdescription}{' '}
            </p>
          )}
        </div>,
      );
    }
  }
  return (
    <div
      className={` mt-[.5rem] md:mt-[.7rem] ${
        mobile
          ? `grid  ${
              content.gridColumns ? `${content.gridColumns}` : 'grid-cols-1'
            } gap-[.5rem] md:gap-[.7rem] justify-center items-center auto-rows-auto`
          : `grid gap-[.5rem] md:gap-[.7rem] !auto-rows-auto justify-center items-center ${
              content.gridColumns ? `${content.gridColumns}` : ''
            }`
      } `}
    >
      {columns}
    </div>
  );
}

export default FrameworkMainSubcards;
