import { ABOUT } from '../../PageContent/About';
import Media from '../../components/Mentions/Media/Media';
import News from '../../components/Mentions/News/News';
import type { ComponentModel } from '../../types/component.model';
import Mentions from './Mentions';
// import Margins from "../../components/Margins/Margins";
import './MentionsBoard.scss';

export default function MentionsBoard(content: ComponentModel) {
  const components = ABOUT;
  return (
    <div className="mentions-board w-full">
      <div className="mentions-board-glass">
        <Mentions
          title={components.news.title}
          description={components.news.description}
          button={components.news.button}
        >
          <News />
        </Mentions>
      </div>
      <div className="mentions-board-clear">
        <Mentions
          title={components.media.title}
          description={components.media.description}
          button={components.media.button}
        >
          <Media />
        </Mentions>
      </div>
    </div>
  );
}
