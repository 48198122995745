import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, HashRouter } from 'react-router-dom';

type AppProviderProps = {
  children: React.ReactNode;
};

// Change routing methods to make serving on different base paths possible.
let Router: typeof BrowserRouter | typeof HashRouter;
let basename: string | undefined;
if (
  typeof import.meta.env.VITE_BASE_PATH !== 'undefined' &&
  import.meta.env.VITE_BASE_PATH !== '/'
) {
  Router = HashRouter;
} else {
  basename = import.meta.env.VITE_BASE_PATH;
  Router = BrowserRouter;
}

export function AppProvider({ children }: AppProviderProps) {
  return (
    <HelmetProvider>
      <Router basename={basename}>{children}</Router>
    </HelmetProvider>
  );
}
