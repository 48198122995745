import parse from 'html-react-parser';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { OSLER } from '../../PageContent/Osler';
import CTAPanel from '../../components/CTA/CTAPanel';
import CardPanel from '../../components/CardPanel/CardPanel';
import IconGlassCard from '../../components/Cards/IconGlassCard';
import NumberGlassCard from '../../components/Cards/NumberGlassCard';
import HeroPanel from '../../components/HeroPanel/HeroPanel';
import Margins from '../../components/Margins/Margins';

export function Osler() {
  const metadata = {
    title: 'Distributive | Solutions | Osler',
    description: 'Block Schedule Creation with Surgical Precision',
    keywords: 'Surgery Surgical Schedule Optimize Block',
  };

  const [featureTab, setFeatureTab] = useState(0);
  const [benefitTab, setBenefitTab] = useState(0);

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto', // Optional if you want to skip the scrolling animation
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>{metadata?.title}</title>
        <meta name="description" content={metadata?.description} />
        <meta name="keywords" content={metadata?.keywords} />
      </Helmet>

      {/* ------------------------------------------------- Hero ------------------------------------------------- */}
      <HeroPanel
        parentDivClassName="!justify-end"
        minHeight="min-h-[450px] max-h-[450px]"
        bgImage={OSLER.hero.bgImage}
      >
        <div className="content flex gap-[3rem] flex-col xl:flex-row items-center xl:items-end justify-between">
          <div className="flex flex-col items-center xl:items-start osler-title-content w-[100%] xl:w-auto lg:min-w-[30vw] lg:max-w-[500px]">
            <img
              className="relative left-[-.6rem] pb-[2rem]"
              src={OSLER.hero.logo}
              alt="osler logo"
            />
            <h1 className="h2_style slogan leading-[1.9rem]">
              {parse((OSLER.hero.title as string) || '')}
            </h1>
          </div>
          <div className="hidden xl:block right-column relative w-[100px] h-[100px]">
            <img
              src={OSLER.hero.calendar}
              alt="osler calendar"
              className="xl:max-w-[650px] 2xl:max-w-[830px] w-[65vw] absolute bottom-[-20rem] right-0"
            />
          </div>
        </div>
      </HeroPanel>

      {/* Calendar Image for mobile devices */}
      <div className="xl:hidden right-column relative flex flex-col items-center justify-center top-[-3rem]">
        <img
          src={OSLER.hero.calendar}
          alt="osler calendar"
          className="max-w-[290px] xs:max-w-[330px] w-[100%]"
        />
        <h3 className="h3_style text-black relative w-[90%] text-center">
          {OSLER.hero.description}
        </h3>
      </div>
      {/* ------------------------------------------------- A solution for today’s problems with measurable results. ------------------------------------------------- */}

      <CardPanel
        parentDivClassName="pt-[0] xl:pt-[200px] relative"
        title="A solution for today’s problems with measurable results."
        textBlack={true}
        childrenDivClassName="justify-around"
      >
        <h3 className="h3_style hidden xl:block text-black font-bold absolute xl:w-[35%] 2xl:w-[30%] top-[2rem] left-[7.5vw] text-left">
          {OSLER.hero.description}
        </h3>
        {OSLER.results.stats.map((result) => (
          <IconGlassCard
            noGlass={true}
            h1ClassName="!tracking-[.1rem] !font-bold"
            imgClassName="!w-[250px] 2xl:!w-[300px] !max-w-none !pb-0"
            imgDivClassName="min-h-[200px] flex items-center items-center"
            pClassName="!font-bold w-[250px] 2xl:w-[280px]"
            divClassName="!justify-start xl:w-[250px] !2xl:w-[100%] !gap-[0] !py-0"
            imageFirst={true}
            textBlack={true}
            src={result.src}
            description={result.description}
          />
        ))}
      </CardPanel>

      {/* ------------------------------------------------- Built with Hospitals for Hospitals ------------------------------------------------- */}
      <CardPanel
        title={OSLER.hospitals.title}
        subHeading={OSLER.hospitals.subTitle}
        bgImage="bg-[url(./assets/png/operation-background.png)] !bg-cover !bg-bottom bg-no-repeat"
        childrenDivClassName="!gap-[1rem] "
      >
        <div className="flex flex-wrap justify-center gap-[1rem] 3xl:gap-[3rem] !px-[1rem] xl:px-0">
          {OSLER.hospitals.cards.map((feature) => (
            <IconGlassCard
              title={feature.title}
              src={feature.src}
              description={feature.description}
              pClassName="font-normal !text-left leading-[1.6rem]"
              imgClassName="!w-[120px] 3xl:!w-[150px]"
              imgDivClassName="!min-h-[90px] flex jusify-center items-center"
              divClassName="!justify-start md:w-[360px] lg:w-[290px] 3xl:w-[350px]"
            />
          ))}
        </div>
      </CardPanel>
      {/* ------------------------------------------------- Introducting Osler ------------------------------------------------- */}
      <CardPanel
        title={OSLER.introducingOsler.title}
        subHeading={OSLER.introducingOsler.subHeading}
        textBlack={true}
      >
        <Margins className="w-[100%] flex justify-center">
          <iframe
            className="osler-video mx-[50px] min-w-[290px] w-[100%] max-w-[1300px] h-[729px] mb-[40px]"
            src={OSLER.introducingOsler.video}
            title="Introduction to Osler"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          />
        </Margins>
      </CardPanel>

      {/* ------------------------------------------------- Features ------------------------------------------------- */}

      <TextImageSplit
        title="Features"
        subHeading="The arrangement of blocks changes the performance of a surgical schedule."
        subHeadingClassName="!font-normal"
        textBgImage={OSLER.features.textBgImage}
        bgImage={OSLER.features.bgImage}
      >
        <TabbedPanel
          layoutId="feature"
          tabs={OSLER.features.tabs}
          tabData={OSLER.features.tabData}
          tabIndex={featureTab}
          setTabIndex={setFeatureTab}
        />
      </TextImageSplit>

      {/* ------------------------------------------------- Benefits ------------------------------------------------- */}
      <TextImageSplit
        title={OSLER.benefits.title}
        subHeading={OSLER.benefits.subHeading}
        isTextLeft={true}
        subHeadingClassName="!font-normal"
        textBgImage={OSLER.benefits.textBgImage}
        bgImage={OSLER.benefits.bgImage}
      >
        <TabbedPanel
          layoutId="benefit"
          tabs={OSLER.benefits.tabs}
          tabData={OSLER.benefits.tabData}
          tabIndex={benefitTab}
          setTabIndex={setBenefitTab}
        />
      </TextImageSplit>

      {/* ------------------------------------------------- Security ------------------------------------------------- */}
      <CardPanel
        textBlack={false}
        title={OSLER.security.title}
        subHeading={OSLER.security.subTitle}
        bgImage={OSLER.security.bgImage}
        childrenDivClassName="mt-[4rem] !gap-[1rem] !justify-evenly"
      >
        {OSLER.security.cards.map((card) => (
          <NumberGlassCard
            title={card.title}
            description={card.description}
            src={card.src}
            divClassName=" !max-w-[500px]"
          />
        ))}
      </CardPanel>

      {/* ------------------------------------------------- Strategic Partners ------------------------------------------------- */}

      <div className="partners m-0">
        <CardPanel
          childrenGap="!gap-[3vw] lg:!gap-[6vw] xl:!gap-[8vw]"
          title={OSLER.partners.title}
          subHeading={OSLER.partners.subTitle}
          textBlack={true}
        >
          {OSLER.partners.partnerList?.map((card, index) => (
            <img
              key={index}
              alt="Partner"
              src={card.src}
              className="h-[150px] w-[150px]"
            />
          ))}
        </CardPanel>
      </div>

      {/* ------------------------------ Join Our Team CTA------------------------------ */}
      <CTAPanel
        title={OSLER.cta.title}
        subHeading={OSLER.cta.subTitle}
        ctaText={OSLER.cta.ctaText}
        ctaBgColour={'bg-[#276cd0]'}
        ctaTextColour={'text-white'}
        bgImage={OSLER.cta.bgImage}
        href={OSLER.cta.href}
      />
    </>
  );
}
