import type { ComponentModel } from '../../types/component.model';
import Button from '../Button/Button';
import './Mentions.scss';

export default function Mentions(content: ComponentModel) {
  return (
    <div className="mentions-container">
      <div className="mentions-title">{content.title}</div>
      <div className="mentions-description">{content.description}</div>
      {content.children}
      <div className="pb-[25px] pt-[1rem]" />
      <Button
        text={content.button?.text}
        url={content.button?.url}
        textColour={`${content.button?.textColour} !w-[100%] md:!w-[50%]`}
      />
    </div>
  );
}
