import type React from 'react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import js from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
import python from 'react-syntax-highlighter/dist/esm/languages/hljs/python';
import docco from 'react-syntax-highlighter/dist/esm/styles/hljs/docco';

SyntaxHighlighter.registerLanguage('javascript', js);
SyntaxHighlighter.registerLanguage('python', python);

type CodeBlockProps = {
  children: React.ReactNode;
  langauge: string;
};

function CodeBlock({
  children,
  langauge: language,
}: CodeBlockProps): JSX.Element {
  return (
    <>
      <div className="w-[100%] flex justify-start">
        <SyntaxHighlighter language={language} style={docco}>
          {children}
        </SyntaxHighlighter>
      </div>

      <br />
    </>
  );
}

export default CodeBlock;
