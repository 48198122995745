import { Outlet } from 'react-router-dom';

import { MainLayout } from '@/components/Layout/MainLayout';

import { About } from '@/pages/About/About';
import { Blog } from '@/pages/Blog/Blog';
import { BrowserWorker } from '@/pages/BrowserWorker/BrowserWorker';
import { Careers } from '@/pages/Careers/Careers';
import { ComputeEconomics } from '@/pages/ComputeEconomics/ComputeEconomics';
import { Contact } from '@/pages/Contact/Contact';
import { DCPWorkers } from '@/pages/DCPWorkers/DCPWorkers';
import { Demo } from '@/pages/Demo/Demo';
import { DockerWorkers } from '@/pages/DockerWorkers/DockerWorkers';
import { Faq } from '@/pages/Faq/Faq';
import { Framework } from '@/pages/Framework/Framework';
import { ContentPage } from '@/pages/Generic/ContentPage';
import { Home } from '@/pages/Home/Home';
import { LegendrePairs } from '@/pages/LegendrePairs/LegendrePairs';
import { Media } from '@/pages/Media/Media';
import { News } from '@/pages/News/News';
import { Osler } from '@/pages/Osler/Osler';
import { PageNotFound } from '@/pages/PageNotFound/PageNotFound';
import { PrivacyPolicyPage } from '@/pages/PrivacyPolicyPage/PrivacyPolicyPage';
import { ScreenSaverWorkers } from '@/pages/ScreenSaverWorkers/ScreenSaverWorkers';

import { Article } from '@/components/Article/Article';
import { Diana } from '@/components/Diana/Diana';

function App() {
  return (
    <MainLayout>
      <Outlet />
    </MainLayout>
  );
}

export const publicRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: '/', element: <Home /> },
      { path: '/*', element: <PageNotFound /> },
      { path: '/osler', element: <Osler /> },
      { path: '/about', element: <About /> },
      { path: '/platform', element: <Framework /> },
      { path: '/faq', element: <Faq /> },
      { path: '/compute-economics', element: <ComputeEconomics /> },
      { path: '/demo', element: <Demo /> },
      { path: '/diana', element: <Diana /> },
      { path: '/careers', element: <Careers /> },
      { path: '/contact', element: <Contact /> },
      { path: '/media', element: <Media /> },
      { path: '/screensaver', element: <ScreenSaverWorkers /> },
      { path: '/docker', element: <DockerWorkers /> },
      { path: '/browser', element: <BrowserWorker /> },
      { path: '/workers', element: <DCPWorkers /> },
      { path: '/lp117', element: <LegendrePairs /> },
      { path: '/jobs/:articleName', element: <Article /> },
      { path: '/news', element: <News /> },
      { path: '/blog', element: <Blog /> },
      { path: '/news/:storyid', element: <ContentPage /> },
      { path: '/people', element: <Blog /> },
      { path: '/policy', element: <PrivacyPolicyPage /> },
    ],
  },
];
