/**
 * @file    SecurityPolicy.js
 *
 * @author  Robert Mirandola <robert@distributive.network>
 * @date    October 2022
 */

export const PRIVACY_POLICY = {
  meta: {
    privacy_policy: 'Privacy Policy',
    privacy_policy_date: 'Updated 20 July, 2022',
    description:
      'Distributive created the Distributive Compute Protocol (DCP). A standard for matching supply and demand for compute to enable anyone, academic or commercial, with a need for computing power to have access to that power within any web connected device on the platform.',
    keywords: 'privacy, policy, security',
  },
  paragraph: {
    privacy_policy_par_1:
      'We at Distributive are providing abundant and cost-effective computing resources for everyone — without exclusion — with the aim of accelerating science, innovation, and discovery.',
    privacy_policy_par_2:
      "Our team comprises many accomplished scientists who have always wholeheartedly advocated for transparency and ethical conduct: previously within our respective research disciplines and now in developing and deploying the Distributive Compute Protocol. I, as a physicist myself, am personally committed to making our platform respectful of our users' privacy and to protecting their data.",
    privacy_policy_par_3:
      'Distributive follows global best practices for collecting and responsibly using customer data. We frequently review our privacy policy and strive to constantly improve the quality of our service.',
  },
  commitments: {
    our_privacy_commitments: 'Our privacy commitments:',
    privacy_policy_list_1:
      'The limited information we collect is used solely to improve our scheduling algorithms.',
    privacy_policy_list_2: 'We do not sell your information',
    privacy_policy_list_3:
      'You have Right-of-access and Right-of-erasure over your own information.',
    privacy_policy_list_4:
      'We keep your information safe. We take security seriously',
  },
  disclaimers: {
    privacy_policy_disclaimer: 'Disclaimer & Statement of Limited Liability',
    privacy_policy_par_4:
      'IN NO EVENT SHALL THE COMPANY BE LIABLE TO ANY PARTY FOR DIRECT, INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, ARISING OUT OF THE USE OF THE SOFTWARE, THE NETWORK AND IT DOCUMENTATION EVEN IF THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.',
    privacy_policy_par_5:
      'THE COMPANY SPECIFICALLY DISCLAIMS ANY WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. THE SOFTWARE, THE NETWORK, AND ACCOMPANYING DOCUMENTATION, IF ANY, PROVIDED HEREUNDER IS PROVIDED "AS IS". THE COMPANY HAS AN OBLIGATION TO PROVIDE MAINTENANCE, SUPPORT, UPDATES, ENHANCEMENTS, OR MODIFICATIONS.',
  },
  closing: {
    policy_instructions: 'Policy & Company Instructions',
    privacy_policy_par_6:
      'Distributive Corp. ("Distributive") is a Canadian corporation which markets the Distributive Compute Protocol ("DCP", "DCP Network", "Product") and its corresponding products, services, and offerings.',
    privacy_policy_par_7:
      'Distributive is bound by the terms of this privacy policy ("Policy", "Agreement", or "Framework"). Distributive will be referred to by "We", "Us", "Company", and "Our" throughout this policy.',
    privacy_policy_par_8:
      'Distributive is the sole data controller for every jurisdiction worldwide, with a registered office at 303 Bagot St. Suite 403, Kingston, K7K 5W7, Ontario, Canada. The corporation is under the prudential supervision of Canadian authorities for tax compliance, legal relations, and all other operating purposes.',
  },
};
