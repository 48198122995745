import { useEffect, useState } from 'react';

import MediaStories from '../../PageContent/MentionContent/MediaStories';
import NewsStories from '../../PageContent/MentionContent/NewsStories';
import { NEWS } from '../../PageContent/NewsList';
import Button from '../../components/Button/Button';
import CTAPanel from '../../components/CTA/CTAPanel';
import HeroPanel from '../../components/HeroPanel/HeroPanel';
import Margins from '../../components/Margins/Margins';
import MediaMention from '../../components/Mentions/Media/MediaMention';
import NewsMention from '../../components/Mentions/News/NewsMention';
import type { MediaMentions } from '../../types/media-mention.model';
import type { NewsStory } from '../../types/news-story.model';

import './News.scss';

export function News() {
  const newsComponents = NewsStories();
  const mediaComponents = MediaStories();
  const components = NEWS;

  const [currentPage, setCurrentPage] = useState(1);

  const totalPosts = newsComponents.length;
  const postsPerPage = 7;
  const PageNumbers = [];

  const numOfPages = Math.ceil(totalPosts / postsPerPage);
  for (let i = 1; i <= numOfPages; i++) {
    PageNumbers.push(i);
  }

  const start = (currentPage - 1) * postsPerPage;
  const end = start + postsPerPage;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HeroPanel
        bgImage="bg-[url(./assets/jpg/green-leftside.jpg)]"
        titleOnly={true}
        title={components.pageTitle}
      />
      <Margins>
        <div className="news-and-mentions-list flex flex-col lg:flex-row justify-between py-[2.5rem] min-h-[800px]">
          <div className="news-list w-full lg:w-[70%] lg:pr-[3rem] flex flex-col justify-between">
            <div>
              <div className="h-[1px] w-full bg-[#D9D9D9] bg-opacity-40" />
              {newsComponents
                .reverse()
                .slice(start, end)
                .map((news: NewsStory, index) => (
                  <>
                    <NewsMention
                      url={`/news/${news.id}`}
                      key={index}
                      headline={news.headline}
                      date={news.date}
                      compact={true}
                      headlineColor="text-black"
                      dateColor="text-black"
                      linkColor="text-[#14AE5C]"
                    />
                    <div className="h-[1px] w-full bg-[#D9D9D9] bg-opacity-40" />
                  </>
                ))}
            </div>
            <div className="w-full pt-[2rem] flex flex-row gap-[1rem] text-black justify-center">
              <button
                className="border-2 w-[2rem] h-[2rem]"
                onClick={() => {
                  if (currentPage > 1) {
                    setCurrentPage(currentPage - 1);
                  }
                }}
              >
                {'<'}
              </button>
              {PageNumbers.map((num) => (
                <button
                  className={`border-2 w-[2rem] h-[2rem] ${
                    currentPage === num
                      ? 'bg-[#14AE5C] border-[#14AE5C]'
                      : 'bg-white'
                  }`}
                  onClick={() => {
                    setCurrentPage(num);
                  }}
                >
                  {num}
                </button>
              ))}

              {/* {numOfPages > 3 ? (
                <>
                  <div className="w-[1rem] h-[1rem] text-center">...</div>
                  {PageNumbers.slice(-1).map((num) => (
                    <button
                      className={`border-2 w-[2rem] h-[2rem] ${
                        currentPage === num
                          ? `bg-[#14AE5C] border-[#14AE5C]`
                          : `bg-white`
                      }`}
                      onClick={() => {
                        setCurrentPage(num);
                      }}
                    >
                      {num}
                    </button>
                  ))}
                </>
              ) : (
                <></>
              )} */}

              <button
                className="border-2 w-[2rem] h-[2rem]"
                onClick={() => {
                  if (currentPage < numOfPages) {
                    setCurrentPage(currentPage + 1);
                  }
                }}
              >
                {'>'}
              </button>
            </div>
          </div>
          <div
            className="media-list bg-[url(./assets/jpg/newspaper.jpg)]
                                    w-full lg:w-[30%] h-fit mt-[2rem] lg:mt-[0rem]
                                    p-[30px] flex flex-col"
          >
            <h1 className="h3_style font-extrabold text-white">
              Latest Media Mentions
            </h1>
            <div className="flex flex-col gap-[1rem] py-[1rem]">
              {mediaComponents
                .slice(-3)
                .reverse()
                .map((media: MediaMentions, index) => (
                  <MediaMention
                    key={index}
                    headline={media.headline}
                    date={media.date}
                    source={media.source}
                    url={media.url}
                  />
                ))}
            </div>
            <Button
              url="/media"
              text="VIEW ALL MEDIA MENTIONS"
              textColour="text-white"
            />
          </div>
        </div>
      </Margins>
      <CTAPanel
        title={components.cta.title}
        subHeading={components.cta.subHeading}
        ctaText={components.cta.ctaText}
        ctaBgColour={components.cta.ctaBgColour}
        ctaTextColour={components.cta.ctaTextColour}
        bgImage={components.cta.bgImage}
        href={components.cta.href}
      />
    </>
  );
}
