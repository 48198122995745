import { useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';

import { BLOG } from '../../PageContent/Blog';
import ArticleCard from '../../components/ArticleCard/ArticleCard';
import CTAPanel from '../../components/CTA/CTAPanel';
import HeroPanel from '../../components/HeroPanel/HeroPanel';
import Margins from '../../components/Margins/Margins';

import './Blog.scss';

export function Blog() {
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto', // Optional if you want to skip the scrolling animation
    });
  }, []);

  return (
    <>
      <HeroPanel
        title={BLOG.hero.title}
        description={BLOG.hero.description}
        bgImage={BLOG.hero.bgImage}
        minHeight="min-h-[684px]"
      />
      {/* -------------------------------------------- Article Cards -------------------------------------------- */}
      <Margins className="hidden xl:block">
        <div className="flex justify-center items-center gap-[20px] 3xl:gap-[50px] relative mt-[60px] xl:mt-[-100px] mb-[50px] xl:mb-[100px]">
          {BLOG.article.cards.map((card) => (
            <ArticleCard content={card} borderCardClassName="" />
          ))}
        </div>
      </Margins>
      <div className="flex xl:hidden mt-[-100px] mb-[50px]">
        <Carousel
          className="blog-carousel"
          // autoPlay
          swipeable={true}
          autoFocus
          showArrows={false}
          showStatus={false}
        >
          {BLOG.article.cards.map((card) => (
            <ArticleCard
              content={card}
              borderCardClassName="max-w-[95%] md:max-w-[90%]"
            />
          ))}
        </Carousel>
      </div>

      {/* -------------------------------------------- Leadership -------------------------------------------- */}
      {/* <EmployeeGrid
        title={BLOG.people.title}
        numOfCols={BLOG.people.numOfCols}
        subHeading={BLOG.people.subTitle}
        bgImage={BLOG.people.bgImage}
        leaders={BLOG.people.leaders}
      /> */}

      {/* -------------------------------------------- CTA -------------------------------------------- */}
      <CTAPanel
        title={BLOG.cta.title}
        subHeading={BLOG.cta.subHeading}
        ctaText={BLOG.cta.ctaText}
        ctaBgColour={BLOG.cta.ctaBgColour}
        ctaTextColour={BLOG.cta.ctaTextColour}
        bgImage={BLOG.cta.bgImage}
        href={BLOG.cta.href}
      />
    </>
  );
}
