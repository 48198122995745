import { AnimatePresence, motion } from 'framer-motion';
import parse from 'html-react-parser';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { RiSuitcaseLine } from 'react-icons/ri';
import { useMediaQuery } from 'react-responsive';
import { Carousel } from 'react-responsive-carousel';
import { NavLink } from 'react-router-dom';

import { CAREERS } from '../../PageContent/Careers';
import { DIANA } from '../../PageContent/Diana';
import { HOME } from '../../PageContent/Home';
import Button from '../../components/Button/Button';
import Margins from '../../components/Margins/Margins';
import TextImageSplit from '../../components/TextImageSplit/TextImageSplit';

import './Home.scss';

const MAX_TITLES = HOME.hero.computeForList.length - 1;

export function Home() {
  const smSize = useMediaQuery({ query: '(min-width:768px)' });

  const [featureId, setFeatureId] = useState(0);
  const [titleIndex, setTitleIndex] = useState(0);
  const [computeFeaturePosition, setComputeFeaturePosition] =
    useState<any>(null);
  const computeFeatureRef = useRef<HTMLHeadingElement>(null);
  const [delayActive, setDelayActive] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  // Used to add back in the title element for animation purposes
  useEffect(() => {
    setIsVisible(true);
  }, []);

  // Used to get the height of the title position
  useEffect(() => {
    function handleScroll() {
      if (computeFeatureRef.current !== null) {
        const rect = computeFeatureRef.current.getBoundingClientRect();
        setComputeFeaturePosition(rect.top + window.pageYOffset);
      }
    }

    handleScroll();
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Used as a delay setting for the title load ins
  useEffect(() => {
    setIsVisible(true);
    if (titleIndex === 0 || titleIndex === MAX_TITLES) setDelayActive(true);
    else setDelayActive(false);
  }, [titleIndex]);

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto', // Optional if you want to skip the scrolling animation
    });
  }, []);
  return (
    <>
      <Helmet>
        <meta name="description" content={HOME.metadata?.description} />
        <meta name="keywords" content={HOME.metadata?.keywords} />
      </Helmet>
      <div className="home">
        {/* ------------------------------------------------- HERO ------------------------------------------------- */}
        <section
          className={`home-hero min-h-[100vh] md:min-h-[75vh] xl:min-h-[92vh] flex flex-col pb-[0]  md:pt-[7rem] md:pb-[0] lg:pb-0 justify-end md:justify-center lg:justify-center items-center ${HOME.hero.bgImage}`}
        >
          <Margins>
            <div className="flex flex-col text-white mb-[6rem] lg:mb-[9rem]">
              {/* Compute.for */}
              <div className="home-title flex items-center overflow-y-visible">
                <h1 className="text-[1.1rem] md:text-[2.2rem] lg:text-[2.8rem] xl:text-[2.4rem] 2xl:text-[2.8rem] 3xl:text-[3rem] font-semibold font-[courier] ">
                  {HOME.hero.title}
                </h1>

                <div className=" font-[courier] font-bold text-[1.1rem] md:text-[2.2rem] lg:text-[2.8rem] xl:text-[2.4rem] 2xl:text-[2.8rem] 3xl:text-[3rem]">
                  (
                </div>

                {/* (List) */}
                <div
                  className="w-[130px] md:w-[220px] lg:w-[270px] xl:w-[240px] 2xl:w-[270px] 3xl:w-[290px] h-[67.2px] relative overflow-y-visible flex justify-start items-center"
                  ref={computeFeatureRef}
                >
                  <AnimatePresence
                    onExitComplete={() => {
                      setTitleIndex((prevVal) => {
                        const newIdx = prevVal + 1;
                        return newIdx > MAX_TITLES ? 0 : newIdx;
                      });
                    }}
                  >
                    {isVisible && (
                      <motion.div
                        className="w-[130px] md:w-[220px] lg:w-[270px] xl:w-[240px] 2xl:w-[270px] 3xl:w-[290px] text-center applications absolute overflow-y-visible"
                        key={HOME.hero.computeForList[titleIndex]}
                        initial={{
                          y:
                            computeFeaturePosition == null
                              ? -366
                              : -computeFeaturePosition,
                          opacity: 0,
                        }}
                        animate={{
                          y: 0,
                          opacity: 1,
                        }}
                        transition={{
                          duration: 0.2, //How long it will tkae to slide into frame
                          opacity: { duration: 0.3 }, //How long it will take to fade into frame
                          ease: 'easeInOut',
                        }}
                        exit={{
                          y: 100,
                          opacity: 0,
                          transition: {
                            delay: delayActive ? 2.5 : 1.1, //Delays how long the title will show
                            duration: 0.15, //How long it will take to slide out of frame
                          },
                        }}
                        onAnimationComplete={() => {
                          setIsVisible(false);
                        }}
                      >
                        <h1
                          id="computeTitle"
                          className={
                            'font-[courier] text-[1.3rem] md:text-[2.2rem] lg:text-[2.8rem] xl:text-[2.4rem] 2xl:text-[2.8rem] 3xl:text-[3rem] font-semibold'
                          }
                        >
                          {/* ( */}
                          <span className="text-[#17CD6C] font-[courier]">
                            {HOME.hero.computeForList[titleIndex]}
                          </span>
                          {/* ) */}
                        </h1>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>

                <div className="font-[courier] font-bold text-[1.1rem] md:text-[2.2rem] lg:text-[2.8rem] xl:text-[2.4rem] 2xl:text-[2.8rem] 3xl:text-[3rem]">
                  )
                </div>
              </div>

              {/* Hero Content */}
              <div className="w-[100%] md:w-[67%] xl:w-[50%] 2xl:w-[50%] 3xl:w-[43%] mt-[1.5rem] 3xl:mt-[2rem]">
                {/* Subheading */}
                <h2 className="text-[1rem] lg:text-[1.3rem] xl:text-[1.5rem] 2xl:text-[1.7rem] 3xl:text-[2rem] md:leading-[1.7rem] xl:leading-[2rem] 3xl:leading-[2.3rem] font-bold">
                  {parse(HOME.hero.subheading as string)}
                </h2>
                {/* Subheading Description */}
                <p className="p_standard_style mt-[2rem]">
                  {HOME.hero.subheadingDescription}
                </p>
              </div>
            </div>
          </Margins>
        </section>

        {/* ------------------------------------------------- DCP PLATFORM ------------------------------------------------- */}
        <section className="dcp-features">
          <Margins className="flex flex-col justify-center items-center">
            <iframe
              className="dcp-features-video relative my-[3rem] top-[0rem] sm:top-[-7rem] min-w-[95%] md:min-w-[670px] lg:min-w-[780px] xl:min-w-[1000px] 2xl:min-w-[1100px] 3xl:min-w-[1200px] min-h-[200px] xss:min-h-[220px] sm:min-h-[400px] md:min-h-[550px] 2xl:min-h-[570px] 3xl:min-h-[660px]"
              src={HOME.platform.video}
              title="Distributive Mission Statement"
              allow="web-share"
              allowFullScreen
            />
            <div className="relative top-[-1rem] lg:top-[-2rem] xl:top-[-4rem] flex flex-col justify-center items-center text-center">
              <h1 className="font-bold text-[1.7rem] tracking-tight pb-[1rem]">
                {HOME.platform.title.slice(
                  0,
                  HOME.platform.title.indexOf('(') + 1,
                )}
                <span className="text-[#17CD6C]">
                  {HOME.platform.title.slice(
                    HOME.platform.title.indexOf('(') + 1,
                    HOME.platform.title.indexOf(')'),
                  )}
                </span>
                )
              </h1>
              <p className="p_large_style text-[1rem] leading-[1.5rem] max-w-[1200px]">
                {HOME.platform.description}
              </p>
              <p className="p_large_style mt-[30px]">
                {HOME.platform.howItWorks}
              </p>
            </div>
          </Margins>
          {smSize ? (
            <>
              <div className="features-tab grid grid-cols-4 justify-between gap-[2rem] mx-[2rem] mt-[-60px] md:mt-[-10px] lg:mt-[-20px] xl:mt-[-50px]">
                {HOME.platform.tabs.map((tabName, key) => (
                  <div
                    className="relative flex flex-col items-w-[100%] my-[2rem]"
                    key={`div1${key}`}
                  >
                    <button
                      onClick={() => {
                        setFeatureId(key);
                      }}
                      className={`w-[100%] pb-[.4rem] font-semibold text-xs md:text-base ${
                        featureId === key ? ' text-black' : 'text-[#BABABA]'
                      }`}
                      key={`button${key}`}
                    >
                      {tabName.name}
                    </button>
                    <div
                      key={`div2${key}`}
                      className={`w-[100%] h-[3px] ${
                        featureId === key ? 'bg-[#02C180]' : 'bg-[#B9B9B9]'
                      } rounded-xl`}
                    />
                  </div>
                ))}
              </div>
              <div className="feature-content relative overflow-hidden text-center h-[550px] md:min-h-[75vh] 2xl:min-h-[60vh] bg-[url(./assets/png/homepage-platform-background.jpg)] bg-cover bg-center">
                {/* Deploy*/}
                <div
                  className={`feature-content-info absolute md:top-[0] w-[400px] h-[95%] md:left-[3%] lg:left-[3%] ${
                    featureId === 0 ? 'opacity-100' : 'opacity-0'
                  } transition duration-700`}
                >
                  <p className="text-left font-semibold text-[24px]">
                    {HOME.platform.tabs[0].title}
                  </p>
                  <p className="p_small_style text-left whitespace-pre-line">
                    {HOME.platform.tabs[0].text}
                  </p>
                  <Button
                    url={'/platform'}
                    isDigDeeper={true}
                    text="DIG DEEPER INTO THE PLATFORM"
                    textColour="text-white"
                    bgColour="!bg-[#0E2439]"
                  />
                </div>
                {/* Compute */}
                <div
                  className={`feature-content-info absolute w-[400px] h-[95%] md:left-[3%] lg:left-[3%] ${
                    featureId === 1 ? 'opacity-100' : 'opacity-0 hidden'
                  } transition duration-700`}
                >
                  <p className="text-left font-semibold text-[24px]">
                    {HOME.platform.tabs[1].title}
                  </p>
                  <p className="p_small_style text-left whitespace-pre-line">
                    {HOME.platform.tabs[1].text}
                  </p>
                  <Button
                    url={'/platform'}
                    isDigDeeper={true}
                    text="DIG DEEPER INTO THE PLATFORM"
                    textColour="text-white"
                    bgColour="!bg-[#0E2439]"
                  />
                </div>
                {/* Organize */}
                <div
                  className={`feature-content-info absolute w-[400px] h-[95%] md:right-[57%] lg:right-[3%] ${
                    featureId === 2 ? 'opacity-100' : 'opacity-0 hidden'
                  } transition duration-300`}
                >
                  <p className="text-left font-semibold text-[24px]">
                    {HOME.platform.tabs[2].title}
                  </p>
                  <p className="p_small_style text-left whitespace-pre-line">
                    {HOME.platform.tabs[2].text}
                  </p>
                  <Button
                    url={'/platform'}
                    isDigDeeper={true}
                    text="DIG DEEPER INTO THE PLATFORM"
                    textColour="text-white"
                    bgColour="!bg-[#0E2439]"
                  />
                </div>
                {/* Pay/Get Paid */}
                <div
                  className={`feature-content-info absolute w-[400px] h-[95%] md:right-[57%] lg:right-[3%] ${
                    featureId === 3 ? 'opacity-100' : 'opacity-0 hidden'
                  } transition duration-700`}
                >
                  <p className="text-left font-semibold text-[24px]">
                    {HOME.platform.tabs[3].title}
                  </p>
                  <p className="p_small_style text-left whitespace-pre-line">
                    {HOME.platform.tabs[3].text}
                  </p>
                  <Button
                    url={'/platform'}
                    isDigDeeper={true}
                    text="DIG DEEPER INTO THE PLATFORM"
                    textColour="text-white"
                    bgColour="!bg-[#0E2439]"
                  />
                  {/* <NavLink
                  to="/"
                  className={`
                  text-white font-bold !text-sm w-full
                  text-center w-[100%] dig-deeper-button cursor-pointer px-[2rem] py-[.5rem]`}
                >
                  {HOME.platform.navText}
                </NavLink> */}
                </div>
                <img
                  className={
                    'feature-image-container absolute left-[50%] top-[20%] md:h-[90%] md:left-[65%] lg:h-[130%] lg:left-[50%] md:top-[50%] translate-y-[-45%] translate-x-[-50%]'
                  }
                  src={HOME.platform.images.base}
                  alt=""
                />
                <img
                  className={`feature-image-container absolute left-[50%] top-[20%] md:h-[90%] md:left-[65%] lg:h-[130%] lg:left-[50%] md:top-[50%] translate-y-[-45%] translate-x-[-50%] transition duration-1000 ${
                    featureId === 3 ? 'opacity-100' : 'opacity-20'
                  }`}
                  src={HOME.platform.images.bank}
                  alt=""
                />
                <img
                  className={`feature-image-container absolute left-[50%] top-[20%] md:h-[90%] md:left-[65%] lg:h-[130%] lg:left-[50%] md:top-[50%] translate-y-[-45%] translate-x-[-50%] transition duration-1000 ${
                    featureId === 1 || featureId === 2
                      ? 'opacity-100'
                      : 'opacity-20'
                  }`}
                  src={HOME.platform.images.servers}
                  alt=""
                />
                <img
                  className={
                    'feature-image-container absolute left-[50%] top-[20%] md:h-[90%] md:left-[65%] lg:h-[130%] lg:left-[50%] md:top-[50%] translate-y-[-45%] translate-x-[-50%]'
                  }
                  src={HOME.platform.images.dcp}
                  alt=""
                />
                <img
                  className={`feature-image-container absolute left-[50%] top-[20%] md:h-[90%] md:left-[65%] lg:h-[130%] lg:left-[50%] md:top-[50%] translate-y-[-45%] translate-x-[-50%] transition duration-1000 ${
                    featureId === 0 || featureId === 3
                      ? 'opacity-100'
                      : 'opacity-20'
                  }`}
                  src={HOME.platform.images.user}
                  alt=""
                />
                <img
                  className={`feature-image-container absolute left-[50%] top-[20%] md:h-[90%] md:left-[65%] lg:h-[130%] lg:left-[50%] md:top-[50%] translate-y-[-45%] translate-x-[-50%] transition duration-1000 ${
                    featureId === 1 || featureId === 2
                      ? 'opacity-100'
                      : 'opacity-20'
                  }`}
                  src={HOME.platform.images.workers}
                  alt=""
                />
              </div>
            </>
          ) : (
            <div className="">
              <div className="feature-content text-center">
                <img
                  className="mt-0"
                  src={HOME.platform.images.completenobg}
                  alt=""
                />
              </div>
              <div className="flex flex-col justify-center feature-content-info-mobile mt-[-20px]">
                <Carousel
                  // autoPlay={true}
                  swipeable={true}
                  autoFocus
                  showArrows={false}
                  showStatus={false}
                  showIndicators={true}
                  showThumbs={false}
                  dynamicHeight={false}
                  className=""
                >
                  {HOME.platform.tabs.map((card) => (
                    <div className={'flex flex-col justify-start h-fit'}>
                      <p className="text-left font-semibold text-[24px] pb-[1rem]">
                        {card.title}
                      </p>
                      <p className="p_small_style text-left whitespace-pre-line">
                        {card.text}
                      </p>
                    </div>
                  ))}
                </Carousel>
                <NavLink
                  to={HOME.platform.navLink}
                  className={`
                  text-white font-bold !text-sm w-full
                  text-center w-[100%] dig-deeper-button cursor-pointer px-[2rem] py-[.5rem]`}
                >
                  {HOME.platform.navText}
                </NavLink>
              </div>
            </div>
          )}
        </section>

        {/* ------------------------------------------------- SOLUTION1 SPOTLIGHT ------------------------------------------------- */}
        <TextImageSplit
          title={HOME.solution1.title}
          textBgImage={HOME.solution1.textBgImage}
          isTextLeft={true}
          bgImage={HOME.solution1.bgImage}
          wantButton={true}
          button={HOME.solution1.button}
        >
          <div className="text-white py-[2rem]">
            <img
              src={HOME.solution1.solutionImage}
              alt="Osler Logo"
              className="max-w-[190px] pb-[3rem]"
            />
            <p className="pb-[2rem] text-[1rem] font-bold">
              {HOME.solution1.subheading}
            </p>
            <p>{HOME.solution1.description}</p>
          </div>
        </TextImageSplit>

        {/* ------------------------------------------------- Solution2 Spotlight ------------------------------------------------- */}
        <TextImageSplit
          title={HOME.solution2.title}
          textBgImage={HOME.solution2.textBgImage}
          isTextLeft={false}
          bgImage={HOME.solution2.bgImage}
          wantButton={true}
          button={HOME.solution2.button}
        >
          <div className="text-white py-[1.5rem]">
            <div className="overwatch-title relative mb-[1.8rem] top-[0]">
              <div className="overwatch-title relative mb-[1.8rem] top-[0]">
                <img
                  src={DIANA.hero.imgTitle}
                  alt="DIANA"
                  className="w-[200px]"
                />
              </div>
            </div>
            <p className="pb-[2rem] text-[1rem] font-bold">
              {HOME.solution2.subheading}
            </p>
            <p>{HOME.solution2.description}</p>
          </div>
        </TextImageSplit>

        {/* ------------------------------------------------- Join The Team ------------------------------------------------- */}
        <TextImageSplit
          title={HOME.joinTheTeam.title}
          textBgImage={HOME.joinTheTeam.textBgImage}
          isTextLeft={true}
          bgImage={HOME.joinTheTeam.bgImage}
          wantButton={true}
          button={HOME.joinTheTeam.button}
          subHeading={HOME.joinTheTeam.subheading}
        >
          <p className="p_standard_style mt-[-1rem] font-bold">
            {parse(CAREERS.joinTeam.subHeading2 || '')}
          </p>
          <div className="text-white pt-[1rem] pb-[1rem]">
            {CAREERS.joinTeam.openPositions.map((position, key) => (
              <a
                key={key}
                className="position flex gap-[1rem] text-white items-center hover:text-sky-500"
                href={position.link}
                target="_blank"
                rel="noreferrer"
              >
                <p>
                  <RiSuitcaseLine />
                </p>
                <p>{position.name}</p>
              </a>
            ))}
          </div>
        </TextImageSplit>
      </div>
    </>
  );
}
