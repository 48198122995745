import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
function WorkerCard({
  content,
  children,
  cardClassName,
  borderCardClassName,
  iconClassName,
  toastTrigger,
  setModalOn,
}: any) {
  const copyToClipboard = (message: string) => {
    navigator.clipboard
      .writeText(message)
      .then(() => {
        console.log(`Text copied to clipboard: ${message}`);
        toastTrigger('Text copied to clipboard!');
      })
      .catch((e) => {
        console.log('unable to copy text: ', e);
        toastTrigger('Failed to copy to clipboard.');
      });
  };

  return (
    <>
      <div
        className={twMerge(
          'bg-[#D9D9D933] flex justify-center items-center p-[10px] 3xl:p-[20px]',
          borderCardClassName,
        )}
      >
        <div
          className={twMerge(
            'content-content bg-[#0D2438] text-[#fff] flex flex-col w-[100%] h-[100%] justify-between items-center px-[20px] py-[30px] text-center',
            cardClassName,
          )}
        >
          {/* Top Section */}
          <div className="top-section flex flex-col justify-start items-center">
            <h3 className="h3_style text-[#fff] ">
              {parse(content?.title || '')}
            </h3>

            {/* Icon */}
            <div className="flex flex-col items-center justify-start mt-[30px]">
              <img
                src={content?.img}
                alt="Download Icon"
                className={twMerge('w-[70px] lg:w-[80px] ', iconClassName)}
              />
              {children}
            </div>
          </div>

          {/* Bottom Section */}
          <div className="bottom-section h-[100%] flex flex-col justify-end items-center text-center break-word">
            {/* Coming Soon */}
            {content?.comingSoon && (
              <div className="self-center">Coming Soon...</div>
            )}

            {/* Link 1 */}
            {content?.link1Href && (
              <a
                className="p_standard_style text-[#0D99FF] font-bold "
                href={content?.link1Href}
              >
                {content?.link1Text}
              </a>
            )}

            {/* Download Button (Link)*/}
            {!content?.isButtonNotLink &&
              content?.link1Href &&
              content?.buttonText && (
                <Link
                  to={content?.link1Href}
                  className="flex gap-[10px] flex-wrap justify-center items-center min-w-[200px] my-[15px] text-center bg-[#FFFFFF33] border-solid border-[1px] border-[#FFFFFF33] p-[12px] text-[20px]"
                >
                  {content?.buttonText}{' '}
                  {content?.buttonIcon && (
                    <img
                      src={content?.buttonIcon}
                      alt="icon"
                      className="w-[24px] h-[24px]"
                    />
                  )}
                </Link>
              )}

            {/* Download Button (Button)*/}
            {content?.isButtonNotLink && (
              <button
                onClick={() => {
                  if (content?.copyToClipboard) {
                    copyToClipboard(content?.clipBoardMessage);
                  }
                  if (content?.generateYaml) {
                    setModalOn(true);
                  }
                }}
                className="flex gap-[10px] flex-wrap justify-center items-center min-w-[200px] my-[15px] text-center bg-[#FFFFFF33] border-solid border-[1px] border-[#FFFFFF33] p-[12px] text-[20px]"
              >
                {content?.buttonText}{' '}
                {content?.buttonIcon && (
                  <img
                    src={content?.buttonIcon}
                    alt="icon"
                    className="w-[24px] h-[24px]"
                  />
                )}
              </button>
            )}

            {/* Link 2 */}
            {content?.buttonSubText != null && (
              <p className="p_standard_style font-bold text-[#fff] min-h-[24px]">
                {content?.buttonSubText}
              </p>
            )}

            {/* Version Archive */}
            {content?.link2Href && (
              <Link
                to={content?.link2Href}
                target={content?.link2Href !== '#' ? '_blank' : ''}
                rel="noreferrer"
                className="p_standard_style text-[#0D99FF] font-bold min-h-[24px]"
              >
                {content?.link2Text}
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default WorkerCard;
