import parse from 'html-react-parser';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { RiSuitcaseLine } from 'react-icons/ri';
import { useMediaQuery } from 'react-responsive';
import { Carousel } from 'react-responsive-carousel';

import { CAREERS } from '../../PageContent/Careers';
import CTAPanel from '../../components/CTA/CTAPanel';
import CardPanel from '../../components/CardPanel/CardPanel';
import ImageGlassCard from '../../components/Cards/IconGlassCard';
import HeroPanel from '../../components/HeroPanel/HeroPanel';
import PeriodicTable from '../../components/PeriodicTable/PeriodicTable';
import TextImageSplit from '../../components/TextImageSplit/TextImageSplit';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import './Careers.scss';

export function Careers() {
  const mdSize = useMediaQuery({ query: '(min-width:768px)' });

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto', // Optional if you want to skip the scrolling animation
    });
  }, []);

  return (
    <>
      <Helmet>
        <meta name="description" content={CAREERS.metadata?.description} />
        <meta name="keywords" content={CAREERS.metadata?.keywords} />
      </Helmet>
      <div className="careers">
        {/* ------------------------------ Hero Panel ------------------------------ */}
        <HeroPanel
          fullHeight={true}
          bgImage={CAREERS.hero.bgImage}
          title={CAREERS.hero.title}
          description={CAREERS.hero.subTitle}
          justifyTop={true}
          subHeadingClassName="!font-bold"
          contentClassName="max-w-[3500px] m-auto"
        >
          <p className="p_standard_style text-xl text-white pb-[0.5rem] font-bold">
            {CAREERS.hero.periodicTitle}
          </p>
          <PeriodicTable />
        </HeroPanel>

        {/* ------------------------------ Card Panel ------------------------------ */}
        <CardPanel
          title={CAREERS.whyDistributive.title}
          subHeading={CAREERS.whyDistributive.subTitle}
          bgImage={CAREERS.whyDistributive.bgImage}
        >
          {mdSize ? (
            <>
              {CAREERS.whyDistributive.cards.map((card, index) => (
                <ImageGlassCard
                  divClassName="!justify-start"
                  src={card.src}
                  title={card.title}
                  description={card.description}
                  key={index}
                />
              ))}
            </>
          ) : (
            <Carousel
              // autoPlay
              swipeable={true}
              autoFocus
              showArrows={false}
              showStatus={false}
            >
              {CAREERS.whyDistributive.cards.map((card, index) => (
                <ImageGlassCard
                  src={card.src}
                  title={card.title}
                  description={card.description}
                  divClassName="!h-[100%]"
                  key={index}
                />
              ))}
            </Carousel>
          )}
        </CardPanel>

        {/* ------------------------------ Join the Team ------------------------------ */}
        <TextImageSplit
          title={CAREERS.joinTeam.title}
          subHeading={CAREERS.joinTeam.subHeading}
          bgImage={CAREERS.joinTeam.bgImage}
          isTextLeft={true}
          textBgImage={CAREERS.joinTeam.textBgImage}
        >
          <p className="p_standard_style mt-[-1rem] font-bold">
            {parse(CAREERS.joinTeam.subHeading2)}
          </p>
          <div className="p_standard_style pt-[1rem]">
            {CAREERS.joinTeam.openPositions.map((position, key) => (
              <a
                key={key}
                className="position flex gap-[1rem] text-white items-center hover:text-sky-500"
                href={position.link}
                target="_blank"
                rel="noreferrer"
              >
                <p>
                  <RiSuitcaseLine />
                </p>
                <p>{position.name}</p>
              </a>
            ))}
          </div>
        </TextImageSplit>

        {/* ------------------------------ Intern Program ------------------------------ */}
        <TextImageSplit
          title={CAREERS.internProgram.title}
          subHeading={CAREERS.internProgram.subHeading}
          description={CAREERS.internProgram.description}
          bgImage={CAREERS.internProgram.bgImage}
          isTextLeft={false}
          wantButton={true}
          button={CAREERS.internProgram.button}
          textBgImage={CAREERS.internProgram.textBgImage}
        />

        {/* ------------------------------ Join Our Team CTA------------------------------ */}
        <CTAPanel
          title={CAREERS.cta.title}
          subHeading={CAREERS.cta.subHeading}
          ctaText={CAREERS.cta.ctaText}
          ctaBgColour={CAREERS.cta.ctaBgColour}
          ctaTextColour={CAREERS.cta.ctaTextColour}
          bgImage={CAREERS.cta.bgImage}
          href={CAREERS.cta.href}
        />
      </div>
    </>
  );
}
