// import WEBSITE_ICON from "../../../assets/png/website_icon.png";
import GITHUB from '../../../assets/svg/github_icon.svg';
import LINKEDIN_ICON from '../../../assets/svg/linkedin_icon.svg';
import MAIL_ICON from '../../../assets/svg/mail_icon.svg';
// import TWITTER from "../../../assets/svg/twitter_icon.svg";
import ArticleHeading from '../../../components/ArticleHeading/ArticleHeading';
import ArticleParagraph from '../../../components/ArticleParagraph/ArticleParagraph';

export const ERIN_HERO = {
  title: 'Erin Peterson',
  description: '<PLACEHOLDER>',
  bgImage:
    'bg-[url(./assets/png/article_backgrounds.png)] bg-cover bg-no-repeat !bg-center',
  image: '<PLACEHOLDER>', //Eg. PYTHON_MONKEY_IMAGE, (Note this is a imported image file like WEBSITE_ICON)
};

export const ERIN_SIDE_INFO = {
  date: 'December 4th, 2023',
  firstName: 'Erin',
  lastName: 'Peterson',
  tableOfContents: 'Table of Contents',
  aboutTheAuthor: 'About the Author',
  authorImg: '<PLACEHOLDER>',
  aboutTheAuthorInfo: 'AI Ethicist and Software Developer at Distributive ',
  contactLinks: 'Contact Links',

  contactLinksList: [
    // Email
    {
      icon: MAIL_ICON,
      displayName: 'erin@distributive.network',
      link: 'mailto:erin@distributive.network',
    },
    // LinkedIn
    {
      icon: LINKEDIN_ICON,
      displayName: 'LinkedIn',
      link: 'https://www.linkedin.com/in/erinlynnpeterson/',
    },
    // Github
    {
      icon: GITHUB,
      displayName: 'Github',
      link: 'https://github.com/16elp',
    },
  ],

  articles: 'Articles',
  articleLinks: [
    {
      name: 'DIANA Youtube',
      link: 'https://youtu.be/khs_zyWbzWQ?feature=shared',
    },
  ],

  keywords: 'Keywords',
  keywordsList: ['AI', 'Ethics'],
};

function Erin_Peterson_Content() {
  return (
    <div>
      <ArticleHeading>Erin Peterson</ArticleHeading>

      <ArticleParagraph>Article Contents</ArticleParagraph>
    </div>
  );
}

export default Erin_Peterson_Content;
