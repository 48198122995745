import type { MediaMentions } from '../../types/media-mention.model';

export default function MediaStories() {
  const components: MediaMentions[] = [
    {
      headline:
        'Ontario Startup Aims to Turn Consumer Electronic Devices into a Supercomputer',
      source: 'The Globe And Mail',
      date: new Date(2019, 0, 30),
      url: 'https://www.theglobeandmail.com/canada/article-ontario-startup-aims-to-turn-consumer-electronic-devices-into-a/',
    },
    {
      headline: 'Distributive Tackles Medical Backlog',
      source: 'Invest Kingston',
      date: new Date(2022, 8, 15),
      url: 'https://www.investkingston.ca/distributive-tackles-medical-backlog/',
    },
    {
      headline:
        'NGen Pilot Project Cuts Cloud Computing Costs for Manufacturers',
      source: 'NGEN',
      date: new Date(2022, 9, 11),
      url: 'https://www.ngen.ca/insights/ngen-pilot-project-cuts-cloud-computing-costs-for-manufacturers',
    },
    {
      headline:
        'Distributive Named as Second Fastest Growing Company in Eastern Ontario',
      source: 'Eastern Ontario Business Journal',
      date: new Date(2022, 9, 18),
      url: 'https://issuu.com/greatrivermedia/docs/eastern_ontario_business_journal_fall_2022/32',
    },
    {
      headline: 'Canadian Project Arrow EV Hits Target at CES 2023',
      source: 'Driving',
      date: new Date(2023, 0, 5),
      url: 'https://driving.ca/auto-news/news/canadian-project-arrow-ev-hits-target-at-ces-2023',
    },
  ];

  return components;
}
