import { type FC, useState } from 'react';
import { Link } from 'react-router-dom';
import './Dropdown.scss';
import type {
  NavigationItemModel,
  NavigationSubModel,
} from '../../../types/navigationItemModel';

export const Dropdown: FC<NavigationItemModel> = ({ subItems, linkIndex }) => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  return (
    <ul
      onClick={handleClick}
      className={`${
        click ? 'dropdown-menu clicked' : 'dropdown-menu'
      } top-[75px]`}
    >
      {subItems?.map((item: NavigationSubModel, index: number) => (
        <li key={index}>
          {
            <Link
              className="dropdown-link"
              to={item.url as string}
              onClick={() => setClick(false)}
            >
              <span>{item.title}</span>
            </Link>
          }
        </li>
      ))}
    </ul>
  );
};
