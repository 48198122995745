import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import {
  BLOGS,
  BLOG_MAP,
  PROFILES,
  PROFILE_MAP,
} from '../../PageContent/Article/Article';
import CTAPanel from '../CTA/CTAPanel';
import HeroPanel from '../HeroPanel/HeroPanel';

import styles from './Article.module.scss';

export function Article({ articleProps }: any) {
  let articleIndex;
  let data: any;

  const navigate = useNavigate();

  const [tableOfContents, setTableOfContents]: any = useState([]); //Stores the list of section headers
  const { articleName, profileName } = useParams(); //Gets the the article name from url

  if (articleName) articleIndex = BLOG_MAP.get(articleName); //Gets the corresponding mapped array index for article names
  if (profileName) articleIndex = PROFILE_MAP.get(profileName); //Get the corresponding mapped array index for profiles
  console.log(articleIndex);

  // Redirects if invalid url param
  if (articleProps == null && articleIndex == null) {
    navigate('/404');
  }

  // Populates data with appropriate content depending on if props data is passed or the url param is used
  // Get BLOG article
  if (articleIndex != null && articleName != null) {
    data = BLOGS[articleIndex];
  }
  // Get PROFILE article
  if (articleIndex != null && profileName != null) {
    data = PROFILES[articleIndex];
  }

  // Allows passed props into the article component
  if (articleProps != null) {
    data = articleProps;
  }

  // Table of contents scroll function
  const scrollToSection = (id: string) => {
    const sectionTitle = document.getElementById(id);
    const OFFSET = 100;
    const sectionPosition = sectionTitle?.offsetTop - OFFSET;
    window?.scrollTo({ top: sectionPosition, behavior: 'smooth' });
  };

  /**
   * Dynamically retrieves the subheadings of the article and applies id's to the sections for auto-scrolling
   */
  const setTitleIds = () => {
    const titles = Object.values(document.getElementsByClassName('h2_id'));

    // Loop through the elements and set unique IDs
    for (let i = 0; i < titles.length; i++) {
      titles[i].id = `item-${i + 1}`; // IDs will be "item-1", "item-2", ...
    }
    setTableOfContents(titles);
  };

  // Scrolls to top of the page
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto', // Optional if you want to skip the scrolling animation
    });
    setTitleIds();
  }, []);

  if (!data) {
    return <div />;
  }

  return (
    <div className="article-container">
      <HeroPanel
        title={data?.hero.title}
        description={data?.hero.image ? '' : data?.hero.description}
        bgImage={data?.hero.bgImage}
        parentContainerClassName="relative"
        minHeight="min-h-[50vh]"
      >
        {data.hero.image && (
          <div className="flex flex-col xl:flex-row gap-[50px] xl:gap-[100px]">
            <h2
              className={
                'h2_hero_style w-[100%] xl:w-[52%] 2xl:2-[52%] 3xl:w-[52%]'
              }
            >
              {data?.hero.description}
            </h2>
            {/* Article Image */}
            <div className="m-[auto] xl:m-0 xl:h-[10px] xl:w-[10px] ">
              <div
                className={
                  'xl:absolute bg-[#D9D9D933] bottom-[-104px] h-[auto] w-[100%] sm:w-[480px] 2xl:w-[550px] p-[10px] 3xl:p-[20px] '
                }
              >
                <img
                  className={`h-[100%] w-[100%] ${data?.hero?.imageStyle}`}
                  src={data?.hero?.image}
                  alt="Hero"
                />
              </div>
            </div>
          </div>
        )}
      </HeroPanel>
      <div className="article-content flex justify-center p-[15px] sm:p-[40px] 3xl:p-[90px] gap-[50px] 3xl:gap-[72px]">
        {/* -------------------------------------------- Side Bar -------------------------------------------- */}
        <div
          className={`${styles.sideBar} hidden xl:flex flex-col min-w-[450px] px-[10px] relative `}
        >
          <div
            className={`sticky top-[100px] max-h-[600px] 3xl:max-h-[800px] overflow-auto ${styles.sidebarContent}`}
          >
            {/* Date & Title */}
            <div className="mb-[30px]">
              <p className="p_small_style">
                {data?.sidebarContent.date} | {data?.sidebarContent.firstName}{' '}
                {data?.sidebarContent.lastName}
              </p>
            </div>
            {/* Table of Contents */}
            <div className="mb-[30px]">
              <p className="p_standard_style font-bold mb-[10px]">
                {data?.sidebarContent.tableOfContents}
              </p>
              <div className="flex flex-col items-start gap-[15px]">
                {tableOfContents.map((item: any, key: any) => (
                  <button
                    key={key}
                    className="text-[#14ae5c] cursor-pointer leading-[18px] text-left"
                    onClick={() => scrollToSection(String(item.id))}
                  >
                    {item.innerText}
                  </button>
                ))}
              </div>
            </div>
            {/* About the Author */}
            <div className="mb-[30px]">
              <p className="p_standard_style font-bold mb-[10px]">
                {data?.sidebarContent.aboutTheAuthor}
              </p>
              <div className="flex gap-[30px] justify-start items-center">
                <img
                  className="w-[65px] h-[65px] rounded-[50%]"
                  src={data?.sidebarContent.authorImg}
                  alt="Author"
                />
                <div className="flex flex-col">
                  <p className="p_standard_style">
                    {data?.sidebarContent.firstName}{' '}
                    {data?.sidebarContent.lastName}
                  </p>
                  <p>{data?.sidebarContent.aboutTheAuthorInfo}</p>
                </div>
              </div>
            </div>
            {/* Contact Links */}
            <div className="mb-[30px]">
              <p className="p_standard_style font-bold">
                {data?.sidebarContent.contactLinks}
              </p>
              {data?.sidebarContent.contactLinksList.map(
                (contact: any) =>
                  contact.displayName &&
                  contact.link && (
                    <div className="p_standard_style flex gap-[7px] items-center justify-start">
                      <img
                        className="w-[17px]"
                        src={contact.icon}
                        alt="Profile Icon"
                      />
                      <Link
                        to={contact.link}
                        target="_blank"
                        rel="noreferrer"
                        className="underline text-[#14ae5c]"
                      >
                        {contact.displayName}
                      </Link>
                    </div>
                  ),
              )}
            </div>
            {/* Articles */}
            {data.sidebarContent.articleLinks.length > 0 && (
              <div className="mb-[30px]">
                <p className="p_standard_style font-bold">
                  {data?.sidebarContent.articles}
                </p>
                <div>
                  {data?.sidebarContent.articleLinks.map(
                    (article: any, i: any) => (
                      <div key={i}>
                        <Link
                          to={article.link}
                          target="_blank"
                          rel="noreferrer"
                          className="text-[#14ae5c] underline"
                        >
                          {article.name}
                        </Link>
                      </div>
                    ),
                  )}
                </div>
              </div>
            )}
            {/* Keywords */}
            {data.sidebarContent.keywordsList.length > 0 && (
              <div>
                <p className="p_standard_style font-bold">
                  {data?.sidebarContent.keywords}
                </p>
                <div className="flex">
                  {data?.sidebarContent.keywordsList.map(
                    (keyword: any, i: any) => (
                      <p key={i}>
                        {keyword}
                        {i !== data.sidebarContent.keywordsList.length - 1
                          ? ','
                          : ''}{' '}
                        <span> &nbsp;</span>
                      </p>
                    ),
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {/* -------------------------------------------- Main Content -------------------------------------------- */}
        <div
          className={`main-content w-[100%] max-w-[1500px] ${
            data.hero.image ? 'xl:mt-[110px]' : ''
          }`}
        >
          {/* Video */}
          {data?.articleContent.video && (
            <div className="p-0 sm:p-[20px] bg-[#d9d9d94d] mt-[0] sm:mt-[-100px] 3xl:mt-[-150px] mb-[30px] relative">
              <iframe
                className="w-[100%] min-h-[400px] lg:min-h-[600px]"
                width="560"
                height="315"
                src={data?.articleContent.video}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              />
            </div>
          )}
          {/* Main Content */}
          <div
            className={`leading-[1.80] article-content ${styles.articleContent}`}
          >
            {data.articleContent.content && <data.articleContent.content />}
          </div>
        </div>
      </div>
      {/* -------------------------------------------- CTA -------------------------------------------- */}
      <CTAPanel
        title={data?.cta.title}
        subHeading={data?.cta.subHeading}
        ctaText={data?.cta.ctaText}
        ctaBgColour={data?.cta.ctaBgColour}
        ctaTextColour={data?.cta.ctaTextColour}
        bgImage={data?.cta.bgImage}
        href={data?.cta.href}
      />
    </div>
  );
}
