import { useEffect } from 'react';

import { MEDIA } from '../../PageContent/MediaList.js';
import MediaStories from '../../PageContent/MentionContent/MediaStories';
import NewsStories from '../../PageContent/MentionContent/NewsStories';
import Button from '../../components/Button/Button';
import CTAPanel from '../../components/CTA/CTAPanel';
import HeroPanel from '../../components/HeroPanel/HeroPanel';
import Margins from '../../components/Margins/Margins';
import MediaMention from '../../components/Mentions/Media/MediaMention';
import NewsMention from '../../components/Mentions/News/NewsMention';
import type { MediaMentions } from '../../types/media-mention.model';
import type { NewsStory } from '../../types/news-story.model';

export function Media() {
  const newsComponents = NewsStories();
  const mediaComponents = MediaStories();
  const components = MEDIA;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HeroPanel
        bgImage="bg-[url(./assets/jpg/green-leftside.jpg)]"
        titleOnly={true}
        title={components.pageTitle}
      />
      <Margins>
        <div className="news-and-mentions-list flex flex-col lg:flex-row justify-between py-[2.5rem] min-h-[600px]">
          <div className="news-list w-full lg:w-[70%] lg:pr-[3rem] flex flex-col justify-between">
            <div>
              <div className="h-[1px] w-full bg-[#D9D9D9] bg-opacity-40" />
              {mediaComponents.reverse().map((media: MediaMentions, index) => (
                <>
                  <NewsMention
                    url={media.url}
                    key={index}
                    headline={media.headline}
                    date={media.date}
                    compact={true}
                    headlineColor="text-black"
                    dateColor="text-black"
                    linkColor="text-[#14AE5C]"
                  />
                  <div className="h-[1px] w-full bg-[#D9D9D9] bg-opacity-40" />
                </>
              ))}
            </div>
          </div>
          <div
            className="media-list bg-[url(./assets/jpg/newspaper.jpg)]
                                    w-full lg:w-[30%] h-fit mt-[2rem] lg:mt-[0rem]
                                    p-[30px] flex flex-col"
          >
            <h1 className="text-2xl sm:text-3xl lg:text-4xl font-extrabold text-white">
              Latest News Releases
            </h1>
            <div className="flex flex-col gap-[1rem] py-[1rem]">
              {newsComponents
                .slice(-3)
                .reverse()
                .map((news: NewsStory, index) => (
                  <MediaMention
                    key={index}
                    headline={news.headline}
                    date={news.date}
                    url={`/news/${news.id}`}
                  />
                ))}
            </div>
            <Button
              url="/news"
              text="VIEW ALL NEWS RELEASES"
              textColour="text-white"
            />
          </div>
        </div>
      </Margins>
      <CTAPanel
        title={components.cta.title}
        subHeading={components.cta.subHeading}
        ctaText={components.cta.ctaText}
        ctaBgColour={components.cta.ctaBgColour}
        ctaTextColour={components.cta.ctaTextColour}
        bgImage={components.cta.bgImage}
        href={components.cta.href}
      />
    </>
  );
}
