export const FAQ = {
  metadata: {
    description:
      'Distributive created the Distributive Compute Protocol (DCP). A standard for matching supply and demand for compute to enable anyone, academic or commercial, with a need for computing power to have access to that power within any web connected device on the platform.',
    keywords: '',
  },
  hero: {
    title: 'FAQs',
    subHeading:
      'This page looks to answer some of the most commonly asked questions about the Distributed Computing Protocol (DCP), and products powered by DCP.',
    bgImage:
      'bg-[url(./assets/jpg/hero-question.jpg)] bg-left bg-cover !bg-no-repeat',
  },
  faqBackground: 'bg-[url(./assets/jpg/stars.jpg)]',
  distributiveQuestions: {
    title: 'Questions about Distributive',
    cards: [
      {
        title: 'Sample common question placeholder text goes in this location.',
        description:
          'The Distributive Compute Protocol is a powerful distributed computing platform and related ecossytem that connects and matches supply and demand for compute to enable everything from cutting edge sceintific research to the next generation of decentralized applications.',
      },
      {
        title: 'Sample common question placeholder text goes in this location.',
        description:
          'The Distributive Compute Protocol is a powerful distributed computing platform and related ecossytem that connects and matches supply and demand for compute to enable everything from cutting edge sceintific research to the next generation of decentralized applications.',
      },
      {
        title: 'Sample common question placeholder text goes in this location.',
        description:
          'The Distributive Compute Protocol is a powerful distributed computing platform and related ecossytem that connects and matches supply and demand for compute to enable everything from cutting edge sceintific research to the next generation of decentralized applications.',
      },
    ],
  },
  computeQuestions: {
    title: 'Questions about the Distributive Compute Protocol Platform',
    cards: [
      {
        title: 'Sample common question placeholder text goes in this location.',
        description:
          'The Distributive Compute Protocol is a powerful distributed computing platform and related ecossytem that connects and matches supply and demand for compute to enable everything from cutting edge sceintific research to the next generation of decentralized applications.',
      },
      {
        title: 'Sample common question placeholder text goes in this location.',
        description:
          'The Distributive Compute Protocol is a powerful distributed computing platform and related ecossytem that connects and matches supply and demand for compute to enable everything from cutting edge sceintific research to the next generation of decentralized applications.',
      },
      {
        title: 'Sample common question placeholder text goes in this location.',
        description:
          'The Distributive Compute Protocol is a powerful distributed computing platform and related ecossytem that connects and matches supply and demand for compute to enable everything from cutting edge sceintific research to the next generation of decentralized applications.',
      },
    ],
  },
  productQuestions: {
    title: 'Questions about products that use DCP',
    cards: [
      {
        title: 'Sample common question placeholder text goes in this location.',
        description:
          'The Distributive Compute Protocol is a powerful distributed computing platform and related ecossytem that connects and matches supply and demand for compute to enable everything from cutting edge sceintific research to the next generation of decentralized applications.',
      },
      {
        title: 'Sample common question placeholder text goes in this location.',
        description:
          'The Distributive Compute Protocol is a powerful distributed computing platform and related ecossytem that connects and matches supply and demand for compute to enable everything from cutting edge sceintific research to the next generation of decentralized applications.',
      },
    ],
  },
};
