import type { NewsMentions } from '../../../types/news-mention.model';
import './NewsMention.scss';

export default function NewsMention(content: NewsMentions) {
  let month;
  let day;
  let year;
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  if (content.date !== undefined) {
    year = content.date.getFullYear();
    month = monthNames[content.date.getMonth()];
    day = content.date.getDate();
  }

  return (
    <>
      {content.compact ? (
        <>
          <div className="news-mention-container-compact pt-[10px] pb-[10px] flex flex-col gap-[5px]">
            <div
              className={`p_small_style text-opacity-80 font-light ${
                content.dateColor ? `${content.dateColor}` : 'text-white'
              }`}
            >
              {month}. {day}, {year}
            </div>
            <div
              className={`h3_style ${
                content.headlineColor
                  ? `${content.headlineColor}`
                  : 'text-white'
              }`}
            >
              {content.headline}
            </div>
            <a
              href={content.url}
              target="_blank"
              rel="noreferrer"
              className={`p_small_style font-medium ${
                content.linkColor ? `${content.linkColor}` : 'text-white'
              }`}
            >
              READ MORE {'>'}
            </a>
          </div>
        </>
      ) : (
        <>
          <div className="news-mention-container">
            <div
              className={`p_small_style text-opacity-80 font-light mb-[4px] ${
                content.dateColor ? `text-${content.dateColor}` : 'text-white'
              }`}
            >
              {month}. {day}, {year}
            </div>
            <div
              className={`p_standard_style font-medium ${
                content.headlineColor
                  ? `text-${content.headlineColor}`
                  : 'text-white'
              }`}
            >
              {content.headline}
            </div>
            <a
              href={content.url}
              className={`p_small_style font-medium mt-[7px] ${
                content.linkColor ? `text-${content.linkColor}` : 'text-white'
              }`}
            >
              READ MORE {'>'}
            </a>
          </div>
        </>
      )}
    </>
  );
}
