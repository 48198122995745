import './FrameworkMainCard.scss';
import parse from 'html-react-parser';
import type { FrameworkCardModel } from '../../types/frameworkcard.model';
// import Job from './Job';

function FrameworkMainCard(content: FrameworkCardModel) {
  return (
    <div
      className={` px-[1rem] xl:px-[2rem] ${
        content.mobile ? 'mobile' : 'mainCard'
      }`}
      style={{ height: content.height }}
    >
      <h1 className="h1_style textStyle  pt-6 pb-4">{content.title}</h1>
      <p className="h6_style textStyle   pb-6">
        {parse(content.description as string)}
      </p>
      {content.job && (
        <div className="job px-[.5rem] xl:px-[2rem]">
          <p className="h6_style textStyle pt-2">
            <span
              style={{ fontFamily: 'monospace' }}
              className="text-yellow-300"
            >
              job{' '}
            </span>
            <span className="text-red-500">= </span>
            <span
              style={{ fontFamily: 'monospace' }}
              className="text-green-400"
            >
              compute.for
            </span>
            (
            <span style={{ fontFamily: 'monospace' }} className="text-blue-600">
              inputSet
            </span>
            ,
            <span style={{ fontFamily: 'monospace' }} className="text-blue-600">
              {' '}
              workfunction
            </span>
            );
          </p>
          <p className="h6_style textStyle pb-2">
            <span
              style={{ fontFamily: 'monospace' }}
              className="text-yellow-300"
            >
              results{' '}
            </span>
            <span className="text-red-500">= </span>
            <span
              style={{ fontFamily: 'monospace' }}
              className="text-violet-500"
            >
              await{' '}
            </span>
            <span style={{ fontFamily: 'monospace' }} className="text-blue-600">
              job
            </span>
            .
            <span
              style={{ fontFamily: 'monospace' }}
              className="text-green-400"
            >
              exec
            </span>
            ();
          </p>
        </div>
      )}

      {content.img && (
        <div className="flex items-center pt-14">
          <img className="mx-auto" src={content.img} alt="" />
        </div>
      )}
    </div>
  );
}

export default FrameworkMainCard;
