import GPU_IMAGE from '../assets/png/article-gpu.png';
import PYTHON_MONKEY from '../assets/png/article-pythonmonkey.png';
import dan_headshot from '../assets/png/headshot-dan.png';
import doug_headshot from '../assets/png/headshot-doug.png';
import erin_headshot from '../assets/png/headshot-erin.png';
import karen_headshot from '../assets/png/headshot-karen.png';
import neil_headshot from '../assets/png/headshot-neil.png';
import wes_headshot from '../assets/png/headshot-wes.png';
export const BLOG = {
  hero: {
    title: 'People of Distributive',
    description:
      'Never doubt that a small group of thoughtful, committed citizens can change the world. Indeed, it is the only thing that ever has.',
    bgImage:
      'bg-[url(./assets/jpg/blog_background.jpg)] !bg-cover !bg-center xl:!bg-right bg-no-repeat',
  },
  article: {
    cards: [
      {
        date: 'July 5, 2023 | Amir Sojoodi',
        image: GPU_IMAGE,
        title: 'WebGPU for Dummies',
        description:
          'The aim of this document is to provide readers with a clear and accessible tips and tricks to WebGPU compute, enabling them to harness its power for their own projects.',
        readMore: {
          text: 'READ MORE...',
          href: '/jobs/webgpu',
        },
      },
      {
        date: 'Jul 28, 2023 | Will Pringle',
        image: PYTHON_MONKEY,
        title: 'Python Monkey',
        description:
          'Run JavaScript/WASM in Python: high-level SpiderMonkey bindings to Python with PythonMonkey',
        readMore: {
          text: 'READ MORE...',
          href: '/jobs/python-monkey',
        },
      },
      // {
      //   date: "July 5, 2023 | Amir Sojoodi",
      //   image: GPU_IMAGE,
      //   title: "WebGPU for Dummies",
      //   description:
      //     "The aim of this document is to provide readers with a clear and accessible tips and tricks to WebGPU compute, enabling them to harness its power for their own projects.",
      //   readMore: {
      //     text: "READ MORE...",
      //     href: "/#",
      //   },
      // },
    ],
  },
  people: {
    title: 'Meet the People of Distributive',
    subTitle:
      'These are some of the people building DCP, The Distributed Compute Protocol and the software ecosystem surrounding it.',
    bgImage: 'bg-[url(./assets/jpg/green-leftside.jpg)]',
    numOfCols: 'lg:grid-cols-3',
    leaders: [
      {
        src: dan_headshot,
        name: 'Dan Desjardins',
        title: 'CEO',
        description:
          'Computational Physicist and former RCAF Officer, 10 years of experience in high performance computing between RMCC, Queen’s U, and Distributive.',
        url: 'https://www.linkedin.com/in/dan-desjardins/',
        displayUrl: 'linkedin.com/in/dan-desjardins',
      },
      {
        src: wes_headshot,
        name: 'Wes Garland',
        title: 'CTO',
        description:
          '30 years of experience in commercial software development and telecommunications systems. Expert in server-side JavaScript platform development.',
        url: 'https://www.linkedin.com/in/wesley-garland-2203a23',
        displayUrl: 'linkedin.com/in/wesley-garland',
      },
      {
        src: karen_headshot,
        name: 'Karen Batch',
        title: 'Dir. AI Task',
        description:
          'M.Sc. of Computing in AI, an expert in ML for use in Health Care. Published research using Natural Language Processing to detect cancer from radiology reports to identify trends of metastatic spread.',
        url: 'https://www.linkedin.com/in/karen-batch-013348111',
        displayUrl: 'linkedin.com/in/karen-batch',
      },
      {
        src: doug_headshot,
        name: 'Doug Stewart',
        title: 'Co-founder / Director of Special Projects',
        description:
          'Doug is a proven leader in business, jazz bands, and sailboats. As a long-time entrepreneur, he makes the world a happier place by forging lasting friendships and bringing smart people together.',
        url: 'https://www.linkedin.com/in/douglas-stewart-686b47135/',
        displayUrl: 'linkedin.com/in/douglas-stewart',
      },
      {
        src: neil_headshot,
        name: 'Neil Laing',
        title: 'VP Design',
        description:
          'Award winning designer leading marketing, branding, UX/UI and product design. Founding employee of AI start-up Rubikloud (acquired 2020), contributor to products such as Tableau and Uptime.',
        url: 'https://www.linkedin.com/in/neil-laing/',
        displayUrl: 'linkedin.com/in/neil-laing',
      },
      {
        src: erin_headshot,
        name: 'Erin Peterson',
        title: 'Artificial Intelligence Software Developer',
        description:
          'Erin is a Computer Engineer specializing in Artificial Intelligence who focuses on innovations that prioritize ethical considerations and contribute to societal well-being.',
        url: 'https://www.linkedin.com/in/erinlynnpeterson',
        displayUrl: 'linkedin.com/in/erinlynnpeterson',
      },
    ],
  },
  cta: {
    title: 'Interested in working at Distributive?',
    subHeading:
      'Reach out to learn more, or visit our careers page to explore joining our growing team.',
    ctaText: 'Careers at Distributive',
    ctaBgColour: 'bg-[#17484D]',
    ctaTextColour: 'text-[white]',
    bgImage: 'bg-[url(./assets/jpg/lecture-room.jpg)]',
    href: '/careers',
  },
};
