import CLIP_BOARD from '../assets/png/clipboard.png';
import DOCKER from '../assets/png/docker_worker.png';
import HELM from '../assets/png/helm_worker.png';
import NUM_1 from '../assets/png/icon-green-1.png';
import NUM_2 from '../assets/png/icon-green-2.png';
import NUM_3 from '../assets/png/icon-green-3.png';
import KUBERNETES from '../assets/png/kubernetes.png';
export const DOCKER_WORKER = {
  metadata: {
    description:
      'Distributive created the Distributive Compute Protocol (DCP). A standard for matching supply and demand for compute to enable anyone, academic or commercial, with a need for computing power to have access to that power within any web connected device on the platform.',
    keywords: '',
  },
  hero: {
    title: 'Docker Worker',
    description:
      'Launching a DCP Worker using a container platform, or an entire Worker cluster using Kubernetes, is a breeze.',
    bgImage:
      'bg-[url(./assets/jpg/screen_saver_worker.jpg)] !bg-cover bg-center !xl:bg-right bg-no-repeat',
  },
  howToGetStarted: {
    cards: [
      {
        title: 'DOCKER WORKER',
        img: DOCKER,
        link1Href: '#',
        link1Text: 'latest stable image',
        buttonText: 'COPY TO CLIPBOARD',
        buttonIcon: CLIP_BOARD,
        buttonSubText: '',
        link2Href: 'https://hub.docker.com/r/distributivenetwork/dcp-worker',
        link2Text: 'Docker Hub Page',
        comingSoon: false,
        clipBoardMessage: 'docker pull distributivenetwork/dcp-worker',
        isButtonNotLink: true,
        copyToClipboard: true,
      },
      {
        title: 'KUBERNETES MANIFEST',
        img: KUBERNETES,
        link1Href: '#',
        link1Text: 'manifest.yaml',
        buttonText: 'GENERATE NOW',
        buttonSubText: '',
        link2Href: 'https://hub.docker.com/r/distributivenetwork/dcp-worker',
        link2Text: 'Docker Hub Page',
        comingSoon: false,
        isButtonNotLink: true,
        generateYaml: true,
      },
      {
        title: 'HELM CHART',
        img: HELM,
        link1Href: '#',
        // link1Text: "Helm install",
        // buttonText: "ARTIFACT HUB",
        buttonSubText: '',
        link2Href: '#',
        // link2Text: "chart version #",
        comingSoon: true,
      },
    ],
  },
  tabs: [
    {
      name: 'DOCKER WORKER',
      activate: true,
    },
    {
      name: 'KUBERNETES',
      activate: true,
    },
  ],
  installationProcedures: [
    // DOCKER INSTALLATION
    {
      pageHeader: 'How to get Started',
      description:
        'If you already have Docker running, you can pull the DCP Docker Worker image to launch and configure a DCP Worker. Watch this 3 minute video to learn how.',
      video: '',
      title: 'How to run the DCP Docker Worker',
      step1: {
        number: NUM_1,
        text1: 'In a terminal, run',
        text2: 'docker version',
        text3: `and check that you are running version <span className="text-[#14AE5C] american-typewriter">24.0.2</span> or later.`,
      },
      step2: {
        number: NUM_2,
        text1: 'Run',
        text2: 'docker pull distributivenetwork/dcp-worker:latest',
        text3: 'to pull the DCP Docker Worker image.',
      },
      step3: {
        number: NUM_3,
        text1: 'Run',
        text2:
          'docker run distributivenetwork/dcp-worker:latest --paymentAddress=<address>',
        text3: 'to run the Worker in the Global Network, or',
        text4: `docker run distributivenetwork/dcp-worker:latest --paymentAddress=<address> --join "<joinKey>,<joinSecret>" --leavePublicGroup`,
        text5: 'to run the Worker in a private Compute Group. ',
        // codeOption2: `docker run distributivenetwork/dcp-worker:latest --paymentAddress=<address> --join "<joinKey>,<joinSecret>" --leavePublicGroup`,
      },
    },
    // Kubernetes Installation
    {
      pageHeader: 'How to get Started',
      description:
        'docker run distributivenetwork/dcp-worker:latest --paymentAddress=<address>',
      video: '',
      title: 'Windows DCP Screensaver Worker install procedure',
      step1: {
        number: NUM_1,
        text1: `Make sure your Kubernetes cluster is running. If not, we recommend <a className="text-[#14AE5C] underline american-typewriter" href="https://docs.k3s.io/quick-start" target="_blank">k3s</a> or <a className="text-[#14AE5C] underline american-typewriter" href="https://docs.k0sproject.io/v1.23.6+k0s.2/k0sctl-install/">k0s</a> to get started`,
      },
      step2: {
        number: NUM_2,
        text1: `Generate a <span className="text-[#14AE5C] american-typewriter">manifest.yaml</span> file, and set the payment address<br/>- where compute credits are deposited <br/>- and other configuration options as desired`,
      },
      step3: {
        number: NUM_3,
        text1: 'Run',
        text2: 'kubectl apply -f ./manifest.yaml',
      },
    },
    // {

    //   pageHeader: "How to get Started",
    //   description:
    //     "Watch this 3 minute video to learn how to install the Windows Screensaver Worker and set it up to join the Global DCP Network and/or one or more Private Compute Groups.",
    //   video: "https://www.youtube.com/embed/QHvpAtvurQE",
    //   title: "Windows DCP Screensaver Worker install procedure",
    //   steps: [
    //     {
    //       number: NUM_1,
    //       instruction: `
    //       <div
    //       className="!text-[black] text-center lg:text-left mb-[27px]"
    //       key={key}
    //     >
    //       <p>
    //         Download and install the Windows DCP Screensaver Worker
    //         .msi
    //       </p>
    //       <p>
    //         Download and install the Windows DCP Screensaver Worker
    //         .msi
    //       </p>
    //     </div>
    //       `,
    //       img: "",
    //     },
    //     {
    //       number: NUM_2,
    //       instruction: `
    //       <div
    //       className="!text-[black] text-center lg:text-left mb-[27px]"
    //       key={key}
    //     >
    //       <p>
    //         Set the DCP Bank Account where Compute Credits will be
    //         deposited as the worker executes work.,
    //       </p>
    //       <p>
    //         <span className="break-all">
    //           Ex: 0x079DAC0612C710ab4e975dAb7171C7e4beF78c5a
    //         </span>
    //         ,
    //       </p>
    //       <p>
    //         Create, view, and manage your Accounts in the DCP Portal.,
    //       </p>
    //     </div>`,
    //       img: "",
    //     },
    //     {
    //       number: NUM_3,
    //       instruction: `
    //       <div
    //       className="!text-[black] text-center lg:text-left mb-[27px]"
    //       key={key}
    //       >
    //       <p>
    //         Set the Compute Group(s) from which the worker will fetch
    //         work. Opt-in/out of the Global Network and join Private
    //         groups as required.,
    //       </p>
    //       <div className="flex w-[100%] gap-[5px]">
    //         <p>Ex:</p>
    //         <div className="flex flex-col">
    //           <p>Join Key: demo</p> <p>Join Secret: dcp</p>
    //           <p>Label: demo time!</p>
    //         </div>
    //       </div>
    //       ,
    //       <p>
    //         Create, view, and manage your Compute Groups in the DCP
    //         Portal.,
    //       </p>
    //     </div>`,
    //       img: "",
    //     },
    //     {
    //       number: NUM_4,
    //       instruction: `
    //       <div
    //       className="!text-[black] text-center lg:text-left mb-[27px]"
    //       key={key}
    //       >
    //       <p>
    //       In ‘Change power settings’, set ‘Turn off the display’ to
    //       ‘Never’ when Plugged in, and set ‘Put the computer to
    //       sleep’ to ‘Never’ when Plugged in.,
    //       </p>
    //       </div>
    //     `,
    //       img: "",
    //     },
    //   ],
    // },
  ],
  moreDetails: {
    configurationOptions: `For mode details and configuration options, please visit the Docker
    Hub page `,
    configurationOptionsLinkText: 'here.',
    configurationOptionsLink:
      'https://hub.docker.com/r/distributivenetwork/dcp-worker',
  },
  cta: {
    title: 'Create your own Compute Group',
    subHeading:
      'By default, DCP users each have a limited, personal Compute Group allowing up to 3 DCP Workers. Compute Groups with custom join keys (e.g. ‘pixar’ ) with unlimited DCP Worker capacity can be purchased for 500,000 Compute Credits.',
    ctaText: 'Contact Us!',
    ctaBgColour: 'bg-[#17484D]',
    ctaTextColour: 'text-[white]',
    bgImage: 'bg-[url(./assets/jpg/lecture-room.jpg)]',
    href: '/contact',
  },
};
