import ARCHITECTURE from '../assets/jpg/workers-diagram.jpg';
import BROWSER from '../assets/png/browser_worker.png';
import DOCKER from '../assets/png/docker.png';
import SCREEN_SAVER from '../assets/png/screen_saver.png';
import STANDALONE from '../assets/png/standalone_worker.png';
export const DCP_WORKER = {
  metadata: {
    description:
      ' Distributive created the Distributive Compute Protocol (DCP). A standard for matching supply and demand for compute to enable anyone, academic or commercial, with a need for computing power to have access to that power within any web connected device on the platform.',
    keywords: '',
  },
  hero: {
    title: 'DCP Workers',
    description1:
      'Setup a distributed computing cluster in minutes. DCP Workers run on everything from fridges to enterprise servers.',
    description2:
      'Select the right worker for your deployment style to get started.',
    bgImage:
      'bg-[url(./assets/jpg/screen_saver_worker.jpg)] !bg-cover bg-center xl:!bg-right bg-no-repeat',
  },
  workers: {
    cards: [
      {
        title: 'SCREENSAVER<br/> WORKER',
        img: SCREEN_SAVER,
        link1Href: '/screensaver',
        buttonText: 'GET STARTED',
        descriptionTitle: 'Desktops and laptops',
        description:
          'Passively harness computing power from existing computers on your network',
      },
      {
        title: 'DOCKER<br/> WORKER',
        img: DOCKER,
        link1Href: '/docker',
        buttonText: 'GET STARTED',
        descriptionTitle: 'Kubernetes clusters',
        description:
          'Use Kubernetes for easy cloud-style DCP Worker deployments',
        comingSoon: '',
      },
      {
        title: 'STANDALONE<br/> WORKER',
        img: STANDALONE,
        link1Href: '#',
        buttonText: '',
        descriptionTitle: 'Advanced users',
        description:
          'Run DCP Workers via the command line for greater flexibility and control',
        comingSoon: 'Coming Soon...',
      },
      {
        title: 'BROWSER<br/> WORKER',
        img: BROWSER,
        link1Href: '#',
        buttonText: '',
        descriptionTitle: 'In-Browser',
        description:
          'Any device visiting a website that embeds the DCP Worker can provide compute',
        comingSoon: 'Coming Soon...',
      },
    ],
  },
  computeGroups: {
    title: 'DCP Compute Groups',
    description:
      'DCP Workers of any kind running on any device can be mixed and matched. You can add Workers to the Global DCP Network and/or to any number of private Compute Groups as long as you have the authentication credentials for those groups. DCP allows you to create flexible and composable computing clusters for effortless and cost-effective distributed computing.',
    img: ARCHITECTURE,

    blockTexts: [
      {
        title: 'Global DCP Network',
        content:
          'By default, DCP Workers will join the Global Network, execute non-sensitive public workloads, and earn compute credits. The Global Network is designed for high throughput computing on a global scale.',
      },
      {
        title: 'Private Compute Groups',
        content:
          'DCP Workers can be configured to join one or more Private Compute Groups by specifying their join keys and join secrets. Eg. A hospital can create its own private cloud using its own computers running DCP Workers in a private Compute Group.',
      },
      {
        title: 'Federated Computing',
        content:
          'Different departments and organizations can each run Compute Groups that work together without sharing any raw data, only the results of the computations. Eg. Banks can run data analytics across multiple jurisdictions without a single byte of raw data leaving any of their buildings.',
      },
    ],
  },
  dcpPortal: {
    title: 'The DCP Portal',
    subheading:
      'The DCP Portal allows users to manage their Compute Credits, Compute Groups, and Jobs.',
    paragraph1:
      'As DCP Workers execute computational workloads, they deposit compute credits into user-specified Accounts that are managed via the Portal.',
    paragraph2:
      'Compute Credits can be purchased, spent by deploying jobs, [soon] redeemed for cash, transferred between accounts, or donated to scientific projects via the Portal.',
    bgImage:
      'bg-[url(./assets/png/dcp_portal_bgimage.png)] !bg-cover bg-bottom bg-no-repeat',
    textBgImage:
      'bg-[url(./assets/jpg/green-rightside.jpg)] !bg-cover bg-bottom bg-no-repeat',
    button: {
      text: 'VISIT THE PORTAL',
      url: 'https://secure.distributed.computer/users/sign_in',
    },
  },
  cta: {
    title: 'Create your own Compute Group',
    subHeading:
      'By default, DCP users each have a limited, personal Compute Group allowing up to 3 DCP Workers. Compute Groups with custom join keys (e.g. ‘pixar’ ) with unlimited DCP Worker capacity can be purchased for 500,000 Compute Credits.',
    ctaText: 'Contact Us!',
    ctaBgColour: 'bg-[#17484D]',
    ctaTextColour: 'text-[white]',
    bgImage: 'bg-[url(./assets/jpg/lecture-room.jpg)]',
    href: '/contact',
  },
};
