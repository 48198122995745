import './FrameworkFooter.scss';
// import { CardModel } from '../../models/card.model';
import { FRAMEWORK } from '../../PageContent/Framework';
import FrameworkCard from '../FrameworkCards/FrameworkCard';

function FrameworkFooter() {
  // title={components.news.title} description={components.news.description} button={components.news.button}
  const components = FRAMEWORK.footer;
  //   const isSmallScreen = useMediaQuery('(max-width: 1000px)');
  //   const isMedScreen = useMediaQuery('(max-width: 1280px)');
  return (
    <div className={'px-[1rem] pb-2 lg:p-8'}>
      <div>
        <h2 className="framework-footer-title text-center">
          {components.title}
        </h2>
        <p className="p_large_style text-center pt-4 max-w-[1200px] m-auto">
          {components.subtitle}
        </p>
      </div>
      <div>
        <div className="xl:px-12 py-8 gap-[1.3rem] !auto-rows-fr grid xl:grid-cols-3 grid-cols-1 justify-items-center align-items-center align-self-center w-full">
          <FrameworkCard
            title={components.card1.title}
            description={components.card1.description}
            button={components.card1.button}
          />
          <FrameworkCard
            title={components.card2.title}
            description={components.card2.description}
            button={components.card2.button}
          />
          <FrameworkCard
            title={components.card3.title}
            description={components.card3.description}
            description2={components.card3.description2}
            description3={components.card3.description3}
            description4={components.card3.description4}
            description5=""
            button={components.card3.button}
          />
        </div>
      </div>
    </div>
  );
}

export default FrameworkFooter;
