import WEBGPU_IMAGE from '../../../assets/png/article-gpu.png';
import GPU_IMG_1 from '../../../assets/png/webgpu_graphic_1.png';
import GPU_IMG_2 from '../../../assets/png/webgpu_graphic_2.png';
import WEBSITE_ICON from '../../../assets/png/website_icon.png';
import GITHUB from '../../../assets/svg/github_icon.svg';
import LINKEDIN_ICON from '../../../assets/svg/linkedin_icon.svg';
import MAIL_ICON from '../../../assets/svg/mail_icon.svg';
import TWITTER from '../../../assets/svg/twitter_icon.svg';
import ArticleHeading from '../../../components/ArticleHeading/ArticleHeading';
import ArticleLink from '../../../components/ArticleLink/ArticleLink';
import ArticleParagraph from '../../../components/ArticleParagraph/ArticleParagraph';
import CodeBlock from '../../../components/CodeBlock/CodeBlock';
import GreyHighlight from '../../../components/GreyHighlight/GreyHighlight';

export const WEB_GPU_SIDE_HERO = {
  title: 'WebGPU For Dummies',
  description:
    'The aim of this document is to provide readers with a clear and accessible tips and tricks to WebGPU compute, enabling them to harness its power for their own projects.',
  bgImage:
    'bg-[url(./assets/png/article_backgrounds.png)] bg-cover bg-no-repeat !bg-center',
  image: WEBGPU_IMAGE,
};

export const WEB_GPU_SIDE_INFO = {
  date: 'July 5, 2023',
  firstName: 'Amir',
  lastName: 'Sojoodi',
  tableOfContents: 'Table of Contents',
  aboutTheAuthor: 'About the Author',
  authorImg: '/webgpu/amir.png',
  aboutTheAuthorInfo: 'PhD Student @ Queens University',
  contactLinks: 'Contact Links',
  contactLinksList: [
    // Email
    {
      icon: MAIL_ICON,
      displayName: 'amir@distributive.network',
      link: 'mailto:amir@distributive.network',
    },
    // LinkedIn
    {
      icon: LINKEDIN_ICON,
      displayName: 'LinkedIn',
      link: 'https://www.linkedin.com/in/amirsojoodi/',
    },
    // Github
    {
      icon: GITHUB,
      displayName: 'Github',
      link: 'https://github.com/',
    },
    // Twitter
    {
      icon: TWITTER,
      displayName: '',
      link: '',
    },
    // University Website
    {
      icon: WEBSITE_ICON,
      displayName: '',
      link: '',
    },
    // Personal Website
    {
      icon: WEBSITE_ICON,
      displayName: '',
      link: '',
    },
  ],
  articles: 'Articles',
  articleLinks: [
    // {
    //   name: "",
    //   link: "",
    // },
  ],
  keywords: 'Keywords',
  keywordsList: [
    // "Mathematics",
    // "Legendre Pairs",
    // "Combinatorics",
    // "Parallel search",
  ],
};

function WebGPUContent() {
  return (
    <div className="webgpu-content">
      {/* ---------------------------------- About ----------------------------------  */}
      <ArticleHeading>About</ArticleHeading>

      <ArticleParagraph>
        In this document, authored by Amir Sojoodi, I have jumped into the world
        of WebGPU compute and explore its potential for High-Performance
        Computing (HPC) and High-Throughput Computing (HTC) applications. It is
        based on my personal notes gathered during the development of a project
        at Distributive. The aim of this document is to provide readers with a
        clear and accessible tips and tricks to WebGPU compute, enabling them to
        harness its power for their own projects.
      </ArticleParagraph>
      <ArticleParagraph>
        Now, I must admit, coming from an HPC background with expertise in
        technologies like CUDA, OpenMP, and MPI, I had underestimated the
        initial challenges of transitioning to the world of WebGPU and
        JavaScript. It's like switching from driving a Ferrari to a self
        balancing hoverboard! However, I've put in every ounce of effort to
        squeeze out every drop of performance potential from the WebGPU API in
        my own work, and I hope you can do the same!
      </ArticleParagraph>
      <ArticleParagraph>
        <b>Disclaimer: </b>
        Please note that the content in this document primarily references the
        December 2022 draft of the WebGPU specification as published by the
        World Wide Web Consortium (W3C) at{' '}
        <a href="https://www.w3.org/TR/webgpu/">
          https://www.w3.org/TR/webgpu/.
        </a>
        While the specification may have evolved since then, the fundamental
        concepts and principles discussed here remain relevant and applicable to
        understanding WebGPU compute.
      </ArticleParagraph>
      <ArticleParagraph>
        Now, let's dive into the fascinating (and frustrating!) world of WebGPU
        compute!
      </ArticleParagraph>
      <ArticleHeading>Introduction to WebGPU Compute</ArticleHeading>

      <ArticleParagraph>
        <b>So, what exactly is WebGPU compute?</b> Well, it's a cutting-edge web
        technology that introduces a low-level, high-performance computing API
        for your favorite web browsers. Gone are the days when GPUs were only
        used for rendering jaw-dropping graphics. With WebGPU compute,
        developers like us can tap into the immense computational capabilities
        of GPUs for a wide range of tasks that go beyond just pixel-pushing.
      </ArticleParagraph>
      <ArticleParagraph>
        <b>But why is this such a big deal?</b> Well, traditionally, if we
        wanted to leverage the full power of GPUs, we had to rely on
        platform-specific technologies like CUDA or OpenCL. Don't get me wrong,
        those technologies are absolute beasts in terms of power, but they often
        tied us down to a specific operating system or programming language.
        WebGPU compute, on the other hand, breaks down those barriers and brings
        GPU-accelerated computing to the web using a standardized API.
      </ArticleParagraph>
      <ArticleParagraph>
        So, in summary, here are the benefits of utilizing WebGPU:
      </ArticleParagraph>
      <ol className="ml-[20px] list-decimal">
        <li> Parallel Processing Power</li>
        <li> Platform Independence</li>
        <li> Web Integration (Duh!)</li>
        <li> Ease of Use: That's an unfullfilled promise for now!</li>
        <li>
          Performance Portability: This means that applications can achieve
          similar performance characteristics across a wide range of devices,
          from laptops to desktops and even mobile devices, without sacrificing
          efficiency.
        </li>
      </ol>
      <br />
      <ArticleParagraph>
        Now, before you get carried away, let me warn you: WebGPU compute isn't
        all rainbows and unicorns. As with any new technology, there are
        challenges to overcome. From mastering the intricacies of JavaScript to
        optimizing your code for parallel execution, you'll face a few hurdles
        along the way.
      </ArticleParagraph>
      <ArticleHeading>What is out there?</ArticleHeading>

      <ArticleParagraph>
        There are many great tutorials and manuals out there:
      </ArticleParagraph>
      <ol className="ml-[30px] list-disc">
        <li>
          WebGPU{' '}
          <ArticleLink
            noUnderline={true}
            href="https://gpuweb.github.io/gpuweb/explainer"
            className="text-[#0c93e4]"
          >
            Explainer
          </ArticleLink>
        </li>
        <li>
          Introduction by{' '}
          <ArticleLink
            noUnderline={true}
            href="https://surma.dev/things/webgpu/"
            className="text-[#0c93e4]"
          >
            Surma
          </ArticleLink>
        </li>
        <li>
          Nikita's great{' '}
          <ArticleLink
            noUnderline={true}
            href="https://wiki.nikiv.dev/computer-graphics/webgpu"
            className="text-[#0c93e4]"
          >
            collection
          </ArticleLink>
        </li>
        <li>
          Chrome team{' '}
          <ArticleLink
            noUnderline={true}
            href="https://developer.chrome.com/articles/gpu-compute/"
            className="text-[#0c93e4]"
          >
            article
          </ArticleLink>
        </li>
        <li>(more in the References section)</li>
      </ol>
      <br />
      <ArticleParagraph>
        And the list goes on! Therefore, I won't bombard you with redundant
        details covered in the specification and other tutorials. I'll just
        provide you with a summary of the key notes that serve as handy
        reminders.
      </ArticleParagraph>
      <ArticleHeading>Core Concepts</ArticleHeading>

      <ArticleParagraph className="">
        Let's familiarize ourselves with some key concepts
      </ArticleParagraph>
      <ol className="ml-[30px]">
        <li>Adapter and device</li>
        <li>Initialization</li>
        <li>Timeline</li>
        <li>Buffer creation</li>
        <li>Buffer mapping</li>
        <li>Pipelines and binding groups</li>
        <li>Command buffers and command encoders</li>
        <li>GPU queue</li>
      </ol>
      <br />
      <ArticleParagraph>
        Let's dive into each of these key concepts.
      </ArticleParagraph>

      <ArticleHeading>Adapter and Device</ArticleHeading>
      <ArticleParagraph>
        The adapter is like the gateway to the GPU. It represents the physical
        GPU device available on the user's system. The device, on the other
        hand, is the driver that manages communication with the adapter. I stole
        this picture from{' '}
        <ArticleLink
          noUnderline={true}
          href="https://cohost.org/mcc/post/1406157-i-want-to-talk-about-webgpu"
        >
          Andi:
        </ArticleLink>
      </ArticleParagraph>
      <img src={GPU_IMG_1} alt="graphic" />
      <br />
      <ol>
        <li>
          <GreyHighlight>Adapter</GreyHighlight>
          <ul className="list-[circle] ml-[30px]">
            <li>
              An adapter identifies an implementation of WebGPU on the system:
            </li>
            <li>
              Both an instance of compute/rendering functionality on the
              platform underlying a browser,
            </li>
            <li>
              And an instance of a browser's implementation of WebGPU on top of
              that functionality.
            </li>
          </ul>
        </li>
        <br />
        <li>
          <GreyHighlight>GPUDevice</GreyHighlight>
          <ul className="list-[circle] ml-[30px]">
            <li>Adapter lists which ones are available.</li>
            <li>
              Must be specified when the requesting a device or they won't be
              active.
            </li>
          </ul>
        </li>
        <br />
        <li>
          <GreyHighlight>GPUAdapter.features</GreyHighlight>
          <ul className="list-[circle] ml-[30px]">
            <li>Adapter lists which ones are available.</li>
            <li>
              Must be specified when the requesting a device or they won't be
              active.
            </li>
          </ul>
        </li>
        <br />
        <li>
          <GreyHighlight>GPUAdapter.limits</GreyHighlight>
          <ul className="list-[circle] ml-[30px]">
            <li>A sample output can be seen here:</li>
          </ul>
        </li>
        <br />
        <CodeBlock langauge="javascript">
          {`maxBindGroups: 4
maxBindingsPerBindGroup: 640
maxBufferSize: 268435456
maxColorAttachments: 8
maxComputeInvocationsPerWorkgroup: 256
maxComputeWorkgroupSizeX: 256
maxComputeWorkgroupSizeY: 256
maxComputeWorkgroupSizeZ: 64
maxComputeWorkgroupStorageSize: 49152
maxComputeWorkgroupsPerDimension: 65535
maxDynamicStorageBuffersPerPipelineLayout: 4
maxDynamicUniformBuffersPerPipelineLayout: 8
maxInterStageShaderComponents: 60
maxInterStageShaderVariables: 16
maxSampledTexturesPerShaderStage: 16
maxSamplersPerShaderStage: 16
maxStorageBufferBindingSize: 4294967295
maxStorageBuffersPerShaderStage: 8
maxStorageTexturesPerShaderStage: 4
maxTextureArrayLayers: 256
maxTextureDimension1D: 8192
maxTextureDimension2D: 8192
maxTextureDimension3D: 2048
maxUniformBufferBindingSize: 65536
maxUniformBuffersPerShaderStage: 12
maxVertexAttributes: 16
maxVertexBufferArrayStride: 2048
maxVertexBuffers: 8
minStorageBufferOffsetAlignment: 256
minUniformBufferOffsetAlignment: 256`}
        </CodeBlock>
        <li>
          Adapter Info -<GreyHighlight>GPUAdapter.limits</GreyHighlight>
        </li>
      </ol>
      <br />
      <CodeBlock language="javascript">
        {`let gpuDevice = null;

async function initializeWebGPU() {
    // Check to ensure the user agent supports WebGPU.
    if (!('gpu' in navigator)) {
        console.error("User agent doesn't support WebGPU.");
        return false;
    }

    // Request an adapter.
    const gpuAdapter = await navigator.gpu.requestAdapter();

    // requestAdapter may resolve with null if no suitable adapters are found.
    if (!gpuAdapter) {
        console.error('No WebGPU adapters found.');
        return false;
    }

    // Request a device.
    // Note that the promise will reject if invalid options are passed to the optional
    // dictionary. To avoid the promise rejecting always check any features and limits
    // against the adapters features and limits prior to calling requestDevice().
    gpuDevice = await gpuAdapter.requestDevice();

    // requestDevice will never return null, but if a valid device request can't be
    // fulfilled for some reason it may resolve to a device which has already been lost.
    // Additionally, devices can be lost at any time after creation for a variety of reasons
    // (ie: browser resource management, driver updates), so it's a good idea to always
    // handle lost devices gracefully.
    gpuDevice.lost.then((info) => {
        console.error(\`WebGPU device was lost: \${info.message}\`);

        gpuDevice = null;

        // Many causes for lost devices are transient, so applications should try getting a
        // new device once a previous one has been lost unless the loss was caused by the
        // application intentionally destroying the device. Note that any WebGPU resources
        // created with the previous device (buffers, textures, etc) will need to be
        // re-created with the new one.
        if (info.reason !== 'destroyed') {
            initializeWebGPU();
        }
    });

    onWebGPUInitialized();

    return true;
}

function onWebGPUInitialized() {
    // Begin creating WebGPU resources here...
}

await initializeWebGPU();`}
      </CodeBlock>
      <ArticleHeading>Initialization</ArticleHeading>
      <ArticleParagraph>
        Initialization is the process of setting up the WebGPU context, creating
        an adapter, and establishing a connection with the device. This is done
        by calling the{' '}
        <GreyHighlight>navigator.gpu.requestAdapter()</GreyHighlight>
        and <GreyHighlight>adapter.requestDevice()</GreyHighlight> methods. The
        requestAdapter() method returns a promise that resolves to an adapter
        object. The <GreyHighlight>requestDevice()</GreyHighlight> method
        returns a promise that resolves to a device object. Then the device
        object is used to create resources like buffers, textures, and
        pipelines.
      </ArticleParagraph>
      <CodeBlock langauge="javascript">
        {`let gpuDevice = null;

async function initializeWebGPU() {
    // Check to ensure the user agent supports WebGPU.
    if (!('gpu' in navigator)) {
        console.error("User agent doesn't support WebGPU.");
        return false;
    }

    // Request an adapter.
    const gpuAdapter = await navigator.gpu.requestAdapter();

    // requestAdapter may resolve with null if no suitable adapters are found.
    if (!gpuAdapter) {
        console.error('No WebGPU adapters found.');
        return false;
    }

    // Request a device.
    // Note that the promise will reject if invalid options are passed to the optional
    // dictionary. To avoid the promise rejecting always check any features and limits
    // against the adapters features and limits prior to calling requestDevice().
    gpuDevice = await gpuAdapter.requestDevice();

    // requestDevice will never return null, but if a valid device request can't be
    // fulfilled for some reason it may resolve to a device which has already been lost.
    // Additionally, devices can be lost at any time after creation for a variety of reasons
    // (ie: browser resource management, driver updates), so it's a good idea to always
    // handle lost devices gracefully.
    gpuDevice.lost.then((info) => {
        console.error(\`WebGPU device was lost: \${info.message}\`);

        gpuDevice = null;

        // Many causes for lost devices are transient, so applications should try getting a
        // new device once a previous one has been lost unless the loss was caused by the
        // application intentionally destroying the device. Note that any WebGPU resources
        // created with the previous device (buffers, textures, etc) will need to be
        // re-created with the new one.
        if (info.reason !== 'destroyed') {
            initializeWebGPU();
        }
    });

    onWebGPUInitialized();

    return true;
}

function onWebGPUInitialized() {
    // Begin creating WebGPU resources here...
}

await initializeWebGPU();`}
      </CodeBlock>
      <ArticleHeading>Timeline</ArticleHeading>
      <ArticleParagraph>
        Before we move forward, I think understanding about timeline is
        important. A computer system with a user agent at the front-end and GPU
        at the back-end has components working on different timelines in
        parallel:
      </ArticleParagraph>
      <ol className="ml-[30px]">
        <li>
          <b>Content timeline:</b>
          Associated with the execution of the Web script. It includes calling
          all methods described by this specification.
        </li>
        <li>
          <b>Device timeline:</b>
          Associated with the GPU device operations that are issued by the user
          agent. It includes creation of adapters, devices, and GPU resources
          and state objects, which are typically synchronous operations from the
          point of view of the user agent part that controls the GPU, but can
          live in a separate OS process.
        </li>
        <li>
          <b>Queue timeline:</b>
          Associated with the execution of operations on the compute units of
          the GPU. It includes actual draw, copy, and compute jobs that run on
          the GPU.
        </li>
      </ol>
      <br />
      <ArticleHeading>Buffer Mapping</ArticleHeading>
      <ArticleParagraph>
        Before we have our data neatly stored in a buffer, we should understand
        how mapping works before we access or update the buffers. This was a bit
        confusing for me in the beginning. I thought that once you create a
        buffer, you can access it directly, but that's not the case. You need to
        map the buffer to access it. Mapping a buffer is an asynchronous
        operation. An application can request to map a{' '}
        <GreyHighlight>GPUBuffer</GreyHighlight> so that they can access its
        content via <GreyHighlight>ArrayBuffers</GreyHighlight> that represent
        part of the <GreyHighlight>GPUBuffer</GreyHighlight>'s allocations.
        Mapping a GPUBuffer is requested asynchronously with{' '}
        <GreyHighlight>mapAsync()</GreyHighlight>
        so that the user agent can ensure the GPU finished using the{' '}
        <GreyHighlight>GPUBuffer</GreyHighlight>
        before the application can access its content. A mapped{' '}
        <GreyHighlight>GPUBuffer</GreyHighlight> cannot be used by the GPU and
        must be unmapped using <GreyHighlight>unmap()</GreyHighlight> before
        work using it can be submitted to the Queue timeline. Take a look at the
        following table:
      </ArticleParagraph>

      <div className="w-[100%] overflow-auto">
        <table>
          <thead>
            <tr>
              <th align="center" />
              <th align="center">
                Regular <br /> ArrayBuffer
              </th>
              <th align="center">
                Shared <br /> Memory
              </th>
              <th align="center">
                Mappable GPU
                <br /> buffer
              </th>
              <th align="center">
                Non-mappable GPU
                <br /> buffer (or texture)
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="center">
                CPU, in the <br />
                content process
              </td>
              <td align="center">
                <strong>Visible</strong>
              </td>
              <td align="center">
                <strong>Visible</strong>
              </td>
              <td align="center">Not visible</td>
              <td align="center">Not visible</td>
            </tr>
            <tr>
              <td align="center">
                CPU, in the GPU <br />
                process
              </td>
              <td align="center">Not visible</td>
              <td align="center">
                <strong>Visible</strong>
              </td>
              <td align="center">
                <strong>Visible</strong>
              </td>
              <td align="center">Not visible</td>
            </tr>
            <tr>
              <td align="center">GPU</td>
              <td align="center">Not visible</td>
              <td align="center">Not visible</td>
              <td align="center">
                <strong>Visible</strong>
              </td>
              <td align="center">
                <strong>Visible</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <br />
      <ArticleParagraph>
        <b>Important point:</b> <GreyHighlight>GPUBuffer</GreyHighlight> mapping
        is done as an ownership transfer between the CPU and the GPU. At each
        instant, only one of the two can access the buffer, so no race is
        possible. In summary, GPU cannot access mapped buffers, and CPU cannot
        access unmapped ones.
      </ArticleParagraph>
      <ArticleHeading>Creating Buffers</ArticleHeading>
      <ArticleParagraph>
        Now let's create some buffers!
        <br /> Buffer creation involves allocating memory on the GPU and
        defining the properties of the buffer, such as its size, usage flags,
        and memory type. See the following example. I will talk about command
        encoders and command buffers later.
      </ArticleParagraph>
      <CodeBlock langauge="javascript">
        {`  // Create a GPU buffer in a mapped state and an arrayBuffer for writing.
  const gpuWriteBuffer = device.createBuffer({
    mappedAtCreation: true,
    size: 4,
    usage: GPUBufferUsage.MAP_WRITE | GPUBufferUsage.COPY_SRC
  });
  const arrayBuffer = gpuWriteBuffer.getMappedRange();

  // Write bytes to buffer.
  new Uint8Array(arrayBuffer).set([0, 1, 2, 3]);

  // At this point, the GPU buffer is mapped, meaning it is owned by the CPU,
  // and it's accessible in read/write from JavaScript. So that the GPU can
  // access it, it has to be unmapped which is as simple as calling
  // gpuBuffer.unmap().
  gpuWriteBuffer.unmap();

  // Get a GPU buffer for reading in an unmapped state.
  const gpuReadBuffer = device.createBuffer({
    mappedAtCreation: false,
    size: 4,
    usage: GPUBufferUsage.COPY_DST | GPUBufferUsage.MAP_READ
  });

  // Encode commands for copying buffer to buffer.
  const copyEncoder = device.createCommandEncoder();
  copyEncoder.copyBufferToBuffer(
      gpuWriteBuffer /* source buffer */, 0 /* source offset */,
      gpuReadBuffer /* destination buffer */, 0 /* destination offset */,
      4 /* size */
  );

  // Submit copy commands.
  const copyCommands = copyEncoder.finish();
  device.queue.submit([copyCommands]);

  // Read buffer.
  console.time("mapAsync");
  await gpuReadBuffer.mapAsync(GPUMapMode.READ);
  console.timeEnd("mapAsync");
  const copyArrayBuffer = gpuReadBuffer.getMappedRange();

  console.log(new Uint8Array(copyArrayBuffer));

  gpuReadBuffer.unmap();`}
      </CodeBlock>
      <ArticleHeading>Pipelines and Binding Groups</ArticleHeading>
      <ArticleParagraph>
        Structurally, the pipeline consists of a sequence of programmable stages
        (shaders) and fixed-function states, such as the blending modes.
      </ArticleParagraph>
      <ul className="ml-[30px]">
        <li>
          They come in <GreyHighlight>GPURenderPipeline</GreyHighlight>
          and
          <GreyHighlight>GPUComputePipeline</GreyHighlight>
        </li>
        <li>They are immutable after creation</li>
      </ul>
      <CodeBlock language="javascript">
        {`// You may create a pipeline layout manually (not advised really).
// Or you may create it automatically (see below)
const pipelineLayout = device.createPipelineLayout({
  bindGroupLayouts: [bindGroupLayout],
});
const pipeline = device.createComputePipeline({
  layout: pipelineLayout,
  compute: {
    module: shaderModule,
    entryPoint: 'computeMain',
  }
});
// But what is a bind group layout? See the following`}
      </CodeBlock>
      <ArticleParagraph>
        A binding group layout defines the layout of a bind group. It is a
        sequence of entries, each of which specifies the binding number,
        visibility, and type of resource. And a bind group associates GPU
        buffers to the entries of the shaders (as bind group layout specifies).
      </ArticleParagraph>
      <ArticleParagraph>
        In the example below, the <GreyHighlight>bindGroupLayout</GreyHighlight>
        expects two readonly storage buffers at numbered entry bindings 0, 1,
        and a storage buffer at 2 for the compute shader. The{' '}
        <GreyHighlight>bindGroup</GreyHighlight>
        on the other hand, defined for this bind group layout, associates GPU
        buffers to the entries:{' '}
        <GreyHighlight>gpuBufferFirstMatrix</GreyHighlight>
        to the binding 0, <GreyHighlight>gpuBufferSecondMatrix</GreyHighlight>
        to the binding 1, and <GreyHighlight>resultMatrixBuffer</GreyHighlight>{' '}
        to the binding 2.
      </ArticleParagraph>

      <CodeBlock langauge="typescript">
        {`const bindGroupLayout = device.createBindGroupLayout({
  entries: [
    {
      binding: 0,
      visibility: GPUShaderStage.COMPUTE,
      buffer: {type: 'read-only-storage'}
    },
    {
      binding: 1,
      visibility: GPUShaderStage.COMPUTE,
      buffer: {type: 'read-only-storage'}
    },
    {
      binding: 2,
      visibility: GPUShaderStage.COMPUTE,
      buffer: {type: 'storage'}
    }
  ]
});

const bindGroup = device.createBindGroup({
  layout: bindGroupLayout,
  entries: [
    {binding: 0, resource: {buffer: gpuBufferFirstMatrix}},
    {binding: 1, resource: {buffer: gpuBufferSecondMatrix}},
    {binding: 2, resource: {buffer: resultMatrixBuffer}}
  ]
});
        `}
      </CodeBlock>
      <ArticleParagraph>
        If you use the auto layout for the compute pipeline, it will only
        contain bindings for variables that are directly or transitively
        referenced by the shader's entry point function. If you don't reference
        the defined vars in the shader code, then they won't be added to the
        automatically generated bind group layout. One quick way to reference
        the vars inside the shader is to add dummy referencing lines to the top
        of the shader's entry point:
      </ArticleParagraph>
      <CodeBlock language="javascript">
        {`@group(0) @binding(0) var<storage, read_write> results : array<i32>;
@group(0) @binding(1) var<storage, read_write> count : array<u32>;

@compute @workgroup_size(16, 16)
fn main(@builtin(workgroup_id) groupId : vec3<u32>,
  @builtin(local_invocation_id) threadId: vec3<u32>,
  @builtin(global_invocation_id) globalId : vec3<u32>) {

_ = &results;
_ = &count;

// This helped me a lot to set up the binding group with 'auto' layout.`}
      </CodeBlock>
      <ArticleParagraph>
        In this way the bind group creation would be like the following:
      </ArticleParagraph>
      <CodeBlock langauge="javascript">
        {`const bindGroup = device.createBindGroup({
  layout : pipeline.getBindGroupLayout(0),
  entries: [
    {binding : 0, resource : {buffer : results}},
    {binding : 1, resource : {buffer : count}}
  ]
});`}
      </CodeBlock>
      <ArticleHeading>Command Encoders and Command Buffers</ArticleHeading>
      <ArticleParagraph>
        <i>The commanders of the GPU army!</i>
      </ArticleParagraph>
      <ArticleParagraph>
        Command buffers are containers that hold instructions for the GPU to
        execute. They store commands such as binding resources, setting pipeline
        states, and dispatching compute operations.
      </ArticleParagraph>
      <ArticleParagraph>
        Command buffers are pre-recorded lists of GPU commands that can be
        submitted to the GPUQueue for execution. Each GPU command represents a
        task to be performed on the GPU, such as setting state, drawing, copying
        resources, etc. Here are the steps to create a command buffer in WebGPU:
      </ArticleParagraph>
      <ol className="ml-[30px]">
        <li>Create a GPUCommandEncoder from the device</li>
        <li>Perform copies between buffers/textures</li>
        <li>Begin render or compute passes</li>
        <li>Creates a GPUCommandBuffer when finished.</li>
        <li>
          Submit the command buffer to the queue for execution. (more on this
          later)
        </li>
      </ol>
      <br />
      <ArticleParagraph>Some notes:</ArticleParagraph>
      <ol className="ml-[30px]">
        <li>Command buffers don't do anything until submitted to the queue.</li>
        <li>
          A <GreyHighlight>GPUCommandBuffer</GreyHighlight>
          can only be submitted once, at which point it becomes invalid. To
          reuse rendering commands across multiple submissions, use
          <GreyHighlight>GPURenderBundle.</GreyHighlight>
          (I have not tested this myself yet.)
        </li>
      </ol>
      <br />
      <ArticleParagraph>
        <b>What is a Pass?</b>A pass is a set of commands that are executed
        together. A pass can be a render pass or a compute pass. Obviously, a
        render pass is a set of commands that render to a texture and a compute
        pass is a set of commands that perform compute operations.
      </ArticleParagraph>
      <CodeBlock langauge="javascript">
        {`
const commandEncoder = device.createCommandEncoder();
commandEncoder.copyBufferToBuffer(bufferA, 0, bufferB, 0, 256);

const passEncoder = commandEncoder.beginComputePass();
passEncoder.setPipeline(pipeline);
passEncoder.setBindGroup(0, bindGroup);
passEncoder.dispatchWorkgroups(128);
passEncoder.end();

const commandBuffer = commandEncoder.finish();
device.queue.submit([commandBuffer]);`}
      </CodeBlock>
      <ArticleParagraph>
        <span className="h3_style">
          <b>Workgroups</b>
        </span>
      </ArticleParagraph>
      <ArticleParagraph>
        A workgroup is like a thread block in CUDA world. It is a group of
        threads that can share data through shared memory and synchronize if
        required.
      </ArticleParagraph>
      <CodeBlock langauge="javascript">
        {`// group_size => workgroup_size(Sx, Sy, Sz) (similar to thread block)
// group_count => dispatchWorkgroups(Nx, Ny, Nz) (similar to a grid)
// Total tasks => (Nx * Ny * Nz * Sx * Sy * Sz)
pass.dispatchWorkgroups(group_count[0], group_count[1], group_count[2]);

// In the shader module you can have optional built-in variables 
// to get the workgroup and thread IDs as follows:
@compute @workgroup_size(group_sizeX, group_sizeY, group_sizeZ)
fn main(@builtin(workgroup_id) groupId : vec3<u32>,
  @builtin(local_invocation_id) threadId: vec3<u32>,
  @builtin(global_invocation_id) globalId : vec3<u32>,
  @builtin(num_workgroups) gridDim : vec3<u32>) {
    // the shader code`}
      </CodeBlock>
      <ArticleHeading>Queue</ArticleHeading>
      <ArticleParagraph>
        The GPU needs an orderly queue to process commands. The queue is
        responsible for receiving command buffers and executing them in the
        order they were submitted. It ensures that the GPU follows a structured
        path.
      </ArticleParagraph>
      <ol className="ml-[30px]">
        <li>
          Device has a default <GreyHighlight>GPUQueue</GreyHighlight>, which is
          the only one available for now. But I hope in the future, there will
          be more queues for different purposes.
        </li>
        <li>
          Also the Queue has handy helper functions for writing to buffers and
          textures. These are the easiest ways to set the contents of these
          resources.
        </li>
      </ol>
      <br />
      <CodeBlock langauge="javascript">
        {`device.queue.writeBuffer(buffer, 0, typedArray);
device.queue.writeTexture({ texture: dstTexture },
                          typedArray,
                          { bytesPerRow: 256 },
                          { width: 64, height: 64 });`}
      </CodeBlock>
      <ArticleParagraph>
        After submitting command buffers to the queue, the queue will execute
        them in order. The queue will wait for the previous command buffer to
        finish before executing the next one. This is called implicit
        synchronization. After submitting tasks to the queue, the CPU can
        continue to do other work while the GPU is busy executing the commands.
        I think there are two ways to synchronize the tasks on the Queue:
      </ArticleParagraph>
      <CodeBlock className="javascript">
        {`// 1. Use the promise returned by the following function
await device.queue.onSubmittedWorkDone();

// 2. If there is any output results in the command buffer that you are
// waiting for, you can call the mapAsync and wait for its promise to resolve.
await results.mapAsync(GPUMapMode.READ);
`}
      </CodeBlock>
      <ArticleParagraph>
        Ok, now let's wrap up everything we have learned so far with a nice
        picture (again stolen from Andy):
      </ArticleParagraph>
      <img src={GPU_IMG_2} alt="graphic" />
      <ArticleHeading>Best Practices</ArticleHeading>
      <ol>
        <li>More pipelines, more state switching, less performance</li>
        <li>
          Create pipelines in advance, and don't use them immediately after
          creation. Or use the async version. The promise resolves when the
          pipleline is ready to use without any stalling.
        </li>
      </ol>
      <br />
      <CodeBlock langauge="javascript">
        {`device.createComputePipelineAsync({
 compute: {
   module: shaderModule,
   entryPoint: 'computeMain'
 }
}).then((pipeline) => {
  const commandEncoder = device.createCommandEncoder();
  const passEncoder = commandEncoder.beginComputePass();
  passEncoder.setPipeline(pipeline);
  passEncoder.setBindGroup(0, bindGroup);
  passEncoder.dispatchWorkgroups(128);
  passEncoder.end();
  device.queue.submit([commandEncoder.finish()]);
});`}
      </CodeBlock>
      <ArticleHeading>Debugging WebGPU code</ArticleHeading>
      <ArticleParagraph>
        <span className="h3_style">
          <b>Label Usage</b>
        </span>
      </ArticleParagraph>
      <ul className="ml-[30px]">
        <li>
          Every single object in WebGPU can be given a label, and those labels
          will be use when reporting error messages.
        </li>
        <li>
          Labels have no impact on performance, so there is no reason not to use
          them!
        </li>
      </ul>
      <br />
      <CodeBlock langauge="javascript">
        {`const vertexBuffer = device.createBuffer({
  label: 'Player vertices',
  size: vertexData.byteLength,
  usage: GPUBufferUsage.INDEX | GPUBufferUsage.COPY_DST,
});

const passEncoder = commandEncoder.beginRenderPass({
  label: 'Primary render pass',
  colorAttachments: [{
    view: context.getCurrentTexture().createView(),
    loadOp: 'clear',
    clearValue: [0.0, 0.0, 0.0, 1.0],
    storeOp: 'store',
  }]
});`}
      </CodeBlock>
      <ArticleParagraph>
        <h3 className="h3_style ">
          <b>Debug Group Usage</b>
        </h3>
      </ArticleParagraph>
      <ul>
        <li>
          Debug groups are great for telling where in the code an error took
          place.
        </li>
        <li>
          They give a personalized stack with for every error that occurs inside
          them.
        </li>
        <li>Just like labels, they show up in the native tools as well.</li>
        <li>
          Plus they're lightweight, so there is no need to worry about stripping
          them out of the release code.
        </li>
      </ul>
      <br />
      <CodeBlock langauge="javascript">
        {`const commandEncoder = device.createCommandEncoder();
commandEncoder.pushDebugGroup('Main Render Loop');

  commandEncoder.pushDebugGroup('Render Scene');
    renderGameScene(commandEncoder);
  commandEncoder.popDebugGroup();

  commandEncoder.pushDebugGroup('Render UI');
    renderGameUI(commandEncoder);
  commandEncoder.popDebugGroup();

commandEncoder.popDebugGroup();
device.queue.submit([commandEncoder.finish()]);`}
      </CodeBlock>
      <ArticleParagraph>
        Using error scopes to capture validation errors from a GPUDevice
        operation that may fail:
      </ArticleParagraph>
      <CodeBlock langauge="javascript">
        {`gpuDevice.pushErrorScope('validation');

let sampler = gpuDevice.createSampler({
    maxAnisotropy: 0, // Invalid, maxAnisotropy must be at least 1.
});

gpuDevice.popErrorScope().then((error) => {
    if (error) {
        // There was an error creating the sampler, so discard it.
        sampler = null;
        console.error(\`An error occured while creating sampler: \${error.message}\`);
    }
});`}
      </CodeBlock>
      <ArticleHeading>Advanced Topics</ArticleHeading>
      <ArticleParagraph>
        In this section I will talk about the following topics:
      </ArticleParagraph>
      <ol className="ml-[30px]">
        <li>Some notes on WGSL</li>
        <li>Atomic operations in WGSL</li>
        <li>Using uniform buffers in WGSL</li>
        <li>Reusing buffers and command buffers</li>
        <li>Utilizing shared memory</li>
        <li>Streaming (Pipelining) data to the GPU</li>
        <li>Double buffering</li>
        <li>Tips and tricks in porting serial C code to WebGPU+JS</li>
      </ol>
      <br />
      <ArticleParagraph>(will be updated)</ArticleParagraph>
      <ArticleHeading>References</ArticleHeading>
      <ArticleParagraph>Ordered to be neat!</ArticleParagraph>
      <ul className="ml-[30px]">
        <li>
          Where to run WebGPU:{' '}
          <ArticleLink noUnderline={true} href="https://caniuse.com/webgpu">
            CanIUse
          </ArticleLink>
        </li>
        <li>
          WebGPU at{' '}
          <ArticleLink
            noUnderline={true}
            href="https://developer.chrome.com/blog/webgpu-io2023/"
          >
            Google IO 2023
          </ArticleLink>
        </li>
        <li>
          WebGPU compute{' '}
          <ArticleLink noUnderline={true} href="https://web.dev/gpu-compute/">
            example
          </ArticleLink>
        </li>
        <li>
          Awesome list for{' '}
          <ArticleLink
            noUnderline={true}
            href="https://github.com/mikbry/awesome-webgpu"
          >
            WebGPU
          </ArticleLink>
        </li>
        <li>
          Nikita's great{' '}
          <ArticleLink
            noUnderline={true}
            href="https://wiki.nikiv.dev/computer-graphics/webgpu"
          >
            collection
          </ArticleLink>
        </li>
        <li>
          Introduction by{' '}
          <ArticleLink
            noUnderline={true}
            href="https://surma.dev/things/webgpu/"
          >
            Surma
          </ArticleLink>
        </li>
        <li>
          Chrome team{' '}
          <ArticleLink
            noUnderline={true}
            href="https://developer.chrome.com/articles/gpu-compute/"
          >
            article
          </ArticleLink>
        </li>
        <li>
          WebGPU on{' '}
          <ArticleLink
            noUnderline={true}
            href="https://developer.chrome.com/docs/web-platform/webgpu/"
          >
            Firefox
          </ArticleLink>
        </li>
        <li>
          WebGPU{' '}
          <ArticleLink
            noUnderline={true}
            href="https://gpuweb.github.io/gpuweb/explainer/"
          >
            explainer
          </ArticleLink>
        </li>
        <li>
          WebGPU{' '}
          <ArticleLink
            noUnderline={true}
            href="https://gpuweb.github.io/gpuweb/"
          >
            spec
          </ArticleLink>
        </li>
        <li>
          Andi's{' '}
          <ArticleLink
            noUnderline={true}
            href="https://cohost.org/mcc/post/1406157-i-want-to-talk-about-webgpu"
          >
            weblog
          </ArticleLink>
        </li>
      </ul>
      <br />
      <ArticleParagraph>Repos and examples:</ArticleParagraph>
      <ul className="ml-[30px]">
        <li>
          <ArticleLink noUnderline={true} href="https://github.com/0hq/WebGPT/">
            WebGPT model
          </ArticleLink>
        </li>
        <li>
          <ArticleLink
            noUnderline={true}
            href="https://github.com/gfx-rs/wgpu-native"
          >
            Native WebGPU
          </ArticleLink>
        </li>
        <li>
          <ArticleLink
            noUnderline={true}
            href="https://github.com/gfx-rs/wgpu/"
          >
            WebGPU for rust
          </ArticleLink>
        </li>
        <li>
          <ArticleLink
            noUnderline={true}
            href="https://github.com/gfx-rs/wgpu/"
          >
            WebGPU in Rust
          </ArticleLink>
        </li>
        <li>
          <ArticleLink
            noUnderline={true}
            href="https://github.com/webgpu/webgpu-samples"
          >
            WebGPU samples
          </ArticleLink>
        </li>
        <li>
          <ArticleLink
            noUnderline={true}
            href="https://github.com/gfx-rs/wgpu/wiki"
          >
            Rust WebGPU Wiki
          </ArticleLink>
        </li>
        <li>
          <ArticleLink
            noUnderline={true}
            href="https://github.com/gfx-rs/wgpu/wiki/Users"
          >
            Rust WebGPU Users
          </ArticleLink>
        </li>
        <li>
          <ArticleLink
            noUnderline={true}
            href="https://github.com/gfx-rs/wgpu-native"
          >
            Rust WebGPU Native
          </ArticleLink>
        </li>
        <li>
          <ArticleLink noUnderline={true} href="https://webgpulab.xbdev.net/">
            WebGPULab examples
          </ArticleLink>
        </li>
        <li>
          <ArticleLink noUnderline={true} href="https://webgpulab.xbdev.net/">
            WebGPU for TypeScript
          </ArticleLink>
        </li>
        <li>
          <ArticleLink noUnderline={true} href="https://youtu.be/7fiCsG6IILs">
            An example on YouTube
          </ArticleLink>
        </li>
        <li>
          <ArticleLink
            noUnderline={true}
            href="https://github.com/gfx-rs/wgpu/wiki/Debugging-wgpu-Applications"
          >
            Debugging WebGPU Apps
          </ArticleLink>
        </li>
      </ul>
      <br />
      <ArticleParagraph>Other Resources</ArticleParagraph>
      <ul className="ml-[30px]">
        <li>
          <ArticleLink
            noUnderline={true}
            href="https://github.com/gpujs/gpu.js"
          >
            GPU Accelerated JS
          </ArticleLink>
        </li>
        <li>
          <ArticleLink noUnderline={true} href="https://www.khronos.org/webcl/">
            Khronos WebCL
          </ArticleLink>
        </li>
      </ul>
    </div>
  );
}

export default WebGPUContent;
