import s1 from '../assets/png/icon-green-1.png';
import s2 from '../assets/png/icon-green-2.png';
import s3 from '../assets/png/icon-green-3.png';

export const CONTACT = {
  metadata: {
    description:
      'Distributive created the Distributive Compute Protocol (DCP). A standard for matching supply and demand for compute to enable anyone, academic or commercial, with a need for computing power to have access to that power within any web connected device on the platform.',
    keywords: '',
  },
  hero: {
    title: 'Contact',
    description:
      'Learn how distributed computing could improve more than the bottom line. DCP solution experts are here to help, regardless of industry.',
    bgImage:
      'bg-[url(./assets/png/background-with-logo.png)] !bg-cover !bg-center bg-no-repeat',
  },
  steps: {
    title: 'How to get started with the Distributive Compute Protocol (DCP)',
    description:
      'Build a full MVP without any data leaving firewalls. Whether building a new program from scratch or looking to optimize the costs of an existing application, DCP delivers measurable results for businesses.',
    bgImage: 'bg-[url(./assets/jpg/screen-chart.jpg)]',
    cards: [
      {
        src: s1,
        title: 'INITIAL CONSULTATION',
        description:
          'DCP solutions experts will do a cost-benefit analysis and determine if there is an ideal fit.',
      },
      {
        src: s2,
        title: 'PRE-PRODUCTION EXAMPLES',
        description:
          'DCP engineers provide as much support as needed to build an MVP and provide competitive benchmarks.',
      },
      {
        src: s3,
        title: 'SHIP & SCALE',
        description:
          'Deploy to production and scale vertically or horizontally on-demand.',
      },
    ],
  },
  departments: {
    title: `Don't worry, we don't byte`,
    description: 'Find the area of Distributive that you’re looking for',
    textBgImage:
      'bg-[url(./assets/jpg/green-rightside.jpg)] bg-bottom bg-no-repeat bg-cover',
    wantButton: false,
    isTextLeft: false,
    bgImage: 'bg-[url(./assets/jpg/team-2022.jpg)]',
    contacts: [
      {
        department: 'General Inquiries',
        email: 'info@distributive.network',
      },
      {
        department: 'Sales Department',
        email: 'sales@distributive.network',
      },
      {
        department: 'Marketing and Media Relations',
        email: 'marketing@distributive.network',
      },
      {
        department: 'Recruitment',
        email: 'jobs@distributive.network',
      },
      {
        department: 'Corporate Billing Inquiries',
        email: 'accounting@distributive.network',
      },
      {
        department: 'Privacy Inquires',
        email: 'privacy@distributive.network',
      },
      {
        department: 'Osler',
        email: 'osler@distributive.network',
      },
      {
        department: 'DIANA',
        email: 'diana@distributive.network',
      },
    ],
  },
  form: {
    title: 'Get in Touch',
    textBgImage: 'bg-[url(./assets/jpg/green-leftside.jpg)] bg-bottom',
    description:
      'Learn how distributed computing could improve more than the bottom line. DCP solution experts are here to help, regardless of industry.',
    wantButton: true,
    isTextLeft: true,
    bgImage: 'bg-[url(./assets/jpg/lecture-room.jpg)]',
    checkbox:
      'Please send me updates on Distributive and our products. (You can opt out at any time.)',
    button: {
      text: 'Submit',

      onClick: () => {},
    },
    wantAuth: true,
    closingMessage: 'Thank you for your interest!',
  },
  address: {
    title: 'Prefer another way? No problem!',
    textBgImage: 'bg-[url(./assets/jpg/green-rightside.jpg)] bg-bottom',
    description:
      'Our head office is located in beautiful downtown Kingston, Ontario Canada but our goal is to help make compute accessible for everyone around the world.',
    wantButton: false,
    isTextLeft: false,
    bgImage: 'bg-[url(./assets/jpg/office-location-map.jpg)]',
    info: (
      <div className="text-white py-[2rem] font-[400] text-[14px]">
        <h2 className="font-[600] text-[18px]">Phone: </h2>
        <p>+1 (613) 484-2415</p>
        <br />
        <h2 className="font-[600] text-[18px]">Email: </h2>
        <p>info@distributive.network</p>
        <br />
        <h2 className="font-[600] text-[18px]">Mailing Address: </h2>
        <p>303 Bagot St. Suite 403</p>
        <p>Kingston, ON, K7K 5W7</p>
        <p>Canada</p>
      </div>
    ),
  },
  cta: {
    title: 'To keep up to date why not check us out on LinkedIn',
    subHeading:
      'Ready to start disrupting the industry and save on engineers` time, energy, and expense? Get in touch.',
    ctaText: 'Distributive on LinkedIn',
    ctaBgColour: 'bg-[#17484D]',
    ctaTextColour: 'text-[white]',
    bgImage: 'bg-[url(./assets/jpg/lecture-room.jpg)]',
    href: 'https://ca.linkedin.com/company/distributive',
  },
};
