import ArticleHeading from '../../components/ArticleHeading/ArticleHeading';
import ArticleLink from '../../components/ArticleLink/ArticleLink';
import ArticleParagraph from '../../components/ArticleParagraph/ArticleParagraph';

function LegendrePairsContent() {
  return (
    <>
      <ArticleHeading>
        A Needle in a Needle Stack - A Really BIG Problem
      </ArticleHeading>

      <ArticleParagraph>
        A Legendre Pair exists when a pair of special number sequences
        satisfying a special set of mathematical rules are identified, similar
        to how a prime is divisible only by itself and 1. The mathematical rules
        that must be satisfied are known, but the input number sequences to
        satisfy them are not! The search space containing possible Lendre Pairs
        has 13 dimensions; the total number of number sequences to check is:
      </ArticleParagraph>
      <ArticleParagraph>
        126 × 84 × 84 × 84 × 84 × 84 × 84 × 84 × 84 × 84 × 84 × 84 × 84 = 1.55 ×
        10<span className="text-[8px] relative bottom-[10px]">25</span>
      </ArticleParagraph>
      <ArticleParagraph>
        That’s 15.5 SEPTILLION number sequences to search…
      </ArticleParagraph>
      <ArticleParagraph>
        At 1 microsecond per number sequence, the complete search space would
        take 20 billion years using a single processor!!
      </ArticleParagraph>
      <ArticleParagraph>
        Thankfully, by dividing up the search space into billions of smaller
        pieces, and distributing them across multiple computers using DCP (like
        SETI@home, but a modern-day version of it!) while also taking advantage
        of WebGPU, this problem becomes tractable! Citizen-scientists can
        participate in the search by sponsoring and claiming subspaces to
        search, and by volunteering their computing power.
      </ArticleParagraph>
      <ArticleParagraph>
        Join the search today and appear on the Leaderboard!
      </ArticleParagraph>

      <ArticleHeading>An Open Conjecture…</ArticleHeading>
      <ArticleParagraph>
        Legendre pairs were introduced in 2001 by Seberry and her students, as a
        means to construct Hadamard matrices via a two-circulant core
        construction. A Legendre pair consists of two sequences of the same odd
        length ℓ, with elements from {'{'}−1, +1{'}'}, such that their
        respective autocorrelation coefficients sum to −2, or (equivalently)
        their respective power spectral density coefficients sum to 2ℓ + 2.
      </ArticleParagraph>
      <ArticleParagraph>
        Legendre pairs of every odd prime length ℓ exist, via a simple
        construction using the Legendre symbol from Number Theory. Legendre
        pairs of odd composite length ℓ can be found via the concept of
        compression, introduced by Djokovic and Kotsireas in 2015.
      </ArticleParagraph>
      <ArticleParagraph>
        While compression is not an one-to-one mapping, there are cases where
        promising compression seeds have been identified. One such conjecture
        predicts that Legendre pairs of some specific orders exist for lengths ℓ
        divisible by nine and the first open case is ℓ = 117.
      </ArticleParagraph>
      <ArticleParagraph>
        Legendre pairs of every odd order are conjectured to exist and this
        implies the validity of the Hadamard conjecture. Hadamard matrices and
        their applications in image analysis, signal processing, fault-tolerant
        systems, analysis of stock market data, coding theory and cryptology,
        combinatorial designs and so on. Finding Legendre pairs and validating
        the Hadamard conjecture is thus a matter of both theoretical and
        practical importance.
      </ArticleParagraph>

      <ArticleHeading>
        View the Job Dashboard to see how you can help
      </ArticleHeading>
      <div className="flex flex-col justify-center items-start">
        <img
          alt="legendre"
          src="/legendre_pairs/dashboard.png"
          className=" bg-contain bg-no-repeat !bg-center w-[100%] min-w-[200px] max-w-[962px] min-h-[112px] max-h-[540px] h-[100%] "
        />
        <div className="opacity-[0.5] text-center min-w-[180px] min-h-[46px] inline-block h-[fit-content] text-[white] cursor-default font-bold my-[30px] py-[10px] px-[40px] bg-[#17484D]">
          Coming soon
        </div>
      </div>
      <ArticleHeading>
        Help Search by Running a DCP Worker - Crunch some numbers!
      </ArticleHeading>
      <ArticleParagraph>
        Go to the DCP Portal and launch a Worker in your web browser simply by
        clicking ‘Start’. Within your web browser’s secure environment, your
        computer will check billions of numbers for valid Legendre Pair
        sequences! As your computer runs the numbers, it will deposit compute
        credits into your DCP Bank Account!
      </ArticleParagraph>
      <a
        href="https://portal.distributed.computer/worker"
        target="_blank"
        rel="noreferrer"
        className=" text-center min-w-[180px] min-h-[46px] inline-block text-[white] font-bold  py-[10px] px-[40px] bg-[#17484D]"
      >
        DCP Portal
      </a>
      <br />
      <br />
      <ArticleParagraph>
        If you’re a more serious user, check out the DCP Screensaver Worker,
        Docker Worker, and Command Line Worker linked{' '}
        <ArticleLink
          href={'http://www.staging.distributive.network/workers'}
          className="text-[#14ae5c]"
        >
          here
        </ArticleLink>
        . Simply install it and it will connect to the DCP Global Network and
        work on this job, and other science jobs!
      </ArticleParagraph>
      <ArticleParagraph>
        Again, if you would like to donate Compute Credits earned, please use
        the DCP Bank Account below when configuring your Workers!
      </ArticleParagraph>
      <ArticleParagraph className="font-bold !ml-[60px]">
        Legendre Pair 117 Job DCP Bank Account:
        <br />
        0x39858577b39a9c6de9b4eb48eadf609079b6ec2e
      </ArticleParagraph>

      <ArticleHeading>
        Sponsoring an LP117 Job - Claim a search subspace!
      </ArticleHeading>
      <div>
        <ArticleParagraph>
          Launch a distributed search on the DCP public network to check about
          30 trillion number sequences for potential LP117 sequences; claim a
          subspace for US $13.70
        </ArticleParagraph>
        <ArticleParagraph>
          Your username will appear on the Leaderboard next to the total number
          of sequences you helped sponsor. If you find an actual LP117 Pair,
          your username will go down in history in a scientific publication! As
          soon as your job is live, it will appear in the ticker banner above
          until it completes. You will get a notification when your job is done
          and how many sequences you helped find!
        </ArticleParagraph>
        <ArticleParagraph>
          Set a sequence in the slider bars below and click Launch Job! (Coming
          Soon)
        </ArticleParagraph>
        <div className=" text-center min-w-[180px] min-h-[46px] inline-block opacity-[0.5] cursor-default text-[white] font-bold py-[10px] px-[40px] bg-[#17484D]">
          Launch Job
        </div>
        <br />
        <br />

        <ArticleHeading>About the Researcher</ArticleHeading>
        <div className="mb-[30px]">
          <div className="flex justify-start gap-[30px] xl:gap-[90px] pt-[40px] flex-col lg:flex-row">
            <div className="w-[100%] lg:w-[auto]">
              <img
                src="/legendre_pairs/Ilias_author_image.png"
                alt="Ilias"
                className="object-fit min-w-[336px] min-h-[246px] max-w-[auto] xs:max-w-[500px] w-[100%] sm:w-[auto] mx-auto sm:m-0 lg:mx-0"
              />
            </div>
            <div className="p_standard_style flex flex-col items-start justify-start relative top-[0px] xl:top-[20px] text-left lg:text-left ">
              <ArticleParagraph className="w-[100%] font-bold">
                Dr. Ilias S. Kotsireas
              </ArticleParagraph>
              <ArticleParagraph className="w-[100%] mb-[30px]">
                Professor, Director of the CARGO Lab Laurier University
              </ArticleParagraph>
              <ArticleParagraph className="w-[100%]">
                My research is in the areas of symbolic computation and
                combinatorial designs. I use algorithmic, meta-heuristic and
                high-performance computing techniques to study existence
                questions for combinatorial designs. I am interested in
                theoretical and algorithmic aspects of solving systems of
                polynomial equations.
              </ArticleParagraph>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LegendrePairsContent;
