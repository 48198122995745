import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './App';

import './index.css';
import './styles/defaultSizes.scss';
import './components/Article/ArticleContent.scss';
import './styles/codeStyles.scss';

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
